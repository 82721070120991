import React, { useState, useEffect, useRef } from 'react';
import ValveMap from '../components/valveMap/ValveMap';
import ValveList from '../components/valveList/ValveList';
import { useLocation } from 'react-router-dom';
import { getValves, importValves, exportValves, deleteValves } from '../services/api';
import useLocalStorage from '../utils/useLocalStorage';
import Upload from '../components/Upload';
import Download from '../components/Download';
import FileDownload from 'js-file-download';
import useAuth from '../services/authentication/useAuth';

const Valves = ({ navigator, valveUpdate }) => {
  const [mode, setMode] = useLocalStorage('valves_mode', 'map');
  const [loadingValves, setLoadingValves] = useState(false);
  const [valves, setValves] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const location = useLocation();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const searchTextRef = useRef(searchText);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadErrors, setDownloadErrors] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (searchTimeout == null) {
      setSearchTimeout(setTimeout(() => {
        setupValves(searchTextRef.current);
        setSearchTimeout(null);
      }, 500));
    }
  }, [searchText, auth.companyId, auth.user, valveUpdate]);

  const setupValves = async (searchText) => {
    try {
      setLoadingValves(true);
      // const searchOptions = {
      //   search: searchText,
      //   take: -1,
      //   page: 0,
      //   minLatitude: currentBounds?._sw.lat ?? -Infinity,
      //   minLongitude: currentBounds?._sw.lng ?? -Infinity,
      //   maxLatitude: currentBounds?._ne.lat ?? Infinity,
      //   maxLongitude: currentBounds?._ne.lng ?? Infinity,
      // }
      const result = await getValves(searchText);
      // setValves(result?.filter(v =>
      //   v.latitude > searchOptions.minLatitude && v.latitude < searchOptions.maxLatitude
      //   && v.longitude > searchOptions.minLongitude && v.longitude < searchOptions.maxLongitude
      // ));
      setValves(result);
      setLoadingValves(false);
    } catch (ex) {
      console.log(ex);
      setLoadingValves(false);
    }
  };

  const updateSearchText = (text) => {
    searchTextRef.current = text;
    setSearchText(text);
  };

  const toggleUpload = () => {
    setUploadOpen(!uploadOpen);
  };

  const toggleDownload = () => {
    setDownloadOpen(!downloadOpen);
  }

  const uploadValves = async (files, mappings) => {
    try {
      setUploadErrors([]);
      setUploading(true);
      const result = await importValves(files[0], mappings);
      if (result.success) {
        setUploadOpen(false);
        setupValves(searchTextRef.current);
      } else {
        setUploadErrors(result.messages || ['An error has occured with the upload.']);
      }
    } catch (ex) {
      if (!ex.response.data)
        setUploadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setUploading(false);
    }
  };

  const downloadValves = async (mappings) => {
    try {
      setDownloadErrors([]);
      setDownloading(true);
      const result = await exportValves(mappings);
      FileDownload(result, 'valves_export.csv');
      setDownloadOpen(false);
    } catch (ex) {
      if (!ex.response.data)
        setDownloadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setDownloading(false);
    }
  };

  const removeValves = async (valveIds) => {
    await deleteValves(valveIds);
    await setupValves(searchText);
  };

  const showValvePage = location.pathname === '/' || location.pathname === '/valves' || location.pathname === '/valves/';

  return (
    <>
        {/* <div className={`fill${mode === 'map' && showValvePage ? '' : ' hidden'}`}>
          <ValveMap valves={valves} selectedId={selectedId} mode={mode} setMode={setMode} searchText={searchText} setSearchText={updateSearchText} toggleUpload={toggleUpload} toggleDownload={toggleDownload} />
        </div>
        <div className={mode === 'list' && showValvePage ? '' : 'hidden'}> */}
          <ValveList valves={valves} setMode={setMode} searchText={searchText} setSearchText={updateSearchText} toggleUpload={toggleUpload} toggleDownload={toggleDownload} deleteValves={removeValves} />
        {/* </div> */}
        {loadingValves && <div className={`spinner${showValvePage ? '' : ' hidden'}`}></div>}
        <Upload onUpload={uploadValves} errors={uploadErrors} uploading={uploading} multiple={false} open={uploadOpen} toggle={toggleUpload} title="Upload Valves" />
        <Download onDownload={downloadValves} errors={downloadErrors} downloading={downloading} multiple={false} open={downloadOpen} toggle={toggleDownload} title="Download Valves" />
    </>
  );
};

export default Valves