import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import LabelledTextBox from '../../components/LabelledTextBox';
import LabelledPicker from '../../components/LabelledPicker';
import { getHydrantManufacturer, updateHydrantManufacturer, deleteHydrantManufacturer, getHydrantImage } from '../../services/api';
import useAuth from '../../services/authentication/useAuth';
import EventEmitter from '../../services/events';
import { makeHydrantManufacturer } from '../../models';
import LabelledTextField from '../../components/LabelledTextField';
import { dateTimeOptions } from '../../utils/dates';

const HydrantManufacturerModal = ({ open, toggle, onClose, onDelete, hydrantManufacturerId, mode }) => {
  const { companyId } = useAuth();
  const [hydrantManufacturer, setHydrantManufacturer] = useState(null);
  const [hydrantImage, setHydrantImage] = useState(null);
  const [viewEditMode, setViewEditMode] = useState(mode || 'view');
  const [startDelete, setStartDelete] = useState(false);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    setupHydrantManufacturer();
  }, [open, hydrantManufacturerId]);

  useEffect(() => {
    setupHydrantImage();
  }, [hydrantManufacturer])

  const setupHydrantManufacturer = async () => {
    const result = await getHydrantManufacturer(hydrantManufacturerId);
    setHydrantManufacturer(makeHydrantManufacturer(result));
  };

  const setupHydrantImage = async () => {
    if (!hydrantManufacturer || !hydrantManufacturer.hydrantImageId) {
      setHydrantImage(null);
      return;
    }

    const result = await getHydrantImage(hydrantManufacturer.hydrantImageId);
    setHydrantImage(makeHydrantImage(result));
  };

  const close = () => {
    onClose();
  };

  const edit = () => {
    setViewEditMode('edit');
  };

  const cancel = async () => {
    await setupHydrantManufacturer();
    setViewEditMode('view');
  };

  const save = async () => {
    try {
      hydrantManufacturer.validate();
      const result = await updateHydrantManufacturer(hydrantManufacturer);
      setViewEditMode('view');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Manufacturer', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Manufacturer', body: 'Unable to save hydrant manufacturer.' });
      }
    }
  };

  const deleteRecord = async () => {
    await deleteHydrantManufacturer(hydrantManufacturer.id);
    onDelete();
  };

  const onChange = e => {
    setHydrantManufacturer(makeHydrantManufacturer({ ...hydrantManufacturer, [e.target.name]: e.target.value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value);
      //   break;
    }

    setHydrantManufacturer(makeHydrantManufacturer({ ...hydrantManufacturer, [e.target.name]: value }));
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        <div>Hydrant Manufacturer</div>
      </ModalHeader>
      <ModalBody>
        {hydrantManufacturer ? (
          <>
            {viewEditMode === 'view' &&
              <div className="details">
                <LabelledTextField label="Name" text={hydrantManufacturer.name} />
                <LabelledTextField label="Address" text={hydrantManufacturer.address} />
                <LabelledTextField label="Address 2" text={hydrantManufacturer.address2} />
                <LabelledTextField label="City" text={hydrantManufacturer.city} />
                <LabelledTextField label="State" text={hydrantManufacturer.state} />
                <LabelledTextField label="Zip Code" text={hydrantManufacturer.zipCode} />
                <LabelledTextField label="Phone" text={hydrantManufacturer.phone} />
                <LabelledTextField label="Mobile Phone" text={hydrantManufacturer.mobilePhone} />
                <LabelledTextField label="Fax" text={hydrantManufacturer.fax} />
                <LabelledTextField label="Email" text={hydrantManufacturer.email} />
                <LabelledTextField label="Website" text={hydrantManufacturer.website} />
                <LabelledTextField label="Created By:" text={hydrantManufacturer.createdBy} />
                <LabelledTextField label="Created Date:" text={hydrantManufacturer.createdDate ? new Date(hydrantManufacturer.createdDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
                <LabelledTextField label="Modified By:" text={hydrantManufacturer.modifiedBy} />
                <LabelledTextField label="Modified Date:" text={hydrantManufacturer.modifiedDate ? new Date(hydrantManufacturer.modifiedDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
              </div>
            }
            {viewEditMode === 'edit' &&
              <div className='details'>
                <LabelledTextBox label="Name" value={hydrantManufacturer.name} name="name" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Address" value={hydrantManufacturer.address} name="address" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Address 2" value={hydrantManufacturer.address2} name="address2" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="City" value={hydrantManufacturer.city} name="city" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="State" value={hydrantManufacturer.state} name="state" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Zip Code" value={hydrantManufacturer.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Phone" value={hydrantManufacturer.phone} name="phone" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Mobile Phone" value={hydrantManufacturer.mobilePhone} name="mobilePhone" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Fax" value={hydrantManufacturer.fax} name="fax" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Email" value={hydrantManufacturer.email} name="email" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Website" value={hydrantManufacturer.website} name="website" onChange={onChange} onBlur={onBlur} />
              </div>
            }
          </>
        ) : (
          <span className="spinner"></span>
        )}
      </ModalBody>
      <ModalFooter>
        {viewEditMode === 'view' &&
          <div className="actions">
            {startDelete ? (
              <>
                <span>Are you sure you want to delete this record?</span>
                <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
                <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
              </>
            ) : (
              <>
                <button type="button" className="btn btn-secondary" onClick={close}>Close</button>
                {/* <button type="button" className="btn btn-danger" onClick={() => setStartDelete(true)}>Delete</button> */}
                <button type="button" className="btn btn-primary" onClick={edit}>Edit</button>
              </>
            )}
          </div>
        }
        {viewEditMode === 'edit' &&
          <div className="actions">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
        }
      </ModalFooter>
    </Modal>
  );
};

export default HydrantManufacturerModal;