import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import LabelledTextBox from '../../components/LabelledTextBox';
import LabelledPicker from '../../components/LabelledPicker';
import { addHydrantManufacturer } from '../../services/api';
import useAuth from '../../services/authentication/useAuth';
import EventEmitter from '../../services/events';
import { makeHydrantManufacturer } from '../../models';

const AddHydrantManufacturerModal = ({ open, toggle, onComplete, onCancel }) => {
  const { companyId } = useAuth();
  const [hydrantManufacturer, setHydrantManufacturer] = useState(makeHydrantManufacturer({ companyId }));
  const { path, url } = useRouteMatch();

  const save = async () => {
    try {
      hydrantManufacturer.validate();
      const result = await addHydrantManufacturer(hydrantManufacturer);
      onComplete(result.id);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Manufacturer', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Manufacturer', body: 'Unable to save hydrant manufacturer.' });
      }
    }
  };

  const cancel = async () => {
    onCancel();
  };

  const onChange = e => {
    setHydrantManufacturer(makeHydrantManufacturer({ ...hydrantManufacturer, [e.target.name]: e.target.value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value);
      //   break;
    }

    setHydrantManufacturer(makeHydrantManufacturer({ ...hydrantManufacturer, [e.target.name]: value }));
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        <div>Add Hydrant Manufacturer</div>
      </ModalHeader>
      <ModalBody>
          <div className='details'>
            <LabelledTextBox label="Name" value={hydrantManufacturer.name} name="name" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Address" value={hydrantManufacturer.address} name="address" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Address 2" value={hydrantManufacturer.address2} name="address2" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="City" value={hydrantManufacturer.city} name="city" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="State" value={hydrantManufacturer.state} name="state" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Zip Code" value={hydrantManufacturer.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Phone" value={hydrantManufacturer.phone} name="phone" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Mobile Phone" value={hydrantManufacturer.mobilePhone} name="mobilePhone" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Fax" value={hydrantManufacturer.fax} name="fax" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Email" value={hydrantManufacturer.email} name="email" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Website" value={hydrantManufacturer.website} name="website" onChange={onChange} onBlur={onBlur} />
          </div>
      </ModalBody>
      <ModalFooter>
        <div className="actions">
          <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={save}>Save</button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddHydrantManufacturerModal;