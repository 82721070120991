import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrantModels = async () => {
  const result = await tryGet(`/hydrantModels`);
  return result.data;
};

export const getHydrantModel = async (hydrantModelId) => {
  const result = await tryGet(`/hydrantModels/${hydrantModelId}`);
  return result.data;
};

export const getHydrantModelEdit = async (hydrantModelId) => {
  const result = await tryGet(`/hydrantModels/${hydrantModelId}/edit`);
  return result.data;
};

export const updateHydrantModel = async (hydrantModel) => {
  const result = await tryPut(`/hydrantModels`, hydrantModel);
  return result.data;
};

export const addHydrantModel = async (hydrantModel) => {
  const result = await tryPost(`/hydrantModels`, hydrantModel);
  return result.data;
};

export const deleteHydrantModel = async (hydrantModelId) => {
  const result = await tryDelete(`/hydrantModels/${hydrantModelId}`);
  return result.data;
};

export const deleteHydrantModels = async (hydrantModelIds) => {
  console.log(hydrantModelIds);
  const result = await tryDelete('/hydrantModels', { data: hydrantModelIds });
  return result.data;
};