import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LabelledMultiPicker from '../components/LabelledMultiPicker';
import LabelledTextField from '../components/LabelledTextField';
import { getUser, getRoles, updateUserRoles } from '../services/api';

const EditUserRoles = () => {
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const { userId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupRoles();
    setupUser();
  }, [userId]);

  const setupUser = async () => {
    const result = await getUser(userId);
    setUser(result);
    if (result.companyUsers) {
      setUserRoles(result.companyUsers.map(cu => cu.role.id));
    }
  };
  
  const setupRoles = async () => {
    const result = await getRoles();
    setRoles(result);
  };

  const addRole = (roleId) => {
    setUserRoles([...userRoles.filter(ur => ur !== roleId), roleId]);
  };

  const removeRole = (roleId) => {
    setUserRoles(userRoles.filter(ur => ur !== roleId));
  };

  const save = async (e) => {
    e.preventDefault();
    await updateUserRoles(userId, userRoles);
    history.goBack();
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <form onSubmit={save}>
      <h2>Edit User Roles</h2>
      <div className="details">
        {user && userRoles ? (
          <>
            <LabelledTextField label="User" text={user.email} />
            <LabelledMultiPicker
              label="Roles"
              options={roles.map(r => ({ value: r.id, content: r.roleName }))}
              values={userRoles}
              onAdd={addRole}
              onRemove={removeRole} />
          </>
        ) : (
          <span className="spinner"></span>
        )}
      </div>
      <div className="form-actions">
        <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
        <button type="submit" className="btn btn-primary">Save</button>
      </div>
    </form>
  );
};

export default EditUserRoles;