export default function buildMakeHydrantImage({ validate, validators }) {
  return function makeHydrantImage({
    hydrantId,
    name,
    image,
    fileName,
    dataType,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      hydrantId: {
        prettyName: 'Hydrant ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      name: {
        prettyName: 'Name',
        validators: [validators.isNumber()],
      },
      image: {
        prettyName: 'Image',
        validators: [validators.isString()],
      },
      fileName: {
        prettyName: 'File Name',
        validators: [validators.isString()],
      },
      dataType: {
        prettyName: 'Data Type',
        validators: [validators.isString()],
      },
    };

    return Object.freeze({
      get hydrantId() { return hydrantId },
      get name() { return name },
      get image() { return image },
      get fileName() { return fileName },
      get dataType() { return dataType },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}