import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { getValve, getCurrentCompany, getValveExercises } from '../../services/api';
import ValveHistory from './ValveHistory';
import CircleButton from '../../components/CircleButton';
import hurcoLogoImg from '../../assets/images/HurcoLogo.png';
import { makeValve, makeCompany, makeValveExercise } from '../../models';
import { dateOptions, dateTimeOptions } from '../../utils/dates';

const ValveHistoryReport = () => {
  const [exercises, setExercises] = useState([]);
  const [valve, setValve] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingValve, setLoadingValve] = useState(true);
  const [loadingExercises, setLoadingExercises] = useState(true);
  const { valveId } = useParams();

  useEffect(() => {
    setupCompany();
    setupValve();
  }, []);

  useEffect(() => {
    setupExercises();
  }, [valve]);

  useEffect(() => {
    setLoading(loadingCompany || loadingValve || loadingExercises);
  }, [loadingCompany, loadingValve, loadingExercises]);

  const setup = async () => {
    try {
      setLoading(true);
      await setupCompany();
      await setupValves();
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  }

  const setupCompany = async () => {
    try {
      setLoadingCompany(true);
      const fetchedCompany = await getCurrentCompany();
      setCompany(makeCompany(fetchedCompany));
      setLoadingCompany(false);
    } catch (ex) {
      console.log(ex);
      setLoadingCompany(false);
    }
  }

  const setupValve = async () => {
    try {
      setLoadingValve(true);
      const fetchedValve = await getValve(valveId);
      const mappedValve = makeValve(fetchedValve);
      setValve(mappedValve);
      setLoadingValve(false);
    } catch (ex) {
      console.log(ex);
      setLoadingValve(false);
    }
  };

  const setupExercises = async () => {
    try {
      setLoadingExercises(true);
      const fetchedExercises = await getValveExercises(valveId);
      const mappedExercises = fetchedExercises.map(e => makeValveExercise(e));
      setExercises(mappedExercises);
      setLoadingExercises(false);
    } catch (ex) {
      console.log(ex);
      setLoadingExercises(false);
    }
  };

  const print = () => {
    window.print();
  };

  const valveHistories = exercises.map((e, idx) => <ValveHistory key={idx} exercise={e} />);

  return (
    <div className="report valve-history-report">
      {loading && <div className="spinner"></div>}
      <div className="report-title">
        <h2>Valve Exercise History Report</h2>
        {loading || <CircleButton icon={faPrint} size="large" title="Print" onClick={print} className="no-print" />}
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <td>
              <div className="report-header">
                <div className="header-logo">
                  <img src={hurcoLogoImg} alt="Hurco Technologies IMS" />
                </div>
                <div className="header-details">
                  <div className="header-detail">
                    <label>Company Name:</label>
                    <span>{company?.name}</span>
                  </div>
                  {/* <div className="header-detail">
                    <label>Address:</label>
                    <span>{company?.address}</span>
                  </div>
                  <div className="header-detail">
                    <label>City:</label>
                    <span>{company?.city}</span>
                  </div>
                  <div className="header-detail">
                    <label>State:</label>
                    <span>{company?.state}</span>
                  </div>
                  <div className="header-detail">
                    <label>Zip:</label>
                    <span>{company?.zip}</span>
                  </div> */}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="valve-info">
                <div className="valve-info-detail">
                  <label>Device Type:</label>
                  <span>Valve</span>
                </div>
                <div className="valve-info-detail">
                  <label>Device ID:</label>
                  <span>{valve?.name}</span>
                </div>
                <div className="valve-info-detail">
                  <label>Manufacturer:</label>
                  <span>{valve?.manufacturer}</span>
                </div>
                <div className="valve-info-detail">
                  <label>Install Date:</label>
                  <span>{valve?.installDate ? new Date(valve.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
                </div>
                <div className="valve-info-detail">
                  <label>Install Date:</label>
                  <span>{valve?.lastMaintenanceDate ? new Date(valve.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="table-exercises-container">
                <table className="table-exercises">
                  <thead>
                    <tr>
                      <th><span>Completed</span></th>
                      <th><span>Total Revs</span></th>
                      <th><span>Cycle Count</span></th>
                      <th><span>Max Torque</span></th>
                      <th><span>Rev At Max Torque</span></th>
                      <th><span>Max Revolutions</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {valveHistories}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ValveHistoryReport;