import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrantManufacturers = async () => {
  const result = await tryGet(`/hydrantManufacturers`);
  return result.data;
};

export const getHydrantManufacturer = async (hydrantManufacturerId) => {
  const result = await tryGet(`/hydrantManufacturers/${hydrantManufacturerId}`);
  return result.data;
};

export const getHydrantManufacturerEdit = async (hydrantManufacturerId) => {
  const result = await tryGet(`/hydrantManufacturers/${hydrantManufacturerId}/edit`);
  return result.data;
};

export const updateHydrantManufacturer = async (hydrantManufacturer) => {
  const result = await tryPut(`/hydrantManufacturers`, hydrantManufacturer);
  return result.data;
};

export const addHydrantManufacturer = async (hydrantManufacturer) => {
  const result = await tryPost(`/hydrantManufacturers`, hydrantManufacturer);
  return result.data;
};

export const deleteHydrantManufacturer = async (hydrantManufacturerId) => {
  const result = await tryDelete(`/hydrantManufacturers/${hydrantManufacturerId}`);
  return result.data;
};

export const deleteHydrantManufacturers = async (hydrantManufacturerIds) => {
  console.log(hydrantManufacturerIds);
  const result = await tryDelete('/hydrantManufacturers', { data: hydrantManufacturerIds });
  return result.data;
};