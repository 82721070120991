import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getValveExerciseSettings = async (searchText) => {
  const result = await tryGet(`/valveExerciseSettings?search=${searchText || ''}`);
  return result.data;
};

export const getValveExerciseSetting = async (valveExerciseSettingId) => {
  const result = await tryGet(`/valveExerciseSettings/${valveExerciseSettingId}`);
  return result.data;
};

export const getValveExerciseSettingEdit = async (valveExerciseSettingId) => {
  const result = await tryGet(`/valveExerciseSettings/${valveExerciseSettingId}/edit`);
  return result.data;
};

export const updateValveExerciseSetting = async (valveExerciseSetting) => {
  const result = await tryPut(`/valveExerciseSettings`, valveExerciseSetting);
  return result.data;
};

export const addValveExerciseSetting = async (valveExerciseSetting) => {
  const result = await tryPost(`/valveExerciseSettings`, valveExerciseSetting);
  return result.data;
};

export const deleteValveExerciseSetting = async (valveExerciseSettingId) => {
  const result = await tryDelete(`/valveExerciseSettings/${valveExerciseSettingId}`);
  return result.data;
};

export const importValveExerciseSettings = async (file) => {
  var data = new FormData();
  data.append('file', file);
  const result = await tryPost('/valveExerciseSettings/import', data);
  return result.data;
};

export const exportValveExerciseSettings = async () => {
  const result = await tryGet('/valveExerciseSettings/export');
  return result.data;
};