import React, { useState, useEffect } from 'react';
import { dateOptions } from '../../../utils/dates';
import { makeHydrantExercise } from '../../../models';
import { getHydrantExerciseLatest } from '../../../services/api';
import ExerciseChart from '../../../components/ExerciseChart';

const HydrantSummary = ({ hydrant }) => {
  const [exercise, setExercise] = useState(null);
  const [loadingExercise, setLoadingExercise] = useState(true);
  
  useEffect(() => {
    setupExercise();
  }, [hydrant]);

  // useEffect(() => {
  //   setLoading(loadingExercise); //was a param
  // }, [loadingExercise]);

  const setupExercise = async () => {
    try {
      setLoadingExercise(true);
      if (hydrant) {
        const fetchedExercise = await getHydrantExerciseLatest(hydrant.id);
        setExercise(makeHydrantExercise(fetchedExercise));
      }
      setLoadingExercise(false);
    } catch (ex) {
      console.log(ex);
      setLoadingExercise(false);
    }
  };

  return (
    <tr className="page-break">
      <td>
        <div className="record-result">
          <div className="result-details">
            <div className="result-detail">
              <label>Device Type:</label>
              <span>Hydrant</span>
            </div>
            <div className="result-detail">
              <label>Device ID:</label>
              <span>{hydrant?.name}</span>
            </div>
          </div>
          {!exercise?.manual &&
          <div className="record-settings">
            <div className="record-setting">
              <label>Set Rev:</label>
              <span>{exercise?.revolutions}</span>
            </div>
            <div className="record-setting">
              <label>Set Torque:</label>
              <span>{exercise?.torqueSetPoint}</span>
            </div>
            <div className="record-setting">
              <label>Set Speed:</label>
              <span>{exercise?.rpmSetPoint}</span>
            </div>
          </div>}
          <div className="record-location">
            <div className="record-location-info">
              <div className="record-location-detail">
                <label>Address:</label>
                <span>{((hydrant?.streetNumber ?? '') + ' ' + (hydrant?.streetName ?? '')).trim()}</span>
              </div>
              <div className="record-location-detail">
                <label>Cross Street:</label>
                <span>{hydrant?.crossStreet}</span>
              </div>
              <div className="record-location-detail">
                <label>Location:</label>
                <span>{hydrant?.locationNotes}</span>
              </div>
              <div className="record-location-detail">
                <label>District:</label>
                <span>{hydrant?.district}</span>
              </div>
              <div className="record-location-detail">
                <label>Sub-Division:</label>
                <span>{hydrant?.subDivision}</span>
              </div>
            </div>
            <div className="record-gps-info">
              <div className="record-gps-detail">
                <label>Latitude:</label>
                <span>{hydrant?.latitude?.toFixed(6)}</span>
              </div>
              <div className="record-gps-detail">
                <label>Longitude:</label>
                <span>{hydrant?.longitude?.toFixed(6)}</span>
              </div>
              <div className="record-gps-detail">
                <label>Elevation:</label>
                <span>{hydrant?.elevation?.toFixed(2)}</span>
              </div>
              <div className="record-gps-detail">
                <label>State X / Y:</label>
                <span>{hydrant?.stateX} / {hydrant?.stateY}</span>
              </div>
            </div>
          </div>
          <div className="record-info">
            <div className="record-info-detail">
              <label>Manufacturer:</label>
              <span>{hydrant?.manufacturer}</span>
            </div>
            <div className="record-info-detail">
              <label>Install Date:</label>
              <span>{hydrant?.installDate ? new Date(hydrant.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="record-info-detail">
              <label>Status:</label>
              <span>{hydrant?.status}</span>
            </div>
            <div className="record-info-detail">
              <label>Main Size:</label>
              <span>{hydrant?.mainSize?.toFixed(2)}</span>
            </div>
            <div className="record-info-detail">
              <label>Open Direction:</label>
              <span>{exercise?.openDirection}</span>
            </div>
            <div className="record-info-detail">
              <label>Bury Depth:</label>
              <span>{hydrant?.buryDepth?.toFixed(2)}</span>
            </div>
          </div>
          <div className="record-stats">
            <div className="record-info-detail">
              <label>Exercise Date:</label>
              <span>{exercise?.exerciseDate ? new Date(exercise.exerciseDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            {exercise?.manual ?
              <>
                <div className="record-stat">
                  <label>Manual Difficulty:</label>
                  <span>{exercise?.difficulty}</span>
                </div>
                <div className="record-stat full-row">
                  <label>Comments:</label>
                  <span>{exercise?.comments}</span>
                </div>
              </>
            : 
              <>
                <div className="record-stat">
                  <label>Total Revs:</label>
                  <span>{hydrant?.totalRevolutions}</span>
                </div>
                <div className="record-stat">
                  <label>Cycle Count:</label>
                  <span>{exercise?.autoCycles}</span>
                </div>
                <div className="record-stat">
                  <label>Max Torque:</label>
                  <span>{hydrant?.maxTorque}</span>
                </div>
                <div className="record-stat">
                  <label>Revs @ Max Torque:</label>
                  <span>{hydrant?.maxTorqueRevolutions}</span>
                </div>
                <div className="record-stat">
                  <label>Max Rev In Set Dir:</label>
                  <span>{hydrant?.maxDirectionRevolutions}</span>
                </div>
                <div className="record-stat full-row">
                  <label>Comments:</label>
                  <span>{exercise?.comments}</span>
                </div>
              </>
            }
          </div>
          {!exercise?.manual && <ExerciseChart exercise={exercise} />}
        </div>
      </td>
    </tr>
  )
};

export default HydrantSummary;