import React from 'react';
import deepFreeze from '../deepFreeze';

export const gpsFixes = deepFreeze([
  {
    id: 1,
    name: 'Fix Not Valid',
  },
  {
    id: 2,
    name: 'GPS Fix',
  },
  {
    id: 3,
    name: 'DGPS Fix',
  },
  {
    id: 4,
    name: 'RTK',
  },
  {
    id: 5,
    name: 'RTK Float',
  },
]);

export const gpsFixOptions = gpsFixes.map((g, idx) => <option key={idx} value={g.id}>{g.name}</option>)