import React from 'react';
import { Link } from 'react-router-dom';
import LabelledTextField from '../LabelledTextField';
import { dateOptions } from '../../utils/dates';
import { faEye, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import CircleButton from '../CircleButton';

const HydrantMapCard = React.forwardRef(({ hydrant, zoomTo }, ref ) => {
  const zoom = () => {
    zoomTo(hydrant.id, [hydrant.longitude, hydrant.latitude]);
  };
  return (
    <div className="map-card card" id={hydrant.id} ref={ref}>
      <h3>{hydrant.name}</h3>
      <div className='details'>
        <LabelledTextField label="Last Maintenance Date" text={hydrant.lastMaintenanceDate ? new Date(hydrant.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''} />
        <LabelledTextField label="Paint Color" text={hydrant.paintColor} />
        <LabelledTextField label="Main Size" text={hydrant.mainSize} />
      </div>
      <div className='actions'>
        <div className="link-icon" onClick={zoom}>
          <CircleButton icon={faSearchLocation} size="large" title="Zoom To" />
        </div>
        <Link to={`/hydrants/${hydrant.id}`}>
          <CircleButton icon={faEye} size="large" type="primary" title="Details" />
        </Link>
      </div>
    </div>
  )
});

export default HydrantMapCard;