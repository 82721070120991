import React, { useState, useEffect, useRef, useCallback } from 'react';
import { faCircle, faCircleXmark, faXmark } from '@fortawesome/free-regular-svg-icons';
import { isFileImage, getBase64 } from '../utils/files';
import CircleButton from './CircleButton';

const LabelledImagePicker = ({ label, value, onChange }) => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [dragDiv, setDragDiv] = useState(null);
  const dragDivRef = useCallback(node => {
    if (node !== null) {
      setDragDiv(node);
    };
  });

  useEffect(() => {
    let dragCounter = 0;
    const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragging(true);
      }
    };
    const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter--;
      if (dragCounter === 0) {
        setDragging(false);
      }
    };
    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        inputFile(e.dataTransfer.files[0]);
        e.dataTransfer.clearData();
        dragCounter = 0;
      }
    };

    if (dragDiv) {
      dragDiv.addEventListener('dragenter', handleDragIn);
      dragDiv.addEventListener('dragleave', handleDragOut);
      dragDiv.addEventListener('dragover', handleDrag);
      dragDiv.addEventListener('drop', handleDrop);

      return () => {
        dragDiv.removeEventListener('dragenter', handleDragIn);
        dragDiv.removeEventListener('dragleave', handleDragOut);
        dragDiv.removeEventListener('dragover', handleDrag);
        dragDiv.removeEventListener('drop', handleDrop);
      };
    }
  }, [dragDiv]);

  const inputFile = async (file) => {
    if (file === null || !isFileImage(file)) {
      onChange(null);
      return;
    }

    const fileName = file.name;
    const image64 = await getBase64(file);
    const imageSplit = image64.split(',');
    const dataType = imageSplit[0];
    const image = imageSplit[1];
    onChange({ fileName, dataType, image });
  };

  const chooseFile = () => {
    fileInputRef.current.click();
  };

  const clear = () => {
    setFiles([]);
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      inputFile(e.target.files[0])
    }
  };

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    inputFile(null);
  };

  const handleImageClick = (e) => {
    fileInputRef.current.click();
  }

  return (
    <div className="labelled-image-picker">
      <span className="label">{label}</span>
      <div ref={dragDivRef} className='image-picker drag-and-drop' onClick={handleImageClick}>
        {value ? (
          <>
            <img alt={label} src={`${value?.dataType},${value?.image}`} />
            <CircleButton icon={faCircleXmark} className="button-remove" type="danger" title="Remove Image" onClick={handleRemoveImage} />
          </>
        ) : (
          <div>Click or Drop Image Here</div>
        )}
        {dragging && <div className="drop">Drop Here!</div>}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        name="file-picker"
        className='hidden'
        onChange={handleImageChange}
      />
    </div>
  );
};

export default LabelledImagePicker;
