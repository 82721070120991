import React from 'react';
import { Link } from 'react-router-dom';
import IsAuthorized from '../services/authentication/IsAuthorized';

const Reports = () => {

  return (
    <div className="settings-page">
      <div className="settings-links">
        <IsAuthorized>
          <Link className="link" to={'/defaultValveExerciseSettings'}>Default Valve Settings</Link>
          <Link className="link" to={'/defaultHydrantExerciseSettings'}>Default Hydrant Settings</Link>
          <Link className="link" to={'/users'}>Users</Link>
        </IsAuthorized>
      </div>
    </div>
  );
};

export default Reports;