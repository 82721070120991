import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { Aside, ErrorBoundaryRoute } from '../../components/Layout';
import EditHydrant from './EditHydrant';
import DetailHydrant from './DetailHydrant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress, faChartArea, faChartColumn, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-regular-svg-icons'
import FireFlowTest from './fireFlowTest';
import { getHydrant } from '../../services/api';
import RequireAuth from '../../services/authentication/RequireAuth';
import Role from '../../services/authentication/Roles';
import { makeHydrant } from '../../models';
import HydrantExerciseSettings from './HydrantExerciseSettings';
import EditHydrantExerciseSettings from './EditHydrantExerciseSettings';
import HydrantExercise from './HydrantExercise';
import EditHydrantExercise from './EditHydrantExercise';

const Hydrant = ({ onUpdate, onDelete }) => {
  const [hydrant, setHydrant] = useState(null);
  const [hydrantExerciseSettings, setHydrantExerciseSettings] = useState(null);
  const { path, url } = useRouteMatch();
  const { hydrantId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupHydrant();
  }, [hydrantId, history.location]);

  const setupHydrant = async () => {
    const result = await getHydrant(hydrantId);
    setHydrant(makeHydrant(result));
  };

  const save = async () => {
    try {
      
      history.replace('/hydrants/' + hydrant.id)
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  return (
    <div id="hydrant" className="page">
      <h2>{hydrant?.name ?? "Hydrant"}</h2>
      <Switch>
        <ErrorBoundaryRoute exact path={path}>
          <DetailHydrant hydrant={hydrant} />
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/edit`}>
          <RequireAuth roles={[Role.Admin]}>
            <EditHydrant/>
          </RequireAuth>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/hydrantExerciseSettings`}>
          <HydrantExerciseSettings HydrantExerciseSettings={hydrantExerciseSettings} />
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/hydrantExerciseSettings/edit`}>
          <RequireAuth roles={[Role.Admin]}>
            <EditHydrantExerciseSettings onUpdate={onUpdate}/>
          </RequireAuth>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/hydrantExercise/:hydrantExerciseId?`} component={HydrantExercise} />
        <ErrorBoundaryRoute exact path={`${path}/hydrantExercise/:hydrantExerciseId/edit`} >
          <RequireAuth roles={[Role.Admin]}>
            <EditHydrantExercise onUpdate={onUpdate} />
          </RequireAuth>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/fireFlowTest/:fireFlowTestId?`} component={FireFlowTest} />
      </Switch>
    </div>
  );
};

export const HydrantAside = () => {
  const { path, url } = useRouteMatch();
  const { hydrantId } = useParams();
  const id = +hydrantId;

  return (
    <Aside>
      <Link to={`${url}`} className='aside-item'>
        <div className="icon-button" title="Hydrant Details">
          <FontAwesomeIcon icon={faListAlt} />
        </div>
        <span>Hydrant Details</span>
      </Link>
      <Link to={`${url}/hydrantExerciseSettings`} className='aside-item'>
        <div className="icon-button" title="Exercise Settings">
          <FontAwesomeIcon icon={faSlidersH} />
        </div>
        <span>Exercise Settings</span>
      </Link>
      <Link to={`${url}/hydrantExercise`} className='aside-item'>
        <div className="icon-button" title="Exercise Resutls">
          <FontAwesomeIcon icon={faChartArea} />
        </div>
        <span>Exercise Resutls</span>
      </Link>
      {/* <Link to={`${url}/hydrantTestSettings`} className='aside-item'>
        <div className="icon-button" title="Test Settings">
          <FontAwesomeIcon icon={faBarsProgress} />
        </div>
        <span>Test Settings</span>
      </Link>
      <Link to={`${url}/fireFlowTest`} className='aside-item'>
        <div className="icon-button" title="Fire Flow Test">
          <FontAwesomeIcon icon={faChartColumn} />
        </div>
        <span>Fire Flow Test</span>
      </Link> */}
    </Aside>
  );
};

export default Hydrant