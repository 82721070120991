export default function buildMakeHydrant({ validate, validators }) {
  return function makeHydrant({
    id,
    companyId,
    name,
    latitude,
    longitude,
    gpsFixId,
    elevation,
    hdop,
    vdop,
    stateX,
    stateY,
    streetNumber,
    streetName,
    crossStreet,
    generalLocation,
    locationNotes,
    city,
    state,
    zipCode,
    district,
    subDivision,
    installDate,
    status,
    description,
    manufacturer,
    hydrantManufacturerId,
    hydrantModelId,
    hydrantImageId,
    paintColor,
    vandalProofType,
    restraint,
    mainSize,
    buryDepth,
    controlValveId,
    controlValveDistance,
    testingNumber,
    comments,
    lastMaintenanceDate,
    maxTorque,
    maxTorqueRevolutions,
    maxRevolutions,
    totalRevolutions,
    exerciseFrequencyMonths,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      companyId: {
        prettyName: 'Company ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
      name: {
        prettyName: 'Name',
        validators: [validators.notEmpty(), validators.maxLength(50)],
      },
      latitude: {
        prettyName: 'Latitude',
        validators: [validators.isNumber()],
      },
      longitude: {
        prettyName: 'Longitude',
        validators: [validators.isNumber()],
      },
      gpsFixId: {
        prettyName: 'GPS Fix',
        validators: [validators.isInteger()],
      },
      elevation: {
        prettyName: 'Elevation',
        validators: [validators.isNumber()],
      },
      hdop: {
        prettyName: 'HDOP',
        validators: [validators.isNumber()],
      },
      vdop: {
        prettyName: 'VDOP',
        validators: [validators.isNumber()],
      },
      stateX: {
        prettyName: 'State X',
        validators: [validators.isNumber()],
      },
      stateY: {
        prettyName: 'State Y',
        validators: [validators.isNumber()],
      },
      streetNumber: {
        prettyName: 'Street Number',
        validators: [validators.maxLength(20)],
      },
      streetName: {
        prettyName: 'Street Name',
        validators: [validators.maxLength(50)],
      },
      crossStreet: {
        prettyName: 'Cross Street',
        validators: [validators.maxLength(50)],
      },
      generalLocation: {
        prettyName: 'General Location',
        validators: [validators.maxLength(50)],
      },
      locationNotes: {
        prettyName: 'Location Notes',
        validators: [],
      },
      city: {
        prettyName: 'City',
        validators: [validators.maxLength(20)],
      },
      state: {
        prettyName: 'State',
        validators: [validators.exactLength(2)],
      },
      zipCode: {
        prettyName: 'Zip Code',
        validators: [validators.maxLength(15)],
      },
      comments: {
        prettyName: 'Comments',
        validators: [validators.isString()],
      },
      district: {
        prettyName: 'District',
        validators: [validators.isString(), validators.maxLength(20)],
      },
      subDivision: {
        prettyName: 'Sub Division',
        validators: [validators.isString(), validators.maxLength(20)],
      },
      installDate: {
        prettyName: 'Install Date',
        validators: [validators.isDate()],
      },
      status: {
        prettyName: 'Status',
        validators: [validators.isString(), validators.maxLength(10)],
      },
      description: {
        prettyName: 'Description',
        validators: [validators.isString()],
      },
      manufacturer: {
        prettyName: 'Manufacturer',
        validators: [validators.isString(), validators.maxLength(50)],
      },
      hydrantManufacturerId: {
        prettyName: 'Hydrant Manufacturer ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      hydrantModelId: {
        prettyName: 'Model ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      hydrantImageId: {
        prettyName: 'Model ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      paintColor: {
        prettyName: 'Paint Color',
        validators: [validators.isString(), validators.maxLength(20)],
      },
      vandalProofType: {
        prettyName: 'Vandal Proof Type',
        validators: [validators.isString(), validators.maxLength(20)],
      },
      restraint: {
        prettyName: 'Restraint',
        validators: [validators.isString(), validators.maxLength(20)],
      },
      mainSize: {
        prettyName: 'Main Size',
        validators: [validators.isNumber()],
      },
      buryDepth: {
        prettyName: 'Bury Depth',
        validators: [validators.isNumber()],
      },
      controlValveId: {
        prettyName: 'Control Valve ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      controlValveDistance: {
        prettyName: 'Control Valve Distance',
        validators: [validators.isNumber()],
      },
      testingNumber: {
        prettyName: 'Testing Number',
        validators: [validators.isString(), validators.maxLength(50)],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get name() { return name },
      get latitude() { return latitude },
      get longitude() { return longitude },
      get gpsFixId() { return gpsFixId },
      get elevation() { return elevation },
      get hdop() { return hdop },
      get vdop() { return vdop },
      get stateX() { return stateX },
      get stateY() { return stateY },
      get streetNumber() { return streetNumber },
      get streetName() { return streetName },
      get crossStreet() { return crossStreet },
      get generalLocation() { return generalLocation },
      get locationNotes() { return locationNotes },
      get city() { return city },
      get state() { return state },
      get zipCode() { return zipCode },
      get district() { return district },
      get subDivision() { return subDivision },
      get installDate() { return installDate ? (typeof(installDate) === typeof(String()) ? new Date(installDate.split('T')[0] + 'T00:00') : new Date(installDate)) : null },
      get status() { return status },
      get description() { return description },
      get manufacturer() { return manufacturer },
      get hydrantManufacturerId() { return hydrantManufacturerId },
      get hydrantModelId() { return hydrantModelId },
      get hydrantImageId() { return hydrantImageId },
      get paintColor() { return paintColor },
      get vandalProofType() { return vandalProofType },
      get restraint() { return restraint },
      get mainSize() { return mainSize },
      get buryDepth() { return buryDepth },
      get controlValveId() { return controlValveId },
      get controlValveDistance() { return controlValveDistance },
      get testingNumber() { return testingNumber },
      get comments() { return comments },
      get lastMaintenanceDate() { return lastMaintenanceDate },
      get maxTorque() { return maxTorque },
      get maxTorqueRevolutions() { return maxTorqueRevolutions },
      get maxRevolutions() { return maxRevolutions },
      get totalRevolutions() { return totalRevolutions },
      get exerciseFrequencyMonths() { return exerciseFrequencyMonths },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        console.log(this);
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}