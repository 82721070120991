import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../../components/LabelledTextBox';
import LabelledPicker from '../../components/LabelledPicker';
import LabelledDatePicker from '../../components/LabelledDatePicker';
import LabelledImagePicker from '../../components/LabelledImagePicker';
import LabelledTextArea from '../../components/LabelledTextArea';
import { getHydrantEdit, updateHydrant, getHydrantModels, getHydrantManufacturers, updateHydrantImage, getHydrantImage, deleteHydrantImage } from '../../services/api';
import EventEmitter from '../../services/events';
import { makeHydrant, makeHydrantModel, makeHydrantImage, makeHydrantManufacturer } from '../../models';
import { stateOptions } from '../../utils/dropdownOptions/stateOptions';
import { gpsFixOptions } from '../../utils/dropdownOptions/gpsFixOptions';
import LatLongSelector from '../../components/LatLongSelector';
import CircleButton from '../../components/CircleButton';
import { faMapMarkedAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import AddHydrantModelModal from './AddHydrantModelModal';
import AddHydrantManufacturerModal from './AddHydrantManufacturerModal';
import { isFileImage, getBase64 } from '../../utils/files';

const EditHydrant = () => {
  const [hydrant, setHydrant] = useState(null);
  const [hydrantImage, setHydrantImage] = useState(null);
  const [hydrantModels, setHydrantModels] = useState([]);
  const [hydrantManufacturers, setHydrantManufacturers] = useState([]);
  const { path, url } = useRouteMatch();
  const { hydrantId } = useParams();
  const [latLongSelectorOpen, setLatLongSelectorOpen] = useState(false);
  const [addHydrantModelModalOpen, setAddHydrantModelModalOpen] = useState(false);
  const [addHydrantManufacturerModalOpen, setAddHydrantManufacturerModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const history = useHistory();

  // useEffect(() => {
  //   setHydrant(hydrantParam);
  // }, [hydrantParam])

  useEffect(() => {
    setupHydrant();
    setupHydrantImage();
    setupHydrantModels();
    setupHydrantManufacturers();
  }, [hydrantId]);

  const setupHydrant = async () => {
    const result = await getHydrantEdit(hydrantId);
    setHydrant(makeHydrant(result));
  };

  const setupHydrantImage = async () => {
    const result = await getHydrantImage(hydrantId);
    if (result.deletedDate) return;
    setHydrantImage(makeHydrantImage(result));
  };

  const setupHydrantModels = async () => {
    const result = await getHydrantModels();
    setHydrantModels(result.map(r => makeHydrantModel(r)));
  };

  const setupHydrantManufacturers = async () => {
    const result = await getHydrantManufacturers();
    setHydrantManufacturers(result.map(r => makeHydrantManufacturer(r)));
  };

  const save = async () => {
    try {
      console.log(hydrant);
      hydrant.validate();
      const result = await updateHydrant(hydrant);

      // const iamgeFormData = new FormData();
      // iamgeFormData.append('hydrantId', hydrantId);
      // iamgeFormData.append('file', selectedImage);
      // console.log(selectedImage);
      // for (var key of iamgeFormData.entries()) {
      //   console.log(key[0] + ", " + key[1]);
      // }

      if (hydrantImage) {
        hydrantImage.validate();
        const data = await updateHydrantImage(hydrantImage);
        //const uploadedImage = await data.json();
        if (data) {
          console.log("Successfully uploaded image");
        } else {
          console.log("Error Found");
        }
      } else {
        await deleteHydrantImage(hydrantId);
      }

      EventEmitter.emit('show-notification', 'Updated Hydrant');
      history.replace(`/hydrants/${hydrant.id}`);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant', body: 'Unable to save hydrant.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    setHydrant(makeHydrant({ ...hydrant, [e.target.name]: e.target.value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
    }

    setHydrant(makeHydrant({ ...hydrant, [e.target.name]: value }));
  };

  const selectLatLong = (coords) => {
    setLatLongSelectorOpen(false);
    setHydrant(makeHydrant({ ...hydrant, latitude: coords.latitude, longitude: coords.longitude }));
  };

  const handleAddHydrantModel = async (hydrantModelId) => {
    setAddHydrantModelModalOpen(false);
    setHydrant(makeHydrant({ ...hydrant, hydrantModelId }));
    await setupHydrantModels();
  };

  const handleAddHydrantManufacturer = async (hydrantManufacturerId) => {
    setAddHydrantManufacturerModalOpen(false);
    setHydrant(makeHydrant({ ...hydrant, hydrantManufacturerId }));
    await setupHydrantManufacturers();
  };

  const onImageChange = (e) => {
    if (e) {
      setHydrantImage(makeHydrantImage({ hydrantId, ...hydrantImage, ...e }))
    } else {
      setHydrantImage(null);
    }
  }

  const handleImageChange = async (e) => {
    console.log(e.target.files[0]);
    const fileName = e.target.files[0].name;
    const image64 = await getBase64(e.target.files[0]);
    const imageSplit = image64.split(',');
    const dataType = imageSplit[0];
    const image = imageSplit[1];
    setHydrantImage(makeHydrantImage({ hydrantId, ...hydrantImage, dataType, image, fileName }))
  };

  const handleRemoveImage = () => {
    setHydrantImage(null);
  };

  const hydrantModelOptions = hydrantModels.map((m, i) => <option key={m.id} value={m.id}>{m.modelId}</option>);
  const hydrantManufacturerOptions = hydrantManufacturers.map((m, i) => <option key={m.id} value={m.id}>{m.name}</option>);

  return (
    <div>
      <h3>Edit Hydrant</h3>
      {hydrant ? (
        <>
          <div className='details'>
            <LabelledTextBox label="Name" value={hydrant.name} name="name" onChange={onChange} onBlur={onBlur} />
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledTextBox type="number" label="Latitude" value={hydrant.latitude} name="latitude" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Longitude" value={hydrant.longitude} name="longitude" onChange={onChange} onBlur={onBlur} />
              </div>
              <div className='input-actions'>
                <CircleButton icon={faMapMarkedAlt} title="Select From Map Point" onClick={() => setLatLongSelectorOpen(true)}/>
              </div>
            </div>
            <LabelledPicker label="GPS Fix" value={hydrant.gpsFixId} name="gpsFixId" onChange={onChange} onBlur={onBlur} >
              <option value=""></option>
              {gpsFixOptions}
            </LabelledPicker>
            <LabelledTextBox type="number" label="Elevation" value={hydrant.elevation} name="elevation" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="HDOP" value={hydrant.hdop} name="hdop" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="VDOP" value={hydrant.vdop} name="vdop" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="State X" value={hydrant.stateX} name="stateX" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="State Y" value={hydrant.stateY} name="stateY" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Street Number" value={hydrant.streetNumber} name="streetNumber" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Street Name" value={hydrant.streetName} name="streetName" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Cross Street" value={hydrant.crossStreet} name="crossStreet" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="General Location" value={hydrant.generalLocation} name="generalLocation" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Location Notes" value={hydrant.locationNotes} name="locationNotes" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="City" value={hydrant.city} name="city" onChange={onChange} onBlur={onBlur} />
            <LabelledPicker label="State" value={hydrant.state} name="state" onChange={onChange} onBlur={onBlur} >
              <option value=""></option>
              {stateOptions}
            </LabelledPicker>
            <LabelledTextBox label="Zip Code" value={hydrant.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="District" value={hydrant.district} name="district" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Sub Division" value={hydrant.subDivision} name="subDivision" onChange={onChange} onBlur={onBlur} />
            <LabelledDatePicker label="Install Date" value={hydrant.installDate} name="installDate" onChange={onChange} showTimeSelect={false} />
            <LabelledTextBox label="Status" value={hydrant.status} name="status" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Description" value={hydrant.description} name="description" onChange={onChange} onBlur={onBlur} />
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledPicker label="Manufacturer ID" value={hydrant.hydrantManufacturerId} name="hydrantManufacturerId" onChange={onChange} onBlur={onBlur} >
                  <option value=""></option>
                  {hydrantManufacturerOptions}
                </LabelledPicker>
              </div>
              <div className='input-actions'>
                <CircleButton icon={faPlus} type="success" title="Add New Hydrant Manufacturer" onClick={() => setAddHydrantManufacturerModalOpen(true)}/>
              </div>
            </div>
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledPicker label="Model ID" value={hydrant.hydrantModelId} name="hydrantModelId" onChange={onChange} onBlur={onBlur} >
                  <option value=""></option>
                  {hydrantModelOptions}
                </LabelledPicker>
              </div>
              <div className='input-actions'>
                <CircleButton icon={faPlus} type="success" title="Add New Hydrant Model" onClick={() => setAddHydrantModelModalOpen(true)}/>
              </div>
            </div>
            <LabelledTextBox label="Paint Color" value={hydrant.paintColor} name="paintColor" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Vandal Proof Type" value={hydrant.vandalProofType} name="vandalProofType" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Restraint" value={hydrant.restraint} name="restraint" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Main Size" value={hydrant.mainSize} name="mainSize" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Bury Depth" value={hydrant.buryDepth} name="buryDepth" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Control Valve Distance" value={hydrant.controlValveDistance} name="controlValveDistance" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Testing Number" value={hydrant.testingNumber} name="testingNumber" onChange={onChange} onBlur={onBlur} />
            <LabelledTextArea label="Comments" value={hydrant.comments} name="comments" onChange={onChange} onBlur={onBlur} />
            <LabelledImagePicker label="Image" value={hydrantImage} onChange={onImageChange} />
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
          <AddHydrantManufacturerModal open={addHydrantManufacturerModalOpen} onCancel={() => setAddHydrantManufacturerModalOpen(false)} onComplete={handleAddHydrantManufacturer} />
          <AddHydrantModelModal open={addHydrantModelModalOpen} onCancel={() => setAddHydrantModelModalOpen(false)} onComplete={handleAddHydrantModel} />
          <LatLongSelector open={latLongSelectorOpen} onCancel={() => setLatLongSelectorOpen(false)} onSelect={selectLatLong} point={{ latitude: hydrant.latitude, longitude: hydrant.longitude }} />
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditHydrant;