export default function buildMakeHydrantExerciseSettings({ validate, validators }) {
  return function makeHydrantExerciseSettings({
    hydrantId,
    companyId,
    torqueSetPoint,
    rpmSetPoint,
    openDirection,
    currentState,
    revolutions,
    stuckRevs,
    stuckAttempts,
    openRecess,
    closeRecess,
    flushRevolutions,
    flushCycles,
    hardstopSpeed,
    autoCycles,
    exerciseFrequencyMonths,
    torqueSetPointLocked,
    rpmSetPointLocked,
    openDirectionLocked,
    currentStateLocked,
    revolutionsLocked,
    stuckRevsLocked,
    stuckAttemptsLocked,
    openRecessLocked,
    closeRecessLocked,
    flushRevolutionsLocked,
    flushCyclesLocked,
    hardstopSpeedLocked,
    autoCyclesLocked,
    exerciseFrequencyMonthsLocked,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      hydrantId: {
        prettyName: 'Hydrant ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      torqueSetPoint: {
        prettyName: 'Torque Set Point',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(800)],
      },
      rpmSetPoint: {
        prettyName: 'RPM Set Point',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(1), validators.lessThanOrEqualTo(255)],
      },
      openDirection: {
        prettyName: 'Open Direction',
        validators: [validators.within(['Left', 'Right'])],
      },
      currentState: {
        prettyName: 'Current State',
        validators: [validators.within(['Open', 'Closed'])],
      },
      revolutions: {
        prettyName: 'Revolutions',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(1), validators.lessThanOrEqualTo(255)],
      },
      stuckRevs: {
        prettyName: 'Stuck Revs',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      stuckAttempts: {
        prettyName: 'Stuck Attempts',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      openRecess: {
        prettyName: 'Open Recess',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      closeRecess: {
        prettyName: 'Close Recess',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      flushRevolutions: {
        prettyName: 'Flush Revolutions',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      flushCycles: {
        prettyName: 'Flush Cycles',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      hardstopSpeed: {
        prettyName: 'Hardstop Speed',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(100)],
      },
      autoCycles: {
        prettyName: 'Auto Cycles',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      exerciseFrequencyMonths: {
        prettyName: 'Exercise Frequency Months',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0)],
      },
      torqueSetPointLocked: {
        prettyName: 'Torque Set Point Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      rpmSetPointLocked: {
        prettyName: 'RPM Set Point Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      openDirectionLocked: {
        prettyName: 'Open Direction Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      currentStateLocked: {
        prettyName: 'Current State Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      revolutionsLocked: {
        prettyName: 'Revolutions Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      stuckRevsLocked: {
        prettyName: 'Stuck Revs Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      stuckAttemptsLocked: {
        prettyName: 'Stuck Attempts Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      openRecessLocked: {
        prettyName: 'Open Recess Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      closeRecessLocked: {
        prettyName: 'Close Recess Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      flushRevolutionsLocked: {
        prettyName: 'Flush Revolutions Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      flushCyclesLocked: {
        prettyName: 'Flush Cycles Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      hardstopSpeedLocked: {
        prettyName: 'Hardstop Speed Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      autoCyclesLocked: {
        prettyName: 'Auto Cycles Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
      exerciseFrequencyMonthsLocked: {
        prettyName: 'Exercise Frequency Months Locked',
        validators: [validators.isBoolean(), validators.notNull()],
      },
    };

    return Object.freeze({
      get hydrantId() { return hydrantId },
      get companyId() { return companyId },
      get torqueSetPoint() { return torqueSetPoint },
      get rpmSetPoint() { return rpmSetPoint },
      get openDirection() { return openDirection },
      get currentState() { return currentState },
      get revolutions() { return revolutions },
      get stuckRevs() { return stuckRevs },
      get stuckAttempts() { return stuckAttempts },
      get openRecess() { return openRecess },
      get closeRecess() { return closeRecess },
      get flushRevolutions() { return flushRevolutions },
      get flushCycles() { return flushCycles },
      get hardstopSpeed() { return hardstopSpeed },
      get autoCycles() { return autoCycles },
      get exerciseFrequencyMonths() { return exerciseFrequencyMonths },
      get torqueSetPointLocked() { return torqueSetPointLocked },
      get rpmSetPointLocked() { return rpmSetPointLocked },
      get openDirectionLocked() { return openDirectionLocked },
      get currentStateLocked() { return currentStateLocked },
      get revolutionsLocked() { return revolutionsLocked },
      get stuckRevsLocked() { return stuckRevsLocked },
      get stuckAttemptsLocked() { return stuckAttemptsLocked },
      get openRecessLocked() { return openRecessLocked },
      get closeRecessLocked() { return closeRecessLocked },
      get flushRevolutionsLocked() { return flushRevolutionsLocked },
      get flushCyclesLocked() { return flushCyclesLocked },
      get hardstopSpeedLocked() { return hardstopSpeedLocked },
      get autoCyclesLocked() { return autoCyclesLocked },
      get exerciseFrequencyMonthsLocked() { return exerciseFrequencyMonthsLocked },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      unlock() {
        return makeHydrantExerciseSettings({
          ...this,
          torqueSetPointLocked: false,
          rpmSetPointLocked: false,
          openDirectionLocked: false,
          currentStateLocked: false,
          revolutionsLocked: false,
          stuckRevsLocked: false,
          stuckAttemptsLocked: false,
          openRecessLocked: false,
          closeRecessLocked: false,
          flushRevolutionsLocked: false,
          flushCyclesLocked: false,
          hardstopSpeedLocked: false,
          autoCyclesLocked: false,
          exerciseFrequencyMonthsLocked: false,
        });
      },
      lock() {
        return makeHydrantExerciseSettings({
          ...this,
          torqueSetPointLocked: true,
          rpmSetPointLocked: true,
          openDirectionLocked: true,
          currentStateLocked: true,
          revolutionsLocked: true,
          stuckRevsLocked: true,
          stuckAttemptsLocked: true,
          openRecessLocked: true,
          closeRecessLocked: true,
          flushRevolutionsLocked: true,
          flushCyclesLocked: true,
          hardstopSpeedLocked: true,
          autoCyclesLocked: true,
          exerciseFrequencyMonthsLocked: true,
        });
      },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}