import { useLocation, Redirect, useHistory } from 'react-router-dom'
import useAuth from './useAuth';
import Role from './Roles';

const RequireAuth = ({ children, roles = [Role.User, Role.Admin], requireCompany = true, requireCompanySubscribed = true, redirect = true }) => {
  const { authed, companyId, user, currentCompany } = useAuth();
  const location = useLocation();
  const history = useHistory();

  let result = children;
  const rolesAccepted = user?.roles?.some(ur => roles.includes(ur.RoleName) && ur.CompanyId === companyId) ?? false;
  if (!authed) {
    result = redirect ? <Redirect
        to="/login"
        replace
        state={{ path: location.pathname }}
      />
      : null;
  } else if (requireCompany && !companyId) {
    result = redirect ? <Redirect
        to="/selectCompany"
        replace
        state={{ path: location.pathname }}
      />
      : null;
  } else if (requireCompany && !rolesAccepted) {
    result = null;
    history.goBack();
  } else if (requireCompany && requireCompanySubscribed && !currentCompany.isSubscribed) {
    result = redirect ? <Redirect
        to="/company"
        replace
        state={{ path: location.pathname }}
      />
      : null;
  }

  return result || null;
};

export default RequireAuth;