import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../../../components/LabelledTextField';
import { dateOptions, dateTimeOptions } from '../../../utils/dates';
import { getFireFlowTest, getFireFlowTestLatest, deleteFireFlowTest, getHydrant, getHydrantModel, getCurrentCompany } from '../../../services/api';
import FireFlowTestModal from '../../../components/fireFlowTests/FireFlowTestModal';
import IsAuthorized from '../../../services/authentication/IsAuthorized';
import { makeHydrant, makeFireFlowTest, makeHydrantModel } from '../../../models';
import ExerciseChart from '../../../components/ExerciseChart';
import CircleButton from '../../../components/CircleButton';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const FireFlowTest = () => {
  const [test, setTest] = useState(null);
  const [hydrant, setHydrant] = useState(null);
  const [company, setCompany] = useState(null);
  const [hydrantModel, setHydrantModel] = useState(null);
  const [loadingExercise, setLoadingExercise] = useState(false);
  const [loadingHydrant, setLoadingHydrant] = useState(false);
  const [loadingHydrantModel, setLoadingHydrantModel] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDelete, setStartDelete] = useState(false);
  const { hydrantId, fireFlowTestId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupCompany();
  }, []);

  useEffect(() => {
    setupHydrant();
    setupTest();
  }, [hydrantId, fireFlowTestId, history.location]);

  useEffect(() => {
    setupHydrantModel();
  }, [hydrant]);

  const setupCompany = async () => {
    const result = await getCurrentCompany();
    setCompany(result);
  };

  const setupHydrant = async () => {
    try {
      setLoadingHydrant(true);
      const result = await getHydrant(hydrantId);
      console.log(result);
      setHydrant(makeHydrant(result));
      setLoadingHydrant(false);
    } catch (ex) {
      setTest(null);
      setLoadingHydrant(false);
    }
  };

  const setupHydrantModel = async () => {
    try {
      setLoadingHydrantModel(true);
      if (hydrant?.hydrantModelId) {
        const result = await getHydrantModel(hydrant?.hydrantModelId);
        setHydrantModel(makeHydrantModel(result));
      }
      setLoadingHydrantModel(false);
    } catch (ex) {
      setHydrantModel(null);
      setLoadingHydrantModel(false);
    }
  };

  const setupTest = async () => {
    const temp = {};
    try {
      setLoadingExercise(true);
      const result = fireFlowTestId ? await getFireFlowTest(fireFlowTestId) : await getFireFlowTestLatest(hydrantId);
      console.log(result);
      // setTest(makeFireFlowTest(result));
      setTest(makeFireFlowTest(temp));
      setLoadingExercise(false);
    } catch (ex) {
      // setTest(null);
      setTest(makeFireFlowTest(temp));
      setLoadingExercise(false);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const selectTest = (testId) => {
    history.push(`/hydrants/${hydrantId}/fireFlowTest/${testId}`);
  };

  const deleteRecord = async () => {
    try {
      setStartDelete(false);
      await deleteFireFlowTest(test.id);
      history.replace(`/hydrants/${hydrantId}/fireFlowTest`);
    } catch (ex) {
      console.log(ex);
      history.replace(`/hydrants/${hydrantId}/fireFlowTest`);
    }
  };

  const testDate = new Date(test?.testDate ?? null);
  const testDateString = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(testDate);

  const testSuccess = false;
  
  return (
    <div className="hydrant-test">
      <div className="report-title">
        <div className="title">
          <h3>Fire Flow Test</h3>
          <div className="form-links no-print">
            <div className='link' onClick={toggleModal}>Change Test</div>
            <Link to={`/reports/history/${hydrantId}`} target='_blank'>View Test History</Link>
          </div>
        </div>
        {loadingHydrant || loadingExercise || <CircleButton icon={faPrint} size="large" title="Print" onClick={print} className="no-print" />}
      </div>
      {loadingExercise && <div className="spinner"></div>}
      {!loadingExercise && test && (
        <>
          {!startDelete ? (
            <div className="form-actions no-print">
              <IsAuthorized>
                <Link to={`/hydrants/${hydrantId}/fireFlowTest/${test.id}/edit`}>Edit</Link>
                <div className="link" onClick={() => setStartDelete(true)}>Delete</div>
              </IsAuthorized>
            </div>
          ) : (
            <div className="form-actions no-print">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
          <div className="fireflow-header">
            <div className="company-info">
              <div className="company-detail">
                <label>Company Name:</label>
                <span>{company?.name}</span>
              </div>
            </div>
            <div className="fireflow-info">
              <div className="fireflow-date">
                <label>Test Date:</label>
                <span>{testDateString}</span>
              </div>
              <div className="fireflow-classification">
                <div className="fireflow-classification-title">
                  <span>NFPA Classification</span>
                </div>
                <div className="fireflow-classification-code">
                  <span>Blue</span>
                  <span>AA</span>
                </div>
                <div className="fireflow-classification-number">
                  <span>1612.25</span>
                </div>
              </div>
              <div className="fireflow-conclusion">
                {testSuccess ? <span className="success">Test did reach recommended drop of 25% per NFPA 291</span>
                : <span className="fail">Test did not reach recommended drop of 25% per NFPA 291</span>}
              </div>
            </div>
          </div>
          <div className="result-details">
            <div className="result-detail">
              <label>Device Type:</label>
              <span>Hydrant</span>
            </div>
            <div className="result-detail">
              <label>Device ID:</label>
              <span>{hydrant?.name}</span>
            </div>
          </div>
          <div className="result-details">
            <div className="result-detail-double">
              <label>Pumpers:</label>
              <span>{hydrantModel?.pumperCount}</span>
              <span>{hydrantModel?.pumperCoefficient}</span>
            </div>
            <div className="result-detail-double">
              <label>Nozzles:</label>
              <span>{hydrantModel?.nozzleCount}</span>
              <span>{hydrantModel?.nozzleCoefficient}</span>
            </div>
            <div className="result-detail">
              <label>Open Direction:</label>
              <span>{hydrantModel?.openDirection}</span>
            </div>
          </div>
          <div className="location">
            <div className="location-info">
              <div className="location-detail">
                <label>Address:</label>
                <span>{((hydrant?.streetNumber ?? '') + ' ' + (hydrant?.streetName ?? '')).trim()}</span>
              </div>
              <div className="location-detail">
                <label>Cross Street:</label>
                <span>{hydrant?.crossStreet}</span>
              </div>
              <div className="location-detail">
                <label>Location:</label>
                <span>{hydrant?.locationNotes}</span>
              </div>
              <div className="location-detail">
                <label>District:</label>
                <span>{hydrant?.district}</span>
              </div>
              <div className="location-detail">
                <label>Sub-Division:</label>
                <span>{hydrant?.subDivision}</span>
              </div>
            </div>
            <div className="gps-info">
              <div className="gps-detail">
                <label>Latitude:</label>
                <span>{hydrant?.latitude?.toFixed(6)}</span>
              </div>
              <div className="gps-detail">
                <label>Longitude:</label>
                <span>{hydrant?.longitude?.toFixed(6)}</span>
              </div>
              <div className="gps-detail">
                <label>Elevation:</label>
                <span>{hydrant?.elevation?.toFixed(2)}</span>
              </div>
              <div className="gps-detail">
                <label>State X / Y:</label>
                <span>{hydrant?.stateX} / {hydrant?.stateY}</span>
              </div>
            </div>
          </div>
          <div className="info">
            <div className="info-detail">
              <label>Manufacturer:</label>
              <span>{hydrant?.manufacturer}</span>
            </div>
            <div className="info-detail">
              <label>Install Date:</label>
              <span>{hydrant?.installDate ? new Date(hydrant.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="info-detail">
              <label>Status:</label>
              <span>{hydrant?.status}</span>
            </div>
            <div className="info-detail">
              <label>Hydrant Size:</label>
              <span>{hydrantModel?.valveOpeningSize?.toFixed(2)}</span>
            </div>
            <div className="info-detail">
              <label>Open Direction:</label>
              <span>{hydrantModel?.openDirection}</span>
            </div>
            <div className="info-detail">
              <label>Bury Depth:</label>
              <span>{hydrant?.buryDepth?.toFixed(2)}</span>
            </div>
          </div>
          {/* {!test.manual && <TestChart test={test} />} */}
        </>
      )}
      {!loadingExercise && !test && (
        <div className="empty-body">
          <div className="empty-text">No Test Found</div>
          <div className="empty-text-small">Unable to find a fire flow test.</div>
        </div>
      )}
      <FireFlowTestModal hydrantId={hydrantId} open={modalOpen} toggle={toggleModal} onSelect={selectTest} fireFlowTest={test} />
    </div>
  );
};

export default FireFlowTest;