import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { getPlans } from '../../services/api';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const SubscriptionSelection = ({ selectedId, onSelectionChange }) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setupSubscriptionPlans();
  }, []);

  const setupSubscriptionPlans = async () => {
    try {
      setLoading(true);
      const plansResult = await getPlans();
      console.log(plansResult);
      setSubscriptionPlans(plansResult);
      if (!selectedId && plansResult.length > 0) {
        onSelectionChange(plansResult[0].id);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  var plansJsx = subscriptionPlans.map(p => {
    return (
      <div key={p.id} className={`card card-subscription card-spaced${selectedId === p?.id ? ' card-selected' : ''}`} onClick={() => onSelectionChange(p?.id)}>
        <div className='card-info'>
          <h4>{p?.name}</h4>
          <div className="subscripton-details">
            <div>${p?.amount.toFixed(2)} / {p?.frequency.toLowerCase()}</div>
          </div>
        </div>
        <div className='card-icon'>
          <FontAwesomeIcon icon={selectedId === p?.id ? faCheckCircle : faCircle} />
        </div>
      </div>
    );
  })

  return loading ? <div className="spinner"></div> : (
    <div className='plans-selection'>
      {plansJsx}
    </div>
  );
};

export default SubscriptionSelection;