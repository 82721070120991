export default function buildMakeValve({ validate, validators }) {
  return function makeValve({
    id,
    companyId,
    name,
    latitude,
    longitude,
    gpsFixId,
    elevation,
    hdop,
    vdop,
    stateX,
    stateY,
    accessType,
    streetNumber,
    streetName,
    crossStreet,
    locationNotes,
    city,
    state,
    zipCode,
    manufacturer,
    installDate,
    size,
    boxSize,
    boxDepth,
    type,
    function: thisFunction,
    drive,
    district,
    subDivision,
    status,
    comments,
    lastMaintenanceDate,
    maxTorque,
    maxTorqueRevolutions,
    maxRevolutions,
    totalRevolutions,
    exerciseFrequencyMonths,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      companyId: {
        prettyName: 'Company ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
      name: {
        prettyName: 'Name',
        validators: [validators.notEmpty(), validators.maxLength(50)],
      },
      latitude: {
        prettyName: 'Latitude',
        validators: [validators.isNumber()],
      },
      longitude: {
        prettyName: 'Longitude',
        validators: [validators.isNumber()],
      },
      gpsFixId: {
        prettyName: 'GPS Fix',
        validators: [validators.isInteger()],
      },
      elevation: {
        prettyName: 'Elevation',
        validators: [validators.isNumber()],
      },
      hdop: {
        prettyName: 'HDOP',
        validators: [validators.isNumber()],
      },
      vdop: {
        prettyName: 'VDOP',
        validators: [validators.isNumber()],
      },
      stateX: {
        prettyName: 'State X',
        validators: [validators.isNumber()],
      },
      stateY: {
        prettyName: 'State Y',
        validators: [validators.isNumber()],
      },
      accessType: {
        prettyName: 'Access Type',
        validators: [validators.maxLength(20)],
      },
      streetNumber: {
        prettyName: 'Street Number',
        validators: [validators.maxLength(20)],
      },
      streetName: {
        prettyName: 'Street Name',
        validators: [validators.maxLength(50)],
      },
      crossStreet: {
        prettyName: 'Cross Street',
        validators: [validators.maxLength(50)],
      },
      city: {
        prettyName: 'City',
        validators: [validators.maxLength(20)],
      },
      state: {
        prettyName: 'State',
        validators: [validators.exactLength(2)],
      },
      zipCode: {
        prettyName: 'Zip Code',
        validators: [validators.maxLength(15)],
      },
      manufacturer: {
        prettyName: 'Manufacturer',
        validators: [validators.maxLength(50)],
      },
      installDate: {
        prettyName: 'Install Date',
        validators: [validators.isDate()],
      },
      size: {
        prettyName: 'Size',
        validators: [validators.isInteger()],
      },
      boxSize: {
        prettyName: 'Box Size',
        validators: [validators.isNumber()],
      },
      boxDepth: {
        prettyName: 'Box Depth',
        validators: [validators.isNumber()],
      },
      type: {
        prettyName: 'Type',
        validators: [validators.maxLength(20)],
      },
      function: {
        prettyName: 'Function',
        validators: [validators.maxLength(20)],
      },
      drive: {
        prettyName: 'Drive',
        validators: [validators.maxLength(20)],
      },
      district: {
        prettyName: 'District',
        validators: [validators.maxLength(20)],
      },
      subDivision: {
        prettyName: 'Sub-Division',
        validators: [validators.maxLength(20)],
      },
      status: {
        prettyName: 'Name',
        validators: [validators.maxLength(20)],
      },
      comments: {
        prettyName: 'Comments',
        validators: [validators.isString()],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get name() { return name },
      get latitude() { return latitude },
      get longitude() { return longitude },
      get gpsFixId() { return gpsFixId },
      get elevation() { return elevation },
      get hdop() { return hdop },
      get vdop() { return vdop },
      get stateX() { return stateX },
      get stateY() { return stateY },
      get accessType() { return accessType },
      get streetNumber() { return streetNumber },
      get streetName() { return streetName },
      get crossStreet() { return crossStreet },
      get locationNotes() { return locationNotes },
      get city() { return city },
      get state() { return state },
      get zipCode() { return zipCode },
      get manufacturer() { return manufacturer },
      get installDate() { return installDate ? (typeof(installDate) === typeof(String()) ? new Date(installDate.split('T')[0] + 'T00:00') : new Date(installDate)) : null },
      get size() { return size },
      get boxSize() { return boxSize },
      get boxDepth() { return boxDepth },
      get type() { return type },
      get function() { return thisFunction },
      get drive() { return drive },
      get district() { return district },
      get subDivision() { return subDivision },
      get status() { return status },
      get comments() { return comments },
      get lastMaintenanceDate() { return lastMaintenanceDate },
      get maxTorque() { return maxTorque },
      get maxTorqueRevolutions() { return maxTorqueRevolutions },
      get maxRevolutions() { return maxRevolutions },
      get totalRevolutions() { return totalRevolutions },
      get exerciseFrequencyMonths() { return exerciseFrequencyMonths },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        console.log(this);
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}