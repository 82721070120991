import React from 'react';

const LabelledImage = ({ label, value }) => {
  console.log(value);
  return (
    <div className="labelled-image">
      <span className="label">{label}</span>
      {value && <img alt={label} src={`${value?.dataType},${value?.image}`} />}
    </div>
  );
};

export default LabelledImage;
