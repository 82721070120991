import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../components/LabelledTextBox';
import LabelledPicker from '../components/LabelledPicker';
import LabelledDatePicker from '../components/LabelledDatePicker';
import { dateOptions } from '../utils/dates';
import axios from 'axios';
import { getValveEdit, updateValve } from '../services/api';
import EventEmitter from '../services/events';
import { makeValve } from '../models';
import { stateOptions } from '../utils/dropdownOptions/stateOptions';
import { gpsFixOptions } from '../utils/dropdownOptions/gpsFixOptions';
import LatLongSelector from '../components/LatLongSelector';
import CircleButton from '../components/CircleButton';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

const EditValve = ({ onUpdate }) => {
  const [valve, setValve] = useState(null);
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const [latLongSelectorOpen, setLatLongSelectorOpen] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   setValve(valveParam);
  // }, [valveParam])

  useEffect(() => {
    setupValve();
  }, [valveId]);

  const setupValve = async () => {
    const result = await getValveEdit(valveId);
    setValve(makeValve(result));
  };

  const save = async () => {
    try {
      console.log(valve);
      valve.validate();
      const result = await updateValve(valve);
      EventEmitter.emit('show-notification', 'Updated Valve');
      onUpdate(valve.id);
      history.replace(`/valves/${valve.id}`);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Valve', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Valve', body: 'Unable to save valve.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
    }
    
    setValve(makeValve({ ...valve, [e.target.name]: value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
    }

    setValve(makeValve({ ...valve, [e.target.name]: value }));
  };

  const selectLatLong = (coords) => {
    setLatLongSelectorOpen(false);
    setValve(makeValve({ ...valve, latitude: coords.latitude, longitude: coords.longitude }));
  };

  return (
    <div>
      <h3>Edit Valve</h3>
      {valve ? (
        <>
          <div className='details'>
            <LabelledTextBox label="Name" value={valve.name} name="name" onChange={onChange} onBlur={onBlur} />
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledTextBox type="number" label="Latitude" value={valve.latitude} name="latitude" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Longitude" value={valve.longitude} name="longitude" onChange={onChange} onBlur={onBlur} />
              </div>
              <div className='input-actions'>
                <CircleButton icon={faMapMarkedAlt} onClick={() => setLatLongSelectorOpen(true)}/>
              </div>
            </div>
            <LabelledPicker label="GPS Fix" value={valve.gpsFixId} name="gpsFixId" onChange={onChange} onBlur={onBlur} >
              <option value=""></option>
              {gpsFixOptions}
            </LabelledPicker>
            <LabelledTextBox type="number" label="Elevation" value={valve.elevation} name="elevation" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="HDOP" value={valve.hdop} name="hdop" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="VDOP" value={valve.vdop} name="vdop" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="State X" value={valve.stateX} name="stateX" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="State Y" value={valve.stateY} name="stateY" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Access Type" value={valve.accessType} name="accessType" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Street Number" value={valve.streetNumber} name="streetNumber" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Street Name" value={valve.streetName} name="streetName" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Cross Street" value={valve.crossStreet} name="crossStreet" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="City" value={valve.city} name="city" onChange={onChange} onBlur={onBlur} />
            <LabelledPicker label="State" value={valve.state} name="state" onChange={onChange} onBlur={onBlur} >
              <option value=""></option>
              {stateOptions}
            </LabelledPicker>
            <LabelledTextBox label="Zip Code" value={valve.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Location Notes" value={valve.locationNotes} name="locationNotes" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Manufacturer" value={valve.manufacturer} name="manufacturer" onChange={onChange} onBlur={onBlur} />
            <LabelledDatePicker label="Install Date" value={valve.installDate} name="installDate" onChange={onChange} showTimeSelect={false} />
            <LabelledTextBox type="number" label="Size" value={valve.size} name="size" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Box Size" value={valve.boxSize} name="boxSize" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Box Depth" value={valve.boxDepth} name="boxDepth" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Type" value={valve.type} name="type" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Function" value={valve.function} name="function" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Drive" value={valve.drive} name="drive" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="District" value={valve.district} name="district" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Sub Division" value={valve.subDivision} name="subDivision" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Status" value={valve.status} name="status" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Comments" value={valve.comments} name="comments" onChange={onChange} onBlur={onBlur} />
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
          <LatLongSelector open={latLongSelectorOpen} onCancel={() => setLatLongSelectorOpen(false)} onSelect={selectLatLong} point={{ latitude: valve.latitude, longitude: valve.longitude }} />
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditValve;