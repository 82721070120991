import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody
} from 'reactstrap';
import { getFireFlowTests } from '../../services/api';
import FireFlowTestTableRow from './FireFlowTestTableRow';
import { makeFireFlowTest } from '../../models';

const FireFlowTestModal = ({ hydrantId, open, toggle, onSelect, fireFlowTest }) => {
  const [fireFlowTests, setFireFlowTests] = useState([]);

  useEffect(() => {
    setupFireFlowTest();
  }, [hydrantId, fireFlowTest]);

  const setupFireFlowTest = async () => {
    const result = await getFireFlowTests(hydrantId);
    const sortedResult = result.sort((a, b) => {
      if (a.testDate < b.testDate) {
        return -1;
      } else if (a.testDate > b.testDate) {
        return 1;
      } else {
        return 0;
      }
    });
    const mappedTests = result.map(e => makeFireFlowTest(e));
    setFireFlowTests(mappedTests);
  };

  const select = id => {
    toggle();
    onSelect(id);
  };

  const fireFlowTestRows = fireFlowTests?.map(exercise => 
    <FireFlowTestTableRow key={exercise.id} exercise={exercise} onSelect={select} />);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalBody>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Test Date</th>
              <th>Nozzle PSI</th>
              <th>Static PSI</th>
              <th>Residual PSI</th>
              <th>Percent Drop</th>
              <th>Total Gallons Used</th>
              <th>Max GPM</th>
              <th>Elapsed Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fireFlowTestRows}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default FireFlowTestModal;