import React, { useState, useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { faMapMarkedAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import Search from '../components/Search';
import CircleButton from '../components/CircleButton';
import useAuth from '../services/authentication/useAuth';
import UserTableRow from '../components/users/UserTableRow';
import InviteTableRow from '../components/users/InviteTableRow';
import { getUserInvites, getUsers, getRoles, uninviteUser } from '../services/api';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [roles, setRoles] = useState([]);
  const { path, url } = useRouteMatch();
  const { companyId } = useAuth();

  useEffect(() => {
    setupRoles();
    setupUsers();
    setupInvites();
  }, []);

  const setupRoles = async () => {
    const result = await getRoles();
    setRoles(result);
  };

  const setupUsers = async (searchText) => {
    const result = await getUsers(searchText);
    setUsers(result);
  };

  const setupInvites = async () => {
    const result = await getUserInvites();
    setInvites(result);
  };

  const handleUninviteUser = async (email) => {
    await uninviteUser({
      email
    });
    setupInvites();
  };

  const userRows = users?.map((user, index) => <UserTableRow key={index} user={user} />);
  const inviteRows = invites?.map((invite, index) => <InviteTableRow key={index} invite={invite} roles={roles} onUninvite={handleUninviteUser} />);

  const search = (searchText) => {
    setupUsers(searchText);
  };

  return (
    <div id="users" className="list">
      <h2>Users</h2>
      <div className="list-actions">
        <Search onSearch={search} />
        <Link to={`${url}/addUserRoles`}>
          <CircleButton icon={faPlus} type="success" title="Add User" />
        </Link>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Email</th>
            <th>Roles</th>
            <th className="command-row"></th>
          </tr>
        </thead>
        <tbody>
          {userRows}
        </tbody>
      </table>
      <h3>Invites</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Email</th>
            <th>Roles</th>
            <th className="command-row"></th>
          </tr>
        </thead>
        <tbody>
          {inviteRows}
        </tbody>
      </table>
    </div>
  );
};

export default Users;