import { tryGet, tryPost, tryPut } from './index';

export const getCompanies = async (searchText) => {
  const result = await tryGet(`/companies?search=${searchText || ''}`);

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCompany = async (companyId) => {
  const result = await tryGet(`/companies/${companyId}`);

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCurrentCompany = async () => {
  const result = await tryGet('/companies/current');

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCompanyEdit = async (companyId) => {
  const result = await tryGet(`/companies/${companyId}/edit`);
  return result.data;
};

export const updateCompany = async (company) => {
  const result = await tryPut('/companies', company);
  return result.data;
};

export const addCompany = async (company) => {
  const result = await tryPost('/companies', company);
  return result.data;
};