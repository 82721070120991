import React, { useRef, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import valveImg from '../../assets/images/valve.png';
import ValveMapCard from './ValveMapCard';
import { faFileExport, faFileImport, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import Search from '../Search';
import CircleButton from '../CircleButton';
import CircleButtonCollapse from '../CircleButtonCollapse';
import useAuth from '../../services/authentication/useAuth';
import { getCurrentCompany } from '../../services/api';
import { addMonths } from '../../utils/dates';

mapboxgl.accessToken = 'pk.eyJ1Ijoid3dlZ2Vua2UiLCJhIjoiY2t3NWl5eWRlMTZocTJvbW5xenFlcDFlMCJ9.TQud3faSsauFW-7URATL4Q';

const ValveMap = ({ valves: paramValves, valveUpdate, mode, setMode, searchText, setSearchText, toggleUpload, toggleDownload }) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const auth = useAuth();
  const [company, setCompany] = useState(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapCards = useRef({});
  const [bbox, setBbox] = useState(null);
  const [lng, setLng] = useState(-93.3426);
  const [lat, setLat] = useState(39.2455);
  const [zoom, setZoom] = useState(4.2);
  const [loadingMap, setLoadingMap] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [selectedValveId, setSelectedValveId] = useState(null);
  const [currentBounds, setCurrentBounds] = useState(null);
  const [boundsTimeout, setBoundsTimeout] = useState(null);
  const [valves, setValves] = useState([]);
  const valvesRef = useRef(valves);
  const boundsRef = useRef(currentBounds);
  const [filteredValves, setFilteredValves] = useState(paramValves.filter(v => v.latitude && v.longitude));
  const filteredValvesRef = useRef(filteredValves);
  const location = useLocation();

  useEffect(() => {
    setupCompany();
  }, [auth.companyId, auth.user]);

  const setupCompany = async () => {
    if (auth.companyId) {
      const result = await getCurrentCompany();
      setCompany(result);
    }
  };

  useEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [location]);

  //Filter valves to only show ones on the map with a lat/long.
  useEffect(() => {
    setFilteredValves(paramValves.filter(v => v.latitude && v.longitude).map(v => ({
      ...v,
      exerciseTime: v.lastMaintenanceDate ? new Date(v.lastMaintenanceDate).getTime() : 0,
      exerciseWithinTime: addMonths(-(v.exerciseFrequencyMonths || 0)).getTime(),
      exerciseHalfWithinTime: addMonths(-(v.exerciseFrequencyMonths || 0) / 2).getTime()
    })));
  }, [paramValves]);

  //Update valves to only those that are on the current map
  useEffect(() => {
    if (boundsTimeout == null) {
      setBoundsTimeout(setTimeout(() => {
        updateValveCards();
        setBoundsTimeout(null);
      }, 2000));
    }
  }, [filteredValves, currentBounds]);

  useEffect(() => {
    if (selectedValveId && mapCards.current) {
      if (!mapCards.current[selectedValveId]) {
        updateValveCards();
      } else if (!mapCards.current[selectedValveId].classList.contains('selected')) {
        for (const i in mapCards.current) {
          const mapCard = mapCards.current[i];
          mapCard?.classList.remove('selected');
        }
        mapCards.current[selectedValveId].classList.add('selected');
        mapCards.current[selectedValveId]?.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (mapCards.current) {
      for (const i in mapCards.current) {
        const mapCard = mapCards.current[i];
        mapCard?.classList.remove('selected');
      }
    }
  }, [mapCards.current, selectedValveId]);

  useEffect(() => {
    if (!loadingMap && map.current) {
      map.current.setFilter('valves-highlight', ['==', 'id', selectedValveId || '']);
    }
  }, [map.current, loadingMap, selectedValveId]);

  //Set the map bounds if it's just been loaded
  useEffect(() => {
    if (mode === 'map' && map.current) {
      map.current.resize();
      if (!loaded) {
        setLoaded(true);
        if (bbox) {
          const bounds = [[bbox.minX - 0.1, bbox.minY - 0.1], [bbox.maxX + 0.1, bbox.maxY + 0.1]];
          map.current.fitBounds(bounds);
        }
      }
    }
  }, [mode]);

  //Set map data source and fit map around that.
  useEffect(() => {
    try {
      mapCards.current = {};
      if (loadingMap || !filteredValves) return;
      const featureCollection = {
        type: 'FeatureCollection',
        features: filteredValves?.map(getGeoJson),
      };
      map.current.getSource('valves')?.setData(featureCollection);

      if (filteredValves.length > 0 && valveUpdate == 0) {
        const newBbox = { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
        filteredValves.forEach(valve => {
          if (valve.longitude < newBbox.minX) {
            newBbox.minX = valve.longitude;
          }
          if (valve.longitude > newBbox.maxX) {
            newBbox.maxX = valve.longitude;
          }
          if (valve.latitude < newBbox.minY) {
            newBbox.minY = valve.latitude;
          }
          if (valve.latitude > newBbox.maxY) {
            newBbox.maxY = valve.latitude;
          }
        });
        const bounds = [[newBbox.minX - 0.1, newBbox.minY - 0.1], [newBbox.maxX + 0.1, newBbox.maxY + 0.1]];
        map.current.fitBounds(bounds);
        setBbox(newBbox);
      }

      valvesRef.current = valves;
    } catch (ex) {
      console.log(ex);
    }
  }, [filteredValves, loadingMap]);

  //Load map
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      boundsRef.current = map.current.getBounds();
      setCurrentBounds(boundsRef.current);
    });
    map.current.on('click', 'valves-layer', handleValvesClick);
    map.current.on('click', 'valves-cluster', handleValvesClusterClick);
    map.current.on('click', handleMapClick);
    map.current.on('load', () => {
      map.current.loadImage(valveImg, (error, valveImage) => {
        if (error) throw error;
        map.current.addImage('valve', valveImage, {
          'sdf': true
        });

        const featureCollection = {
          type: 'FeatureCollection',
          features: valves.map(getGeoJson),
        };
        map.current.addSource('valves', {
          type: 'geojson',
          data: featureCollection,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });
        
        map.current.addLayer({
          'id': 'valves-cluster',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['has', 'point_count'],
          'layout': {
            'icon-image': 'valve',
            'icon-size': 0.2,
            'icon-allow-overlap': true,
          }
        });

        map.current.addLayer({
          id: 'valves-cluster-count',
          type: 'symbol',
          source: 'valves',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 0.4]
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
        
        const exerciseHalfLife = addMonths(-30).toISOString().split('T')[0];
        const exerciseExpiration = addMonths(-60).toISOString().split('T')[0];
        map.current.addLayer({
          'id': 'valves-layer',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['!', ['has', 'point_count']],
          'layout': {
            'icon-image': 'valve',
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'icon-size': 0.18,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
          },
          'paint': {
            'icon-color': [
              "case", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", exerciseHalfLife]
              ], "#0000aa", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", exerciseExpiration]
              ], "#880088", 
              "#aa0000"
            ],
          },
        });
        
        map.current.addLayer({
          'id': 'valves-highlight',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['==', 'id', ''],
          'layout': {
            'icon-image': 'valve',
            'icon-size': 0.18,
            'icon-allow-overlap': true,
          },
          'paint': {
            'icon-color': "#00cc00"
            // [
            //   "case", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", exerciseHalfLife]
            //   ], "#0088ff", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", exerciseExpiration]
            //   ], "#dd66dd", 
            //   "#ff8800"
            // ],
          },
        });

        setLoadingMap(false);
      });
    });
  }, []);

  useEffect(() => {
    if (loadingMap || !map.current) return;

    const exerciseFrequency = company?.valveExerciseFrequencyMonths ?? 60;
    const exerciseHalfLife = addMonths(-Math.round(exerciseFrequency / 2)).toISOString().split('T')[0];
    const exerciseExpiration = addMonths(-exerciseFrequency).toISOString().split('T')[0];

    map.current.setPaintProperty('valves-layer', 'icon-color', [
      "case", [
        ">=", 
        ["to-number", ["get", "exerciseTime"]],
        ["to-number", ["get", "exerciseHalfWithinTime"]]
      ], valveLegend[0].color, [
        ">=", 
        ["to-number", ["get", "exerciseTime"]],
        ["to-number", ["get", "exerciseWithinTime"]]
      ], valveLegend[1].color, 
      valveLegend[2].color
    ]);

    map.current.setPaintProperty('valves-highlight', 'icon-color', [
      "case", [
        ">=", 
        ["to-number", ["get", "exerciseTime"]],
        ["to-number", ["get", "exerciseHalfWithinTime"]]
      ], valveLegend[0].highlight, [
        ">=", 
        ["to-number", ["get", "exerciseTime"]],
        ["to-number", ["get", "exerciseWithinTime"]]
      ], valveLegend[1].highlight, 
      valveLegend[2].highlight
    ]);
  }, [map.current, loadingMap, company]);

  const updateValveCards = () => {
    mapCards.current = {};
    valvesRef.current = (filteredValves?.filter((v, i) =>
      v.id === selectedValveId || (
        v.latitude > (boundsRef.current?._sw.lat ?? -Infinity) && v.latitude < (boundsRef.current?._ne.lat ?? Infinity)
        && v.longitude > (boundsRef.current?._sw.lng ?? -Infinity) && v.longitude < (boundsRef.current?._ne.lng ?? Infinity)
      )
    ).filter((v, i) => v.id === selectedValveId || i < 20) ?? []);
    setValves(valvesRef.current);
  };

  const handleValvesClick = (e) => {
    e.clickOnLayer = true;
    const selectedId = e.features[0].properties.id;
    setSelectedValveId(selectedId);
    mapCards.current[selectedId]?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleValvesClusterClick = (e) => {
    e.clickOnLayer = true;
    const features = e.features;
    const clusterId = features[0].properties.cluster_id;
    map.current.getSource('valves').getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
       
      map.current.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom
      });
    });
  };

  const handleMapClick = (e) => {
    if (e.clickOnLayer) return;
    console.log('MAP CLICKED');
    console.log(e);
    setSelectedValveId(null);
  }

  const zoomTo = (id, coordinates) => {
    setSelectedValveId(id);
    map.current.easeTo({
      center: coordinates,
      zoom: 18,
    });
  };

  return (
    <div className="map-container">
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div ref={mapContainer} className="map" />
      <div className="map-actions">
      </div>
      <div className="map-legend">
        {valveLegend.map((key, idx) => (
          <div className="legend-key" key={idx}>
            <div className="legend-key-icon" style={{backgroundColor: key.color}}></div>
            <div className="legend-key-text">{key.text}</div>
          </div>
        ))}
      </div>
      <div className="map-list">
        <div className="map-list-actions">
          <Search searchText={searchText} setSearchText={setSearchText} />
          <CircleButtonCollapse>
            <CircleButton icon={faPlus} type="success" title="Add Valve" onClick={() => history.push(`valves/add`)} />
            <CircleButton icon={faFileImport} type="alternate" title="Import Valves" onClick={toggleUpload} />
            <CircleButton icon={faFileExport} type="alternate" title="Export Valves" onClick={toggleDownload} />
          </CircleButtonCollapse>
          <CircleButton icon={faList} type="primary" title="List View" onClick={() => setMode('list')} />
        </div>
        <div className="map-cards">
          {valves?.map((v, i) => <ValveMapCard valve={v} key={i} zoomTo={zoomTo} ref={(element) => mapCards.current[v.id] = element} />)}
        </div>
      </div>
    </div>
  );
};

const getGeoJson = (object, idField = 'id') => {
  const geoJson = {
    type: 'Feature',
    id: object[idField],
    properties: {},
    geometry: {
        type: 'Point',
        coordinates: [object.longitude, object.latitude],
    },
  };
  for (const property in object) {
    if (property === 'Latitude' || property === 'Longitude') {
      continue;
    }

    geoJson.properties[property] = object[property];
  }

  return geoJson;
};

const valveLegend = [
  {
    color: '#0000aa',
    highlight: '#00cc00',
    text: 'Exercised'
  },
  {
    color: '#880088',
    highlight: '#00cc00',
    text: 'Exercise Half Life'
  },
  {
    color: '#aa0000',
    highlight: '#00cc00',
    text: 'Exercise Needed'
  }
]

export default ValveMap;