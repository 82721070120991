import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import CircleButton from '../CircleButton';

const CompanyTableRow = ({ company, selected, onSelect }) => {
  const { path, url } = useRouteMatch();
  
  return (
    <tr id={company.id}>
      {/* <td>{selected ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faCircle} />}</td> */}
      <td>{company.name}</td>
      <td>
        <div className="link-icon" onClick={() => onSelect(company.id)}>
          {selected ? 
            <CircleButton icon={faCheckCircle} title="Select" />
          : 
            <CircleButton icon={faCircle} title="Select" />
          }
        </div>
      </td>
    </tr>
  );
};

export default CompanyTableRow;