import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../../components/LabelledTextField';
import { dateOptions, dateTimeOptions } from '../../utils/dates';
import { getHydrantExercise, getHydrantExerciseLatest, deleteHydrantExercise, getHydrant } from '../../services/api';
import HydrantExerciseModal from '../../components/hydrantExercises/HydrantExerciseModal';
import IsAuthorized from '../../services/authentication/IsAuthorized';
import { makeHydrant, makeHydrantExercise } from '../../models';
import ExerciseChart from '../../components/ExerciseChart';
import CircleButton from '../../components/CircleButton';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const HydrantExercise = () => {
  const [exercise, setExercise] = useState(null);
  const [hydrant, setHydrant] = useState(null);
  const [loadingExercise, setLoadingExercise] = useState(false);
  const [loadingHydrant, setLoadingHydrant] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDelete, setStartDelete] = useState(false);
  const { hydrantId, hydrantExerciseId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupHydrant();
    setupExercise();
  }, [hydrantId, hydrantExerciseId, history.location]);

  const setupHydrant = async () => {
    try {
      setLoadingHydrant(true);
      const result = await getHydrant(hydrantId);
      console.log(result);
      setHydrant(makeHydrant(result));
      setLoadingHydrant(false);
    } catch (ex) {
      setExercise(null);
      setLoadingHydrant(false);
    }
  };

  const setupExercise = async () => {
    try {
      setLoadingExercise(true);
      const result = hydrantExerciseId ? await getHydrantExercise(hydrantExerciseId) : await getHydrantExerciseLatest(hydrantId);
      setExercise(makeHydrantExercise(result));
      setLoadingExercise(false);
    } catch (ex) {
      setExercise(null);
      setLoadingExercise(false);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const selectExercise = (exerciseId) => {
    history.push(`/hydrants/${hydrantId}/hydrantExercise/${exerciseId}`);
  };

  const deleteRecord = async () => {
    try {
      setStartDelete(false);
      await deleteHydrantExercise(exercise.id);
      history.replace(`/hydrants/${hydrantId}/hydrantExercise`);
    } catch (ex) {
      console.log(ex);
      history.replace(`/hydrants/${hydrantId}/hydrantExercise`);
    }
  };

  const exerciseDate = new Date(exercise?.exerciseDate ?? null);
  const exerciseDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(exerciseDate);
  
  return (
    <div className="hydrant-exercise">
      <div className="report-title">
        <div className="title">
          <h3>Hydrant Exercise</h3>
          <div className="form-links no-print">
            <div className='link' onClick={toggleModal}>Change Exercise</div>
            <Link to={`/reports/history/${hydrantId}`} target='_blank' className='link'>View Exercise History</Link>
          </div>
        </div>
        {loadingHydrant || loadingExercise || <CircleButton icon={faPrint} size="large" title="Print" onClick={print} className="no-print" />}
      </div>
      {loadingExercise && <div className="spinner"></div>}
      {!loadingExercise && exercise && (
        <>          
          <div className="result-details">
            <div className="result-detail">
              <label>Device Type:</label>
              <span>Hydrant</span>
            </div>
            <div className="result-detail">
              <label>Device ID:</label>
              <span>{hydrant?.name}</span>
            </div>
          </div>
          <div className="hydrant-settings">
            <div className="hydrant-setting">
              <label>Set Rev:</label>
              <span>{exercise?.revolutions}</span>
            </div>
            <div className="hydrant-setting">
              <label>Set Torque:</label>
              <span>{exercise?.torqueSetPoint}</span>
            </div>
            <div className="hydrant-setting">
              <label>Set Speed:</label>
              <span>{exercise?.rpmSetPoint}</span>
            </div>
          </div>
          <div className="hydrant-location">
            <div className="hydrant-location-info">
              <div className="hydrant-location-detail">
                <label>Address:</label>
                <span>{((hydrant?.streetNumber ?? '') + ' ' + (hydrant?.streetName ?? '')).trim()}</span>
              </div>
              <div className="hydrant-location-detail">
                <label>Cross Street:</label>
                <span>{hydrant?.crossStreet}</span>
              </div>
              <div className="hydrant-location-detail">
                <label>Location:</label>
                <span>{hydrant?.locationNotes}</span>
              </div>
              <div className="hydrant-location-detail">
                <label>District:</label>
                <span>{hydrant?.district}</span>
              </div>
              <div className="hydrant-location-detail">
                <label>Sub-Division:</label>
                <span>{hydrant?.subDivision}</span>
              </div>
            </div>
            <div className="hydrant-gps-info">
              <div className="hydrant-gps-detail">
                <label>Latitude:</label>
                <span>{hydrant?.latitude?.toFixed(6)}</span>
              </div>
              <div className="hydrant-gps-detail">
                <label>Longitude:</label>
                <span>{hydrant?.longitude?.toFixed(6)}</span>
              </div>
              <div className="hydrant-gps-detail">
                <label>Elevation:</label>
                <span>{hydrant?.elevation?.toFixed(2)}</span>
              </div>
              <div className="hydrant-gps-detail">
                <label>State X / Y:</label>
                <span>{hydrant?.stateX} / {hydrant?.stateY}</span>
              </div>
            </div>
          </div>
          <div className="hydrant-info">
            <div className="hydrant-info-detail">
              <label>Manufacturer:</label>
              <span>{hydrant?.manufacturer}</span>
            </div>
            <div className="hydrant-info-detail">
              <label>Install Date:</label>
              <span>{hydrant?.installDate ? new Date(hydrant.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="hydrant-info-detail">
              <label>Status:</label>
              <span>{hydrant?.status}</span>
            </div>
            <div className="hydrant-info-detail">
              <label>Hydrant Size:</label>
              <span>{hydrant?.size?.toFixed(2)}</span>
            </div>
            <div className="hydrant-info-detail">
              <label>Open Direction:</label>
              <span>{exercise?.openDirection}</span>
            </div>
            <div className="hydrant-info-detail">
              <label>Bury Depth:</label>
              <span>{hydrant?.boxDepth?.toFixed(2)}</span>
            </div>
          </div>
          {exercise.manual ? (
            <div className="exercise-stats">
              <div className="exercise-stat">
                <label>Exercise Date:</label>
                <span>{exercise?.exerciseDate ? new Date(exercise.exerciseDate).toLocaleDateString('en-US', dateTimeOptions) : ''}</span>
              </div>
              <div className="exercise-stat">
                <label>Difficulty:</label>
                <span>{exercise?.difficulty}</span>
              </div>
              <div className="exercise-stat">
                <label>Comments:</label>
                <span>{exercise?.comments}</span>
              </div>
            </div>
          ) : (
            <div className="exercise-stats">
              <div className="exercise-stat">
                <label>Exercise Date:</label>
                <span>{exercise?.exerciseDate ? new Date(exercise.exerciseDate).toLocaleDateString('en-US', dateTimeOptions) : ''}</span>
              </div>
              <div className="exercise-stat">
                <label>Total Revs:</label>
                <span>{exercise?.totalRevolutions}</span>
              </div>
              <div className="exercise-stat">
                <label>Cycle Count:</label>
                <span>{exercise?.autoCycles}</span>
              </div>
              <div className="exercise-stat">
                <label>Max Torque:</label>
                <span>{exercise?.maxTorque}</span>
              </div>
              <div className="exercise-stat">
                <label>Revs @ Max Torque:</label>
                <span>{exercise?.maxTorqueRevolutions}</span>
              </div>
              <div className="exercise-stat">
                <label>Max Rev In Set Dir:</label>
                <span>{exercise?.maxRevolutions}</span>
              </div>
              <div className="exercise-stat">
                <label>Comments:</label>
                <span>{exercise?.comments}</span>
              </div>
            </div>
          )}
          {!startDelete ? (
            <div className="form-actions no-print">
              <IsAuthorized>
                <Link to={`/hydrants/${hydrantId}/hydrantExercise/${exercise.id}/edit`}>Edit</Link>
                <div className="link" onClick={() => setStartDelete(true)}>Delete</div>
              </IsAuthorized>
            </div>
          ) : (
            <div className="form-actions no-print">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
          {!exercise.manual && <ExerciseChart exercise={exercise} />}
        </>
      )}
      {!loadingExercise && !exercise && (
        <div className="empty-body">
          <div className="empty-text">No Exercise Found</div>
          <div className="empty-text-small">We couldn't find an exercise.</div>
        </div>
      )}
      <HydrantExerciseModal hydrantId={hydrantId} open={modalOpen} toggle={toggleModal} onSelect={selectExercise} hydrantExercise={exercise} />
    </div>
  );
};

export default HydrantExercise;