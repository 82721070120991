import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody
} from 'reactstrap';
import { getValveExercises } from '../../services/api';
import ValveExerciseTableRow from './ValveExerciseTableRow';
import { makeValveExercise } from '../../models';

const ValveExerciseModal = ({ valveId, open, toggle, onSelect, valveExercise }) => {
  const [valveExercises, setValveExercises] = useState([]);

  useEffect(() => {
    setupValveExercise();
  }, [valveId, valveExercise]);

  const setupValveExercise = async () => {
    const result = await getValveExercises(valveId);
    result.sort((a, b) => {
      if (a.exerciseDate < b.exerciseDate) {
        return -1;
      } else if (a.exerciseDate > b.exerciseDate) {
        return 1;
      } else {
        return 0;
      }
    });
    const mappedExercises = result.map(e => makeValveExercise(e));
    setValveExercises(mappedExercises);
  };

  const select = id => {
    toggle();
    onSelect(id);
  };

  const valveExerciseRows = valveExercises?.map(exercise => 
    <ValveExerciseTableRow key={exercise.id} exercise={exercise} onSelect={select} />);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalBody>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Exercise Date</th>
              <th>Max Torque</th>
              <th>Max Torque Revolutions</th>
              <th>Total Turns</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {valveExerciseRows}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default ValveExerciseModal;