import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import hurcoLogo from '../assets/images/HurcoLogo_WhiteBackground.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { getCompany, getCurrentCompany } from '../services/api';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '../utils/useLocalStorage';
import useAuth from '../services/authentication/useAuth';
import Dropdown, { DropdownItem } from './Dropdown';
import { useWindowEvent } from '../utils/useWindowEvent';
import IsAuthorized from '../services/authentication/IsAuthorized';
import EventEmitter from '../services/events';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import BubbleNotification from './BubbleNotification';
import ErrorBoundary from './ErrorBoundary';
import { Route } from 'react-router-dom';

export const Layout = ({ children }) => {
  const [company, setCompany] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [bubbleNotificationOpen, setBubbleNotificationOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    EventEmitter.addListener('show-error', showError);
    EventEmitter.addListener('show-message', showMessage);
    EventEmitter.addListener('show-notification', showNotification);

    return () => {
      EventEmitter.removeListener('show-error', showError);
      EventEmitter.removeListener('show-message', showMessage);
      EventEmitter.removeListener('show-notification', showNotification);
    };
  }, []);

  useEffect(() => {
    setupCompany();
  }, [auth.companyId, auth.user]);

  const setupCompany = async () => {
    if (auth.companyId) {
      const result = await getCurrentCompany();
      setCompany(result);
    }
  };

  const showError = (e) => {
    setError(e);
    setErrorModalOpen(true);
  };

  const showMessage = (m) => {
    setMessage(m);
    setMessageModalOpen(true);
  };

  const showNotification = (n) => {
    setNotification(n);
    setBubbleNotificationOpen(true);
  };

  const logoutUser = async () => {
    await auth.logout();
    history.push('/login');
  };

  const toggleErrorModal = () => {
    setErrorModalOpen(!errorModalOpen);
  };

  const toggleMessageModal = () => {
    setMessageModalOpen(!messageModalOpen);
  };

  const toggleBubbleNotification = () => {
    setBubbleNotificationOpen(!bubbleNotificationOpen);
  };

  return (
    <div id="site">
      <Header>
        {!auth.authed ? (
          <>
            <Link to={'/login'}>Login</Link>
            <Link to={'/register'}>Register</Link>
          </>
        ) : (
          <>
            <div className='nav-link'><Link to={'/home'} className='link'>Map</Link></div>
            <div className='nav-link'><Link to={'/valves'} className='link'>Valves</Link></div>
            <div className='nav-link'><Link to={'/hydrants'} className='link'>Hydrants</Link></div>
            <div className='nav-link'><Link to={'/reports'} className='link'>Reports</Link></div>
            <Dropdown label={auth.user?.email}>
                <DropdownItem><Link to={'/company'} className='link'>{company?.name}</Link><Link to={'/selectCompany'} className='link'>{company ? 'Switch' : 'Select Company'}</Link></DropdownItem>
                <DropdownItem><Link to={'/settings'} className='link'>Settings</Link></DropdownItem>
                <DropdownItem><Link to={'/account'} className='link'>Account</Link></DropdownItem>
                <DropdownItem><div onClick={logoutUser}><a href="#" className='link'>Logout</a></div></DropdownItem>
            </Dropdown>
          </>
        )}
      </Header>
      {children}
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <ModalHeader>
          {error?.title}
        </ModalHeader>
        <ModalBody>
          {error?.body}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={toggleErrorModal}>Okay</button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={messageModalOpen} toggle={toggleMessageModal}>
        <ModalHeader>
          {message?.title}
        </ModalHeader>
        <ModalBody>
          {message?.body}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={toggleMessageModal}>Okay</button>
        </ModalFooter>
      </Modal>
      <BubbleNotification open={bubbleNotificationOpen} toggle={toggleBubbleNotification}>
        {notification}
      </BubbleNotification>
    </div>
  );
};

export const Main = ({ children }) => {
  return (
      <main>
      {children}
    </main>
  );
};

export const Aside = ({ children }) => {
  if (children.length === 0)
    return null;

  const [asideOpen, setAsideOpen] = useState(false);

  const toggleAside = (e) => {
    e?.stopPropagation();
    setAsideOpen(!asideOpen);
  };

  const closeAside = () => {
    setAsideOpen(false);
  };

  const handleClick = (e) => {
    closeAside();
  };
  useWindowEvent('click', handleClick);
  
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: closeAside });
    }
    return child;
  });

  return (
    <aside className={asideOpen ? 'open' : 'closed'}>
      <div id="toggleAside" className="icon-button icon-button-small" title="Show Aside" onClick={toggleAside}>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
      <div className="aside-items">
        {children}
      </div>
    </aside>
  );
};

export const Header = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = (e) => {
    e?.stopPropagation();
    setNavOpen(!navOpen);
  }

  const closeNav = () => {
    setNavOpen(false);
  }

  const openNav = () => {
    setNavOpen(true);
  }

  const handleClick = (e) => {
    closeNav();
  };
  useWindowEvent('click', handleClick);
  
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: closeNav });
    }
    return child;
  });

  return (
    <header>
        <Link id="logo" to="/">
          <img src={hurcoLogo} alt="Hurco" />
        </Link>
        <div id="toggleNav" className="icon-button" onClick={toggleNav}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <nav className={navOpen ? 'open' : 'closed'}>
          <div className="nav-links">
            {children}
          </div>
        </nav>
    </header>
  );
};

export const Page = ({children}) => {
  return (
    <div className="page">
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </div>
  )
}

export const ErrorBoundaryRoute = ({ children, ...props }) => {
  return (
    <ErrorBoundary path={props.path}>
      <Route {...props} >
        {children}
      </Route>
    </ErrorBoundary>
  )
};