import React, { Component, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Layout, Main, Aside, ErrorBoundaryRoute } from './components/Layout';
import { Home, Valves, Valve, ValveAside, AddValve } from './pages';
import { Hydrant, HydrantAside, AddHydrant, Hydrants } from './pages/hydrants';
import './custom.css'
import PrivacyPolicy from './pages/PrivacyPolicy';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Users from './pages/Users';
import Companies from './pages/Companies';
import RequireAuth from './services/authentication/RequireAuth';
import { AuthProvider } from "./services/authentication/useAuth";
import SelectCompany from './pages/SelectCompany';
import Role from './services/authentication/Roles';
import EditUserRoles from './pages/EditUserRoles';
import AddUserRoles from './pages/AddUserRoles';
import Register from './pages/Register';
import Company from './pages/Company';
import Settings from './pages/Settings';
import AddCompany from './pages/AddCompany';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import EditCompany from './pages/EditCompany';
import Account from './pages/Account';
import ConfirmEmail from './pages/ConfirmEmail';
import ValveSummaryReport from './pages/valveSummaryReport';
import ExerciseSummaryReport from './pages/reports/exerciseSummaryReport';
import ExerciseResultsReport from './pages/reports/exerciseResultsReport';
import ValveResultsReport from './pages/valveResultsReport';
import ValveHistoryReport from './pages/valveHistoryReport';
import Reports from './pages/Reports';
import DefaultValveExerciseSettings from './pages/DefaultValveExerciseSettings';
import EditDefaultValveExerciseSettings from './pages/EditDefaultValveExerciseSettings';
import AddDefaultValveExerciseSettings from './pages/AddDefaultValveExerciseSettings';
import DetailHydrantModel from './pages/hydrants/DetailHydrantModel';
import DefaultHydrantExerciseSettings from './pages/hydrants/DefaultHydrantExerciseSettings';
import EditDefaultHydrantExerciseSettings from './pages/hydrants/EditDefaultHydrantExerciseSettings';
import AddDefaultHydrantExerciseSettings from './pages/hydrants/AddDefaultHydrantExerciseSettings';

const App = () => {
    const [valveUpdate, setValveUpdate] = useState(0);
    const [hydrantUpdate, setHydrantUpdate] = useState(0);
    const handleValveUpdate = () => {
        setValveUpdate(valveUpdate + 1);
    };
    const handleHydrantUpdate = () => {
        setHydrantUpdate(hydrantUpdate + 1);
    };
    return (
        <AuthProvider>
            <Switch>
                <ErrorBoundaryRoute path='/'>
                    <Layout>
                        <div className="body">
                            <Switch>
                                <ErrorBoundaryRoute exact path='/valves/add' />
                                <ErrorBoundaryRoute exact path='/valves/import' />
                                <ErrorBoundaryRoute path='/valves/:valveId'>
                                    <RequireAuth>
                                        <ValveAside/>
                                    </RequireAuth>
                                </ErrorBoundaryRoute>
                                <ErrorBoundaryRoute exact path='/hydrants/add' />
                                <ErrorBoundaryRoute exact path='/hydrants/import' />
                                <ErrorBoundaryRoute path='/hydrants/:hydrantId'>
                                    <RequireAuth>
                                        <HydrantAside/>
                                    </RequireAuth>
                                </ErrorBoundaryRoute>
                            </Switch>
                            <Main>
                                {/* Simply hiding Valves screen based on location
                                    so the map doesn't reload when going back to it */}
                                <RequireAuth redirect={false}>
                                    <Home />
                                </RequireAuth>
                                <Switch>
                                    <ErrorBoundaryRoute exact path='/privacyPolicy' component={PrivacyPolicy} />
                                    <ErrorBoundaryRoute exact path='/register' component={Register} />
                                    <ErrorBoundaryRoute exact path='/login' component={Login} />
                                    <ErrorBoundaryRoute exact path='/account'>
                                        <RequireAuth requireCompany={false}>
                                            <Account />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/settings'>
                                        <RequireAuth requireCompany={true}>
                                            <Settings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/account/confirmEmail' component={ConfirmEmail} />
                                    <ErrorBoundaryRoute exact path='/account/forgotPassword' component={ForgotPassword} />
                                    <ErrorBoundaryRoute exact path='/account/resetPassword' component={ResetPassword} />
                                    <ErrorBoundaryRoute exact path='/companies'>
                                        <RequireAuth requireCompany={false}>
                                            <Companies/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/createCompany'>
                                        <RequireAuth requireCompany={false}>
                                            <AddCompany />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/company'>
                                        <RequireAuth requireCompanySubscribed={false}>
                                            <Company />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/company/edit'>
                                        <RequireAuth roles={[Role.Admin]} requireCompanySubscribed={false}>
                                            <EditCompany />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/selectCompany'>
                                        <RequireAuth requireCompany={false}>
                                            <SelectCompany/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/users'>
                                        <RequireAuth roles={[Role.Admin]} requireCompanySubscribed={false}>
                                            <Users/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/users/:userId/editRoles'>
                                        <RequireAuth roles={[Role.Admin]} requireCompanySubscribed={false}>
                                            <EditUserRoles/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/users/addUserRoles'>
                                        <RequireAuth roles={[Role.Admin]} requireCompanySubscribed={false}>
                                            <AddUserRoles/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/valves'>
                                        <RequireAuth>
                                            <Valves/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/valves/add'>
                                        <RequireAuth>
                                            <AddValve onAdd={handleValveUpdate}/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/valves/import'>
                                        <RequireAuth>
                                            <AddValve onAdd={handleValveUpdate}/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute path='/valves/:valveId'>
                                        <RequireAuth>
                                            <Valve onUpdate={handleValveUpdate} onDelete={handleValveUpdate}/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/hydrants'>
                                        <RequireAuth>
                                            <Hydrants/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/hydrants/add'>
                                        <RequireAuth>
                                            <AddHydrant onAdd={handleHydrantUpdate}/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute path='/hydrants/:hydrantId'>
                                        <RequireAuth>
                                            <Hydrant onUpdate={handleHydrantUpdate} onDelete={handleHydrantUpdate}/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/hydrantModels/:hydrantModelId'>
                                        <RequireAuth>
                                            <DetailHydrantModel/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/reports'>
                                        <RequireAuth>
                                            <Reports/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/reports/summary'>
                                        <RequireAuth>
                                            <ExerciseSummaryReport/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/reports/results'>
                                        <RequireAuth>
                                            <ExerciseResultsReport/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/reports/history/:valveId'>
                                        <RequireAuth>
                                            <ValveHistoryReport/>
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultValveExerciseSettings'>
                                        <RequireAuth>
                                            <DefaultValveExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultValveExerciseSettings/:defaultValveExerciseSettingsId/edit'>
                                        <RequireAuth>
                                            <EditDefaultValveExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultValveExerciseSettings/add'>
                                        <RequireAuth>
                                            <AddDefaultValveExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultHydrantExerciseSettings'>
                                        <RequireAuth>
                                            <DefaultHydrantExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultHydrantExerciseSettings/:defaultHydrantExerciseSettingsId/edit'>
                                        <RequireAuth>
                                            <EditDefaultHydrantExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <ErrorBoundaryRoute exact path='/defaultHydrantExerciseSettings/add'>
                                        <RequireAuth>
                                            <AddDefaultHydrantExerciseSettings />
                                        </RequireAuth>
                                    </ErrorBoundaryRoute>
                                    <Route path='/'>
                                        <RequireAuth>
                                        </RequireAuth>
                                    </Route>
                                </Switch>
                            </Main>
                        </div>
                    </Layout>
                </ErrorBoundaryRoute>
            </Switch>
        </AuthProvider>
    );
};

export default App;