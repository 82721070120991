import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, useRef } from 'react';
import CircleButton from './CircleButton';

const Search = ({ onSearch, searchText: inSearchText, setSearchText: inSetSearchText }) => {
  const [searchText, setSearchText] = inSetSearchText ? [inSearchText, inSetSearchText] : useState(inSearchText || '');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const searchTextRef = useRef(searchText);
  const inputRef = useRef();

  useEffect(() => {
    if (searchTimeout == null && onSearch && !initialLoad) {
      setSearchTimeout(setTimeout(() => {
        onSearch(searchTextRef.current);
        setSearchTimeout(null);
      }, 500));
    }
    setInitialLoad(false);
  }, [searchText]);

  const updateSearch = e => {
    searchTextRef.current = e.target.value;
    setSearchText(searchTextRef.current);
  }

  const clear = () => {
    searchTextRef.current = '';
    setSearchText(searchTextRef.current);
    inputRef.current.focus();
  }

  return (
    <div className="search">
      <input ref={inputRef} type="text" placeholder="Search" value={searchText} onChange={updateSearch} />
      <CircleButton icon={faTimes} title="Clear" onClick={clear} />
    </div>
  )
};

export default Search;