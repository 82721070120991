import { jwtDecode } from 'jwt-decode';

export const parseJWT = (token) => {
  try {
    const _token = token || localStorage.getItem('access_token');
    if (!_token) {
      return null;
    }

    const _tokenDecoded = jwtDecode(_token);
    if (_tokenDecoded.CompanyRole) {
      if (typeof _tokenDecoded.CompanyRole === 'string') {
        _tokenDecoded.roles = [JSON.parse(_tokenDecoded.CompanyRole)]
      } else {
        _tokenDecoded.roles = _tokenDecoded.CompanyRole.map(JSON.parse);
      }
    } else {
      _tokenDecoded.roles = [];
    }

    if (_tokenDecoded.CompanySubscription) {
      if (typeof _tokenDecoded.CompanySubscription === 'string') {
        _tokenDecoded.companies = [JSON.parse(_tokenDecoded.CompanySubscription)]
      } else {
        _tokenDecoded.companies = _tokenDecoded.CompanySubscription.map(JSON.parse);
      }
    } else {
      _tokenDecoded.companies = [];
    }
    
    return _tokenDecoded;
  } catch (ex) {
    console.log(ex);
    return null;
  }
}