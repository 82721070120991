import React from 'react';
import { Link } from 'react-router-dom';
import LabelledTextField from '../LabelledTextField';
import { dateOptions } from '../../utils/dates';
import CircleButton from '../CircleButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const HydrantTableRow = ({ hydrant, selected, toggleSelected }) => {  
  const onChange = e => {
    toggleSelected(e.target.checked);
  };

  return (
    <tr id={hydrant.id}>
      <td><input type="checkbox" checked={selected} onChange={onChange} /></td>
      <td>{hydrant.name}</td>
      <td>{hydrant.streetNumber}</td>
      <td>{hydrant.streetName}</td>
      <td>{hydrant.city}</td>
      <td>{hydrant.state}</td>
      <td>{hydrant.lastMaintenanceDate ? new Date(hydrant.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''}</td>
      <td className='actions'>
        <Link to={`/hydrants/${hydrant.id}`}>
          <CircleButton icon={faEye} type="primary" title="Details" />
        </Link>
      </td>
    </tr>
  )
};

export default HydrantTableRow;