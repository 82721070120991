export const validate = (object, schema) => {
  const errors = [];
  for (const key in schema) {
    const keyErrors = schema[key].validators
      .filter(v => !v.validate(object[key]))
      .map(v => {
        const error = new Error(v.message);
        error.name = schema[key].prettyName ?? key;
        return error;
      });
    errors.push.apply(errors, keyErrors);
  }
  return errors;
};

export const validators = Object.freeze({
  isString: () => {
    return {
      validate: value => !value || typeof value === 'string' || value instanceof String,
      message: 'Must be a string.',
    };
  },
  isInteger: () => {
    return {
      validate: value => !value || (!isNaN(value) && parseInt(value, 10) === Number(value)),
      message: 'Must be an integer.',
    };
  },
  isNumber: () => {
    return {
      validate: value => !value || !isNaN(value),
      message: 'Must be a number.',
    };
  },
  isDate: () => {
    return {
      validate: value => !value || (Object.prototype.toString.call(value) === '[object Date]' && value.toString !== 'Invalid Date'),
      message: 'Must be a date.',
    };
  },
  isBoolean: () => {
    return {
      validate: value => !value || typeof value === 'boolean',
      message: 'Must be a boolean.',
    };
  },
  isArray: () => {
    return {
      validate: value => !value || Array.isArray(value),
      message: 'Must be an array.',
    };
  },
  notNull: () => {
    return {
      validate: value => value !== null && value !== undefined,
      message: 'Cannot be NULL.',
    };
  },
  notEmpty: () => {
    return {
      validate: value => value?.length > 0,
      message: 'Cannot be empty.',
    };
  },
  within: list => {
    return {
      validate: value => !value || list.includes(value),
      message: `Must be within: ${list.join(', ')}.`,
    };
  },
  greaterThan: min => {
    return {
      validate: value => !value || value > min,
      message: `Must be greater than ${min}.`,
    };
  },
  greaterThanOrEqualTo: min => {
    return {
      validate: value => !value || value >= min,
      message: `Must be greater than or equal to ${min}.`,
    };
  },
  lessThan: max => {
    return {
      validate: value => !value || value < max,
      message: `Must be less than ${max}.`,
    };
  },
  lessThanOrEqualTo: max => {
    return {
      validate: value => !value || value <= max,
      message: `Must be less than or equal to ${max}.`,
    };
  },
  exactLength: len => {
    return {
      validate: value => !value || value.length === len,
      message: `Length must be equal to ${len}.`,
    };
  },
  maxLength: max => {
    return {
      validate: value => !value || value.length <= max,
      message: `Length cannot be greater than ${max}.`,
    };
  },
  minLength: min => {
    return {
      validate: value => !value || value.length >= min,
      message: `Length must be at least ${min}.`,
    };
  },
  isDivisibleBy: mod => {
    return {
      validate: value => !value || value % mod === 0,
      message: `Must be divisible by ${mod}.`,
    };
  },
});