import useAuth from './useAuth';
import Role from './Roles';

const IsAuthorized = ({ children, roles = [Role.Admin], requireCompany = true }) => {
  const { authed, companyId, user } = useAuth();

  let result = children;
  const rolesAccepted = user?.roles?.some(ur => roles.includes(ur.RoleName) && ur.CompanyId === companyId) ?? false;
  if (!authed || (requireCompany && (!companyId || !rolesAccepted))) {
    result = null
  }

  return result;
};

export default IsAuthorized;