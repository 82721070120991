import { useState, useEffect } from 'react';
import { useWindowEvent } from './useWindowEvent';

const useLocalStorage = (key, defaultValue) => {
  // initialize the value from localStorage
  if (!localStorage.getItem(key) && defaultValue) {
    localStorage.setItem(key, defaultValue);
  }
  const [currentValue, setCurrentValueState] = useState(() =>
    localStorage.getItem(key)
  );
  

  const handler = (e) => {
    if (
      e.storageArea === localStorage &&
      e.key === key &&
      e.newValue !== currentValue
    ) {
      setCurrentValueState(e.newValue);
    }
  };

  const setCurrentValue = (value) => {
      localStorage.setItem(key, value);
      const storageEvent = new StorageEvent('storage', {
        key: key,
        oldValue: currentValue,
        newValue: value,
        url: '/',
        storageArea: localStorage,
        bubbles: true,
        cancelable: true,
      });
      window.dispatchEvent(storageEvent);
  }

  const removeCurrentValue = () => {
      localStorage.removeItem(key);
      const storageEvent = new StorageEvent('storage', {
        key: key,
        oldValue: currentValue,
        newValue: value,
        url: '/',
        storageArea: localStorage,
        bubbles: true,
        cancelable: true,
      });
      window.dispatchEvent(storageEvent);
  }

  // set up the event listener
  useWindowEvent('storage', handler);

  // use as const to tell TypeScript this is a tuple
  return [currentValue, setCurrentValue, removeCurrentValue];
};

export default useLocalStorage;