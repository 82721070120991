import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const CircleButton = ({ icon, img, title, onClick, size, type, className }) => {
  return (
    <div className={`circle-button ${size || ''} circle-button-${type} ${className || ''}`} onClick={onClick} title={title}>
      {icon && <FontAwesomeIcon icon={icon || faQuestion}/>}
      {img && <img src={img} alt={title} />}
    </div>
  );
};

export default CircleButton;