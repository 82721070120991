import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getValveExercises = async (valveId) => {
  const result = await tryGet(`/valveExercises?valveId=${valveId || ''}`);
  return result.data;
};

export const getValveExercise = async (valveExerciseId) => {
  const result = await tryGet(`/valveExercises/${valveExerciseId}`);
  return result.data;
};

export const getValveExerciseLatest = async (valveId) => {
  const result = await tryGet(`/valveExercises/latest/${valveId}`);
  return result.data;
};

export const getValveExerciseEdit = async (valveExerciseId) => {
  const result = await tryGet(`/valveExercises/${valveExerciseId}/edit`);
  return result.data;
};

export const updateValveExercise = async (valveExercise) => {
  const result = await tryPut(`/valveExercises`, valveExercise);
  return result.data;
};

export const addValveExercise = async (valveExercise) => {
  const result = await tryPost(`/valveExercises`, valveExercise);
  return result.data;
};

export const deleteValveExercise = async (valveExerciseId) => {
  const result = await tryDelete(`/valveExercises/${valveExerciseId}`);
  return result.data;
};

export const exportAllValveExerciseResults = async () => {
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/valveExercises/exportAllResults?timeZoneName=${timeZoneName}`);
  return result.data;
};

export const exportValveExerciseResults = async (valveIds) => {
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/valveExercises/exportResults?timeZoneName=${timeZoneName}`, valveIds);
  return result.data;
};