import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrantExerciseSettings = async (searchText) => {
  const result = await tryGet(`/hydrantExerciseSettings?search=${searchText || ''}`);
  return result.data;
};

export const getHydrantExerciseSetting = async (hydrantExerciseSettingId) => {
  const result = await tryGet(`/hydrantExerciseSettings/${hydrantExerciseSettingId}`);
  return result.data;
};

export const getHydrantExerciseSettingEdit = async (hydrantExerciseSettingId) => {
  const result = await tryGet(`/hydrantExerciseSettings/${hydrantExerciseSettingId}/edit`);
  return result.data;
};

export const updateHydrantExerciseSetting = async (hydrantExerciseSetting) => {
  const result = await tryPut(`/hydrantExerciseSettings`, hydrantExerciseSetting);
  return result.data;
};

export const addHydrantExerciseSetting = async (hydrantExerciseSetting) => {
  const result = await tryPost(`/hydrantExerciseSettings`, hydrantExerciseSetting);
  return result.data;
};

export const deleteHydrantExerciseSetting = async (hydrantExerciseSettingId) => {
  const result = await tryDelete(`/hydrantExerciseSettings/${hydrantExerciseSettingId}`);
  return result.data;
};

export const importHydrantExerciseSettings = async (file) => {
  var data = new FormData();
  data.append('file', file);
  const result = await tryPost('/hydrantExerciseSettings/import', data);
  return result.data;
};

export const exportHydrantExerciseSettings = async () => {
  const result = await tryGet('/hydrantExerciseSettings/export');
  return result.data;
};