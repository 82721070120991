import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../components/LabelledTextBox';
import { dateOptions } from '../utils/dates';
import LabelledPicker from '../components/LabelledPicker';
import { getDefaultValveExerciseSettingsEdit, updateDefaultValveExerciseSettings } from '../services/api';
import { makeDefaultValveExerciseSettings } from '../models';
import EventEmitter from '../services/events';

const EditDefaultValveExerciseSettings = () => {
  const [defaultSettings, setDefaultSettings] = useState(null);
  const [saving, setSaving] = useState(false);
  const { path, url } = useRouteMatch();
  const { defaultValveExerciseSettingsId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExerciseSettings();
  }, [defaultValveExerciseSettingsId, history.location]);

  const setupExerciseSettings = async () => {
    const result = await getDefaultValveExerciseSettingsEdit(defaultValveExerciseSettingsId);
    setDefaultSettings(makeDefaultValveExerciseSettings(result));
  };

  const save = async () => {
    try {
      setSaving(true);
      defaultSettings.validate();
      const result = await updateDefaultValveExerciseSettings(defaultSettings);
      history.replace('/defaultValveExerciseSettings');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Default Valve Exercise Settings', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Default Valve Exercise Settings', body: 'Unable to save default valve exercise settings.' });
      }
    } finally {
      setSaving(false);
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }
    setDefaultSettings(makeDefaultValveExerciseSettings({ ...defaultSettings, [name]: value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }

    switch (type) {
      case "number":
        value = +value;
        break;
    }

    setDefaultSettings(makeDefaultValveExerciseSettings({ ...defaultSettings, [name]: value }));
  };

  const years = Math.floor(defaultSettings?.exerciseFrequencyMonths / 12);
  const months = defaultSettings?.exerciseFrequencyMonths % 12;
  
  return (
    <div>
      <h3>Edit Default Valve Exercise Settings</h3>
      {saving && <div className="spinner"></div>}
      {defaultSettings ? (
        <>
          <div className="details">
            <LabelledTextBox type="number" label="Valve Size" value={defaultSettings.valveSize} name="valveSize" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Torque Set Point" value={defaultSettings.torqueSetPoint} name="torqueSetPoint" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="RPM Set Point" value={defaultSettings.rpmSetPoint} name="rpmSetPoint" onChange={onChange} onBlur={onBlur}/>
            <LabelledPicker label="Open Direction" value={defaultSettings.openDirection} name="openDirection" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </LabelledPicker>
            <LabelledPicker label="Current State" value={defaultSettings.currentState} name="currentState" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </LabelledPicker>
            <LabelledTextBox type="number" label="Revolutions" value={defaultSettings.revolutions} name="revolutions" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Stuck Revs" value={defaultSettings.stuckRevs} name="stuckRevs" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Stuck Attempts" value={defaultSettings.stuckAttempts} name="stuckAttempts" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Open Recess" value={defaultSettings.openRecess} name="openRecess" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Close Recess" value={defaultSettings.closeRecess} name="closeRecess" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Flush Revolutions" value={defaultSettings.flushRevolutions} name="flushRevolutions" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Flush Cycles" value={defaultSettings.flushCycles} name="flushCycles" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Hardstop Speed" value={defaultSettings.hardstopSpeed} name="hardstopSpeed" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Auto Cycles" value={defaultSettings.autoCycles} name="autoCycles" onChange={onChange} onBlur={onBlur}/>
            <div className="labelled-text-box">
              <span className="label">Valve Exercise Frequency</span>
              <div className="input">
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Years</span>
                  <input type="number" value={years?.toString() ?? ''}
                    name="exerciseFrequencyYears"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Valve Exercise Frequency (Years)"
                  />
                </div>
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Months</span>
                  <input type="number" value={months?.toString() ?? ''}
                    name="exerciseFrequencyMonths"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Valve Exercise Frequency (Months)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-secondary" onClick={cancel} disabled={saving}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save} disabled={saving}>Save</button>
          </div>
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditDefaultValveExerciseSettings;
