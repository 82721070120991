export const round = (number, percision) => {
  return Math.round(number / percision) * percision;
};

export const roundUp = (number, percision) => {
  return Math.ceil(number / percision) * percision;
};

export const roundDown = (number, percision) => {
  return Math.floor(number / percision) * percision;
};

export const roundDecimals = (number, decimals) => {
  const pow = Math.pow(10, decimals);
  return Math.round((number + Number.EPSILON) * pow) / pow;
};