import React from 'react';

const LabelledTextBox = ({ label, type, value, name, onChange, onBlur }) => {

  const onTextChange = (e) => {
    onChange({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
    });
  };

  const onTextBlur = (e) => {
    onBlur({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
    });
  };

  return (
    <div className="labelled-text-box">
      <span className="label">{label}</span>
      <input type={type ? type : 'text'} value={value?.toString() ?? ''}
        name={name}
        onChange={onTextChange}
        onBlur={onTextBlur}
        placeholder={label}
      />
    </div>
  );
};

export default LabelledTextBox;
