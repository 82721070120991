import React, { useState } from 'react';
import useAuth from '../services/authentication/useAuth';
import LabelledTextField from '../components/LabelledTextField';
import LabelledTextBox from '../components/LabelledTextBox';
import { forgotPassword } from '../services/api';
import EventEmitter from '../services/events';

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const onChange = e => {
    setEmail(e.target.value);
  };

  const onBlur = e => {
    setEmail(e.target.value);
  };

  const changePassword = async () => {
    try {
      setPasswordError(null);
      setChangingPassword(true);
      if (credentials.password !== credentials.confirmPassword) {
        setPasswordError("Password and Confirm Password don't match");
        return;
      }
      if (credentials.password.length < 6) {
        setPasswordError("Password must be at least 6 characters long");
        return;
      }
      if (!/[a-z]/.test(credentials.password)) {
        setPasswordError('Password must contain a lower case letter');
        return;
      }
      if (!/[A-Z]/.test(credentials.password)) {
        setPasswordError('Password must contain an upper case letter');
        return;
      }
      if (!/[0-9]/.test(credentials.password)) {
        setPasswordError('Password must contain a number');
        return;
      }
      if (!/[!@#$%^&*]/.test(credentials.password)) {
        setPasswordError('Password must contain a symbol: ! @ # $ % ^ & *');
        return;
      }

      await updatePassword({ password: credentials.password });
      setCredentials({});
      
      EventEmitter.emit('show-notification', 'Password Changed Successfully');

    } catch (ex) {
      setPasswordError('Unable to update password');
    } finally {
      setChangingPassword(false);
    }
  };

  const sendEmail = async () => {
    try {
      setSendingEmail(true);
      await forgotPassword(email);
      setEmailSent(true);
    } catch (ex) {
      console.log(ex);
    } finally {
      setSendingEmail(false);
    }
  }

  return (
    <div>
      <h2>Forgot Password</h2>
      <div className="user-details">
        {emailSent && <div>An email has been sent to reset your password.</div>}
        <LabelledTextBox type="email" label="Email" value={email} name="email" onChange={onChange} onBlur={onBlur} />
      </div>
      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={sendEmail} disabled={sendingEmail}>{emailSent ? 'Resend Password Reset' : 'Send Password Reset'}</button>
      </div>
      {sendingEmail && <div className="spinner"></div>}
    </div>
  );
};

export default ForgotPassword;