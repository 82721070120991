import React from 'react';
import { Link } from 'react-router-dom';
import LabelledTextField from '../LabelledTextField';
import { dateOptions } from '../../utils/dates';
import CircleButton from '../CircleButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const ValveTableRow = ({ valve, selected, toggleSelected }) => {  
  const onChange = e => {
    toggleSelected(e.target.checked);
  };

  return (
    <tr id={valve.id}>
      <td><input type="checkbox" checked={selected} onChange={onChange} /></td>
      <td>{valve.name}</td>
      <td>{valve.streetNumber}</td>
      <td>{valve.streetName}</td>
      <td>{valve.city}</td>
      <td>{valve.state}</td>
      <td>{valve.lastMaintenanceDate ? new Date(valve.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''}</td>
      <td>{valve.maxTorque}</td>
      <td>{valve.maxTorqueRevolutions}</td>
      <td className='actions'>
        <Link to={`/valves/${valve.id}`}>
          <CircleButton icon={faEye} type="primary" title="Details" />
        </Link>
      </td>
    </tr>
  )
};

export default ValveTableRow;