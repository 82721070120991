import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrantExercises = async (hydrantId) => {
  const result = await tryGet(`/hydrantExercises${hydrantId ? `/byHydrantId/${hydrantId}` : ''}`);
  console.log('Hydrant Exercises', hydrantId, result);
  return result.data;
};

export const getHydrantExercise = async (hydrantExerciseId) => {
  const result = await tryGet(`/hydrantExercises/${hydrantExerciseId}`);
  return result.data;
};

export const getHydrantExerciseLatest = async (hydrantId) => {
  const result = await tryGet(`/hydrantExercises/latest/${hydrantId}`);
  return result.data;
};

export const getHydrantExerciseEdit = async (hydrantExerciseId) => {
  const result = await tryGet(`/hydrantExercises/${hydrantExerciseId}/edit`);
  return result.data;
};

export const updateHydrantExercise = async (hydrantExercise) => {
  const result = await tryPut(`/hydrantExercises`, hydrantExercise);
  return result.data;
};

export const addHydrantExercise = async (hydrantExercise) => {
  const result = await tryPost(`/hydrantExercises`, hydrantExercise);
  return result.data;
};

export const deleteHydrantExercise = async (hydrantExerciseId) => {
  const result = await tryDelete(`/hydrantExercises/${hydrantExerciseId}`);
  return result.data;
}