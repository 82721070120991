import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import LabelledTextBox from '../../components/LabelledTextBox';
import LabelledPicker from '../../components/LabelledPicker';
import { getHydrantModel, updateHydrantModel, deleteHydrantModel, getHydrantImage } from '../../services/api';
import useAuth from '../../services/authentication/useAuth';
import EventEmitter from '../../services/events';
import { makeHydrantModel } from '../../models';
import LabelledTextField from '../../components/LabelledTextField';
import { dateTimeOptions } from '../../utils/dates';

const HydrantModelModal = ({ open, toggle, onClose, onDelete, hydrantModelId, mode }) => {
  const { companyId } = useAuth();
  const [hydrantModel, setHydrantModel] = useState(null);
  const [hydrantImage, setHydrantImage] = useState(null);
  const [viewEditMode, setViewEditMode] = useState(mode || 'view');
  const [startDelete, setStartDelete] = useState(false);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    setupHydrantModel();
  }, [open, hydrantModelId]);

  useEffect(() => {
    setupHydrantImage();
  }, [hydrantModel])

  const setupHydrantModel = async () => {
    const result = await getHydrantModel(hydrantModelId);
    setHydrantModel(makeHydrantModel(result));
  };

  const setupHydrantImage = async () => {
    if (!hydrantModel || !hydrantModel.hydrantImageId) {
      setHydrantImage(null);
      return;
    }

    const result = await getHydrantImage(hydrantModel.hydrantImageId);
    setHydrantImage(makeHydrantImage(result));
  };

  const close = () => {
    onClose();
  };

  const edit = () => {
    setViewEditMode('edit');
  };

  const cancel = async () => {
    await setupHydrantModel();
    setViewEditMode('view');
  };

  const save = async () => {
    try {
      hydrantModel.validate();
      const result = await updateHydrantModel(hydrantModel);
      setViewEditMode('view');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Model', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Model', body: 'Unable to save hydrant model.' });
      }
    }
  };

  const deleteRecord = async () => {
    await deleteHydrantModel(hydrantModel.id);
    onDelete();
  };

  const onChange = e => {
    setHydrantModel(makeHydrantModel({ ...hydrantModel, [e.target.name]: e.target.value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value);
      //   break;
    }

    setHydrantModel(makeHydrantModel({ ...hydrantModel, [e.target.name]: value }));
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        <div>Hydrant Model</div>
      </ModalHeader>
      <ModalBody>
        {hydrantModel ? (
          <>
            {viewEditMode === 'view' &&
              <div className="details">
                <LabelledTextField label="Model ID" text={hydrantModel.modelId} />
                <LabelledTextField label="Description" text={hydrantModel.description} />
                <LabelledTextField label="Open Direction" text={hydrantModel.openDirection} />
                <LabelledTextField label="Valve Turns" text={hydrantModel.valveTurns} />
                <LabelledTextField label="Valve Opening Size" text={hydrantModel.valveOpeningSize} />
                <LabelledTextField label="Barrel Type" text={hydrantModel.barrelType} />
                <LabelledTextField label="Pumper Count" text={hydrantModel.pumperCount} />
                <LabelledTextField label="Pumper Diameter" text={hydrantModel.pumperDiameter} />
                <LabelledTextField label="Pumper Coefficient" text={hydrantModel.pumperCoefficient} />
                <LabelledTextField label="Pumper Thread Diameter" text={hydrantModel.pumperThreadDiameter} />
                <LabelledTextField label="Pumper Threads Per Inch" text={hydrantModel.pumperThreadsPerInch} />
                <LabelledTextField label="Nozzle Count" text={hydrantModel.nozzleCount} />
                <LabelledTextField label="Nozzle Diameter" text={hydrantModel.nozzleDiameter} />
                <LabelledTextField label="Nozzle Coefficient" text={hydrantModel.nozzleCoefficient} />
                <LabelledTextField label="Nozzle Thread Diameter" text={hydrantModel.nozzleThreadDiameter} />
                <LabelledTextField label="Nozzle Threads Per Inch" text={hydrantModel.nozzleThreadsPerInch} />
                <LabelledTextField label="Created By:" text={hydrantModel.createdBy} />
                <LabelledTextField label="Created Date:" text={hydrantModel.createdDate ? new Date(hydrantModel.createdDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
                <LabelledTextField label="Modified By:" text={hydrantModel.modifiedBy} />
                <LabelledTextField label="Modified Date:" text={hydrantModel.modifiedDate ? new Date(hydrantModel.modifiedDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
              </div>
            }
            {viewEditMode === 'edit' &&
              <div className='details'>
                <LabelledTextBox label="Model ID" value={hydrantModel.modelId} name="modelId" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Description" value={hydrantModel.description} name="description" onChange={onChange} onBlur={onBlur} />
                <LabelledPicker label="Open Direction" value={hydrantModel.openDirection} name="openDirection" onChange={onChange} onBlur={onBlur}>
                  <option value=""></option>
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                </LabelledPicker>
                <LabelledTextBox type="number" label="Valve Turns" value={hydrantModel.valveTurns} name="valveTurns" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Valve Opening Size" value={hydrantModel.valveOpeningSize} name="valveOpeningSize" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox label="Barrel Type" value={hydrantModel.barrelType} name="barrelType" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Pumper Count" value={hydrantModel.pumperCount} name="pumperCount" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Pumper Diameter" value={hydrantModel.pumperDiameter} name="pumperDiameter" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Pumper Coefficient" value={hydrantModel.pumperCoefficient} name="pumperCoefficient" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Pumper Thread Diameter" value={hydrantModel.pumperThreadDiameter} name="pumperThreadDiameter" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Pumper Threads Per Inch" value={hydrantModel.pumperThreadsPerInch} name="pumperThreadsPerInch" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Nozzle Count" value={hydrantModel.nozzleCount} name="nozzleCount" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Nozzle Diameter" value={hydrantModel.nozzleDiameter} name="nozzleDiameter" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Nozzle Coefficient" value={hydrantModel.nozzleCoefficient} name="nozzleCoefficient" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Nozzle Thread Diameter" value={hydrantModel.nozzleThreadDiameter} name="nozzleThreadDiameter" onChange={onChange} onBlur={onBlur} />
                <LabelledTextBox type="number" label="Nozzle Threads Per Inch" value={hydrantModel.nozzleThreadsPerInch} name="nozzleThreadsPerInch" onChange={onChange} onBlur={onBlur} />
              </div>
            }
          </>
        ) : (
          <span className="spinner"></span>
        )}
      </ModalBody>
      <ModalFooter>
        {viewEditMode === 'view' &&
          <div className="actions">
            {startDelete ? (
              <>
                <span>Are you sure you want to delete this record?</span>
                <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
                <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
              </>
            ) : (
              <>
                <button type="button" className="btn btn-secondary" onClick={close}>Close</button>
                {/* <button type="button" className="btn btn-danger" onClick={() => setStartDelete(true)}>Delete</button> */}
                <button type="button" className="btn btn-primary" onClick={edit}>Edit</button>
              </>
            )}
          </div>
        }
        {viewEditMode === 'edit' &&
          <div className="actions">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
        }
      </ModalFooter>
    </Modal>
  );
};

export default HydrantModelModal;