import React from 'react';

const LabelledPicker = ({ label, type, value, name, onChange, onBlur, children }) => {

  const onPickerChange = (e) => {
    onChange({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
    });
  };

  const onPickerBlur = (e) => {
    onBlur({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
    });
  };

  return (
    <div className="labelled-picker">
      <span className="label">{label}</span>
      <select type={type ? type : 'text'} value={value?.toString() ?? ''}
        name={name}
        onChange={onPickerChange}
        onBlur={onPickerBlur}
        placeholder={label}>
        {children}
      </select>
    </div>
  );
};

export default LabelledPicker;
