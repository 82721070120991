import React, { useState, useEffect, useRef } from 'react';
import HydrantMap from '../../components/hydrantMap/HydrantMap';
import HydrantList from '../../components/hydrantList/HydrantList';
import { useLocation } from 'react-router-dom';
import { getHydrants, importHydrants, exportHydrants, deleteHydrants } from '../../services/api';
import useLocalStorage from '../../utils/useLocalStorage';
import Upload from '../../components/Upload';
import Download from '../../components/Download';
import FileDownload from 'js-file-download';
import useAuth from '../../services/authentication/useAuth';

const Hydrants = ({ navigator }) => {
  const [mode, setMode] = useLocalStorage('hydrants_mode', 'map');
  const [loadingHydrants, setLoadingHydrants] = useState(false);
  const [hydrants, setHydrants] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const location = useLocation();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const searchTextRef = useRef(searchText);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadErrors, setDownloadErrors] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (searchTimeout == null) {
      setSearchTimeout(setTimeout(() => {
        setupHydrants(searchTextRef.current);
        setSearchTimeout(null);
      }, 500));
    }
  }, [searchText, auth.companyId, auth.user]);

  const setupHydrants = async (searchText) => {
    try {
      setLoadingHydrants(true);
      const result = await getHydrants(searchText);
      setHydrants(result);
      setLoadingHydrants(false);
    } catch (ex) {
      console.log(ex);
      setLoadingHydrants(false);
    }
  };

  const updateSearchText = (text) => {
    searchTextRef.current = text;
    setSearchText(text);
  };

  const toggleUpload = () => {
    setUploadOpen(!uploadOpen);
  };

  const toggleDownload = () => {
    setDownloadOpen(!downloadOpen);
  }

  const uploadHydrants = async (files, mappings) => {
    try {
      setUploadErrors([]);
      setUploading(true);
      const result = await importHydrants(files[0], mappings);
      if (result.success) {
        setUploadOpen(false);
        setupHydrants(searchTextRef.current);
      } else {
        setUploadErrors(result.messages || ['An error has occured with the upload.']);
      }
    } catch (ex) {
      if (!ex.response.data)
        setUploadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setUploading(false);
    }
  };

  const downloadHydrants = async (mappings) => {
    try {
      setDownloadErrors([]);
      setDownloading(true);
      const result = await exportHydrants(mappings);
      FileDownload(result, 'hydrants_export.csv');
      setDownloadOpen(false);
    } catch (ex) {
      if (!ex.response.data)
        setDownloadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setDownloading(false);
    }
  };

  const removeHydrants = async (hydrantIds) => {
    await deleteHydrants(hydrantIds);
    await setupHydrants(searchText);
  };

  const showHydrantPage = location.pathname === '/hydrants' || location.pathname === '/hydrants/';

  const importExportFieldList = [
    "Name",
    "Latitude",
    "Longitude",
    "Elevation",
    "Hdop",
    "Vdop",
    "StateX",
    "StateY",
    "StreetNumber",
    "StreetName",
    "CrossStreet",
    "LocationNotes",
    "GeneralLocation",
    "City",
    "State",
    "ZipCode",
    "District",
    "SubDivision",
    "InstallDate",
    "Status",
    "Description",
    "Manufacturer",
    "ModelId",
    "PaintColor",
    "VandalProofType",
    "Restraint",
    "MainSize",
    "BuryDepth",
    "ControlValve",
    "ControlValveDistance",
    "TestingNumber",
    "Comments",
  ];

  return (
    <>
        <HydrantList hydrants={hydrants} setMode={setMode} searchText={searchText} setSearchText={updateSearchText} toggleUpload={toggleUpload} toggleDownload={toggleDownload} deleteHydrants={removeHydrants} />
        {loadingHydrants && <div className={`spinner${showHydrantPage ? '' : ' hidden'}`}></div>}
        <Upload onUpload={uploadHydrants} errors={uploadErrors} uploading={uploading} multiple={false} open={uploadOpen} toggle={toggleUpload} title="Upload Hydrants" defaultFields={importExportFieldList} fieldsStorageKey="hydrantImportExport_list" />
        <Download onDownload={downloadHydrants} errors={downloadErrors} downloading={downloading} multiple={false} open={downloadOpen} toggle={toggleDownload} title="Download Hydrants" defaultFields={importExportFieldList} fieldsStorageKey="hydrantImportExport_list" />
    </>
  );
};

export default Hydrants