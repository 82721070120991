import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import LabelledTextBox from '../../components/LabelledTextBox';
import LabelledPicker from '../../components/LabelledPicker';
import { addHydrantModel } from '../../services/api';
import useAuth from '../../services/authentication/useAuth';
import EventEmitter from '../../services/events';
import { makeHydrantModel } from '../../models';

const AddHydrantModelModal = ({ open, toggle, onComplete, onCancel }) => {
  const { companyId } = useAuth();
  const [hydrantModel, setHydrantModel] = useState(makeHydrantModel({ companyId }));
  const { path, url } = useRouteMatch();

  const save = async () => {
    try {
      hydrantModel.validate();
      const result = await addHydrantModel(hydrantModel);
      onComplete(result.id);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Model', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Model', body: 'Unable to save hydrant model.' });
      }
    }
  };

  const cancel = async () => {
    onCancel();
  };

  const onChange = e => {
    setHydrantModel(makeHydrantModel({ ...hydrantModel, [e.target.name]: e.target.value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value);
      //   break;
    }

    setHydrantModel(makeHydrantModel({ ...hydrantModel, [e.target.name]: value }));
  };

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>
        <div>Add Hydrant Model</div>
      </ModalHeader>
      <ModalBody>
          <div className='details'>
            <LabelledTextBox label="Model ID" value={hydrantModel.modelId} name="modelId" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Description" value={hydrantModel.description} name="description" onChange={onChange} onBlur={onBlur} />
            <LabelledPicker label="Open Direction" value={hydrantModel.openDirection} name="openDirection" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </LabelledPicker>
            <LabelledTextBox type="number" label="Valve Turns" value={hydrantModel.valveTurns} name="valveTurns" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Valve Opening Size" value={hydrantModel.valveOpeningSize} name="valveOpeningSize" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox label="Barrel Type" value={hydrantModel.barrelType} name="barrelType" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Pumper Count" value={hydrantModel.pumperCount} name="pumperCount" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Pumper Diameter" value={hydrantModel.pumperDiameter} name="pumperDiameter" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Pumper Coefficient" value={hydrantModel.pumperCoefficient} name="pumperCoefficient" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Pumper Thread Diameter" value={hydrantModel.pumperThreadDiameter} name="pumperThreadDiameter" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Pumper Threads Per Inch" value={hydrantModel.pumperThreadsPerInch} name="pumperThreadsPerInch" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Nozzle Count" value={hydrantModel.nozzleCount} name="nozzleCount" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Nozzle Diameter" value={hydrantModel.nozzleDiameter} name="nozzleDiameter" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Nozzle Coefficient" value={hydrantModel.nozzleCoefficient} name="nozzleCoefficient" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Nozzle Thread Diameter" value={hydrantModel.nozzleThreadDiameter} name="nozzleThreadDiameter" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="number" label="Nozzle Threads Per Inch" value={hydrantModel.nozzleThreadsPerInch} name="nozzleThreadsPerInch" onChange={onChange} onBlur={onBlur} />
          </div>
      </ModalBody>
      <ModalFooter>
        <div className="actions">
          <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={save}>Save</button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddHydrantModelModal;