import React, { useState, useEffect } from 'react';

const BubbleNotification = ({ children, open, toggle, timeframe }) => {
  useEffect(() => {
    let timeout = null;
    if (open) {
      timeout = setTimeout(close, (timeframe || 2000));
    } else {
      close();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [toggle, open, timeframe]);

  const close = () => {
    if (open)
      toggle();
  };

  return (
    <div className={`bubble-notification${open ? ' open' : ''}`}>
      {children}
    </div>
  );
};

export default BubbleNotification;