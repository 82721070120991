import React from 'react';
import { dateOptions, dateTimeOptions } from '../../../utils/dates';

const ValveSummary = ({ valve }) => {

  return (
    <tr>
      <td>
        <div className="valve-summary">
          <div className="summary-details">
            <div className="summary-detail">
              <label>Device Type:</label>
              <span>{valve?.deviceType ?? 'Valve'}</span>
            </div>
            <div className="summary-detail">
              <label>Device ID:</label>
              <span>{valve?.name}</span>
            </div>
          </div>
          <div className="valve-details">
            <div className="valve-detail">
              <label>Address:</label>
              <span>{(valve?.streetNumber + ' ' + valve?.streetName).trim()}</span>
            </div>
            <div className="valve-detail">
              <label>Cross Street:</label>
              <span>{valve?.crossStreet}</span>
            </div>
            <div className="valve-detail">
              <label>Install Date:</label>
              <span>{valve.installDate ? new Date(valve.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="valve-detail">
              <label>District:</label>
              <span>{valve?.district}</span>
            </div>
            <div className="valve-detail">
              <label>Sub-Division:</label>
              <span>{valve?.subDivision}</span>
            </div>
            <div className="valve-detail">
              <label>Manufacturer:</label>
              <span>{valve?.manufacturer}</span>
            </div>
          </div>
          <div className="valve-stats">
            <div className="valve-stat">
              <label>Exercise Date:</label>
              <span>{valve.lastMaintenanceDate ? new Date(valve.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="valve-stat">
              <label>Valve Size:</label>
              <span>{valve?.size}</span>
            </div>
            <div className="valve-stat">
              <label>Total Revs:</label>
              <span>{valve?.totalRevolutions}</span>
            </div>
            <div className="valve-stat">
              <label>Cycle Count:</label>
              <span>{valve?.autoCycles}</span>
            </div>
            <div className="valve-stat">
              <label>Max Torque:</label>
              <span>{valve?.maxTorque}</span>
            </div>
            <div className="valve-stat">
              <label>Revs @ Max Torque:</label>
              <span>{valve?.maxTorqueRevolutions}</span>
            </div>
            <div className="valve-stat">
              <label>Max Rev In Set Dir:</label>
              <span>{valve?.maxDirectionRevolutions}</span>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
};

export default ValveSummary;