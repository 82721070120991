import React from 'react';
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';
import CircleButton from './CircleButton';

const LabelledTextBoxCheck = ({ label, type, value, name, onChange, onBlur, checked, checkName, checkTitle, onCheckChange, checkedIcon, uncheckedIcon }) => {

  const onTextChange = (e) => {
    onChange({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
      checkName,
      checked
    });
  };

  const onTextBlur = (e) => {
    onBlur({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
      checkName,
      checked
    });
  };

  const onCheckToggle = () => {
    onChange({ name, value, type, checkName, checked: !checked });
  };

  return (
    <div className="labelled-text-box-check">
      <span className="label">{label}</span>
      <input type={type ? type : 'text'} value={value?.toString() ?? ''}
        name={name}
        onChange={onTextChange}
        onBlur={onTextBlur}
        placeholder={label}
      />
      <CircleButton icon={checked ? checkedIcon || faUnlink : uncheckedIcon || faLink} title={checkTitle} onClick={onCheckToggle} />
    </div>
  );
};

export default LabelledTextBoxCheck;
