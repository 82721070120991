import React from 'react';

const LabelledTextField = ({ label, text }) => {
  return (
    <div className="labelled-text-field">
      <span className="label">{label}</span>
      <span className="text">{text}</span>
    </div>
  );
};

export default LabelledTextField;
