export default function buildMakeTorqueLog({ validate, validators }) {
  return function makeTorqueLog({
    id,
    companyId,
    valveExerciseId,
    torque,
    revolutions,
    rpm,
    direction,
    logDate = new Date(),
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      valveExerciseId: {
        prettyName: 'Valve Exercise ID',
        validators: [validators.notNull(), validators.isString(), validators.exactLength(36)],
      },
      torque: {
        prettyName: 'Torque',
        validators: [validators.isNumber()],
      },
      revolutions: {
        prettyName: 'Revolutions',
        validators: [validators.isNumber()],
      },
      rpm: {
        prettyName: 'RPM',
        validators: [validators.isNumber()],
      },
      direction: {
        prettyName: 'Direction',
        validators: [validators.within(['Left', 'Right'])],
      },
      logDate: {
        prettyName: 'Log Date',
        validators: [validators.isDate()],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get valveExerciseId() { return valveExerciseId },
      get torque() { return torque },
      get revolutions() { return revolutions },
      get rpm() { return rpm },
      get direction() { return direction },
      get logDate() { return logDate ? new Date(logDate) : null },
      get maxTorque() { return maxTorque },
      get maxTorqueRevolutions() { return maxTorqueRevolutions },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}