import React, { useState, useEffect } from 'react';
import CompanyTableRow from '../components/company/CompanyTableRow';
import useAuth from '../services/authentication/useAuth';
import { getCompanies } from '../services/api';
import Search from '../components/Search';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faFileExport, faFileImport, faMapMarkedAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import CircleButton from '../components/CircleButton';
import { useHistory, useLocation } from 'react-router-dom';

const SelectCompany = () => {
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [companies, setCompanies] = useState([]);
  const { switchCompany, companyId } = useAuth();
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    setupCompanies();
  }, []);

  const setupCompanies = async (searchText) => {
    setLoadingCompanies(true);
    const result = await getCompanies(searchText);
    setCompanies(result);
    setLoadingCompanies(false);
  };

  const search = (searchText) => {
    setupCompanies(searchText);
  };

  const selectCompany = (companyId) => {
    switchCompany(companyId);
    history.push('/home');
  };

  const companyRows = companies.map((company, index) =>
    <CompanyTableRow key={index} company={company} selected={company.id === companyId} onSelect={selectCompany} />);

  return (
    <div className="list">
      <h2>Select Company</h2>
      <div className="list-actions">
        <Search onSearch={search} />
        <CircleButton icon={faPlus} type="success" title="Create Company" onClick={() => history.push('/createCompany')} />
      </div>
      {loadingCompanies ? (
        <span className="spinner"></span>
      ) : companies.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Company</th>
              <th className='command-row'></th>
            </tr>
          </thead>
          <tbody>
            {companyRows}
          </tbody>
        </table>
      ) : (
        <div className="empty-body">
          <div className="empty-text">You aren't added to any companies yet</div>
          <div className="empty-text-small">An admin must add you to one or you can create a new company by clicking the +.</div>
        </div>
      )}
    </div>
  );
};

export default SelectCompany;