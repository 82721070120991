import { validate, validators } from '../utils/validation';
import buildMakeValve from './valve';
import buildMakeValveExerciseSettings from './valveExerciseSettings';
import buildMakeValveExercise from './valveExercise';
import buildMakeTorqueLog from './torqueLog';
import buildMakeCompany from './company';
import buildMakeCompanyLogo from './companyLogo';
import buildDefaultMakeValveExerciseSettings from './defaultValveExerciseSettings';
import buildMakeHydrant from './hydrant';
import buildMakeFireFlowTest from './fireFlowTest';
import buildMakeHydrantManufacturer from './hydrantManufacturer';
import buildMakeHydrantModel from './hydrantModel';
import buildMakeHydrantImage from './hydrantImage';
import buildMakeHydrantExerciseSettings from './hydrantExerciseSettings';
import buildMakeDefaultHydrantExerciseSettings from './defaultHydrantExerciseSettings';
import buildMakeHydrantExercise from './hydrantExercise';
import buildMakeHydrantTorqueLog from './hydrantTorqueLog';

const makeDefaultValveExerciseSettings = buildDefaultMakeValveExerciseSettings({ validate, validators });
const makeCompany = buildMakeCompany({ validate, validators, makeDefaultValveExerciseSettings });
const makeCompanyLogo = buildMakeCompanyLogo({ validate, validators });
const makeValve = buildMakeValve({ validate, validators });
const makeValveExerciseSettings = buildMakeValveExerciseSettings({ validate, validators });
const makeTorqueLog = buildMakeTorqueLog({ validate, validators });
const makeValveExercise = buildMakeValveExercise({ validate, validators, makeTorqueLog });
const makeHydrant = buildMakeHydrant({ validate, validators });
const makeFireFlowTest = buildMakeFireFlowTest({ validate, validators });
const makeHydrantManufacturer = buildMakeHydrantManufacturer({ validate, validators });
const makeHydrantModel = buildMakeHydrantModel({ validate, validators });
const makeHydrantImage = buildMakeHydrantImage({ validate, validators });
const makeHydrantExerciseSettings = buildMakeHydrantExerciseSettings({ validate, validators });
const makeDefaultHydrantExerciseSettings = buildMakeDefaultHydrantExerciseSettings({ validate, validators });
const makeHydrantTorqueLog = buildMakeHydrantTorqueLog({ validate, validators });
const makeHydrantExercise = buildMakeHydrantExercise({ validate, validators, makeHydrantTorqueLog });

export {
  makeCompany,
  makeCompanyLogo,
  makeValve,
  makeDefaultValveExerciseSettings,
  makeValveExerciseSettings,
  makeValveExercise,
  makeTorqueLog,
  makeHydrant,
  makeFireFlowTest,
  makeHydrantManufacturer,
  makeHydrantModel,
  makeHydrantImage,
  makeHydrantExerciseSettings,
  makeDefaultHydrantExerciseSettings,
  makeHydrantExercise,
  makeHydrantTorqueLog
};
