import React from 'react';
import { timeSecondOptions } from '../utils/dates';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Torque Log',
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
    callbacks: {
      title: tooltipItems => {
        let label = '';
        if (tooltipItems.length > 0) {
          const tpLabel = tooltipItems[0].label;
          const date = new Date(tpLabel);
          label = new Intl.DateTimeFormat('en-US', timeSecondOptions).format(date);
        }
        return label;
      },
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        align: 'center',
        text: 'Torque'
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: true,
        align: 'center',
        text: 'Revolutions'
      },
    },
  },
};

const ExerciseChart = ({ exercise }) => {
  const torqueLogs = exercise?.torqueLogs || exercise?.hydrantTorqueLogs || [];
  const data = {
    datasets: [
      {
        label: 'Torque',
        data: torqueLogs.map(t => { return { x: t.logDate.toISOString(), y: t.torque }}) ?? [],
        borderColor: '#02a5ff',
        backgroundColor: 'rgba(2, 165, 255, 0.5)',
        borderWidth: 1,
        yAxisID: 'y',
        xAxisID: 'x',
      },
      {
        label: 'Revolutions',
        data: torqueLogs.map(t => { return { x: t.logDate, y: t.revolutions }}) ?? [],
        borderColor: '#ffa502',
        backgroundColor: 'rgba(255, 165, 2, 0.5)',
        borderWidth: 1,
        yAxisID: 'y1',
        xAxisID: 'x',
      },
    ],
  };

  return (
    <div className="chart">
      <Line options={options} data={data} />
    </div>
  );
};

export default ExerciseChart;