import React, { useState, useEffect } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../components/LabelledTextBox';
import LabelledTextArea from '../components/LabelledTextArea';
import LabelledDatePicker from '../components/LabelledDatePicker';
import LabelledPicker from '../components/LabelledPicker';
import LabelledCheckBox from '../components/LabelledCheckBox';
import { getValveExerciseEdit, updateValveExercise } from '../services/api';
import { makeValveExercise } from '../models';
import EventEmitter from '../services/events';

const EditValveExercise = ({ onUpdate }) => {
  const [exercise, setExercise] = useState(null);
  const { path, url } = useRouteMatch();
  const { valveId, valveExerciseId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExercise();
  }, [valveId, history.location]);

  const setupExercise = async () => {
    const result = await getValveExerciseEdit(valveExerciseId);
    setExercise(makeValveExercise(result));
  };

  const save = async () => {
    try {
      console.log(exercise);
      exercise.validate();
      const result = await updateValveExercise(exercise);
      onUpdate(valveExerciseId);
      history.replace(`/valves/${valveId}/valveExercise/${exercise.id}`);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Valve Exercise', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Valve Exercise', body: 'Unable to save valve exercise.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    const type = e.type;
    let value = e.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      case 'checkbox':
        value = !!value;
        break;
    }
    setExercise(makeValveExercise({ ...exercise, [e.name]: value }));
  };

  const onBlur = e => {
    console.log(e.type);
    const type = e.type;
    let value = e.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      case 'checkbox':
        value = !!value;
        break;
    }

    setExercise(makeValveExercise({ ...exercise, [e.name]: value }));
  };
  
  return (
    <div>
      <h3>Edit Valve Exercise</h3>
      {exercise ? (
        <>
          <div className="details">
            <LabelledDatePicker label="Exercise Date" value={exercise.exerciseDate} name="exerciseDate" onChange={onChange} dateFormat="MM/dd/yyyy h:mm aa" showTimeSelect />
            <LabelledCheckBox label="Manual Entry" value={exercise.manual} name="manual" onChange={onChange} onBlur={onBlur} />
            {exercise.manual && 
            <LabelledPicker label="Difficulty" value={exercise.difficulty} name="difficulty" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Easy">Easy</option>
              <option value="Medium">Medium</option>
              <option value="Hard">Hard</option>
            </LabelledPicker>}
            <LabelledTextArea label="Comments" value={exercise.comments} name="comments" onChange={onChange} onBlur={onBlur} />
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditValveExercise;
