import React, { useState, useEffect } from 'react';
import useAuth from '../services/authentication/useAuth';
import LabelledTextBox from '../components/LabelledTextBox';
import { useHistory } from 'react-router-dom';

const Register = () => {
  const [registration, setRegistration] = useState({});
  const [registering, setRegistering] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [registered, setRegistered] = useState(false);
  const history = useHistory();
  const { register } = useAuth();

  useEffect(() => {
    if (registered) {
      setTimeout(() => history.replace('/login'), 5000);
    }
  }, [registered])

  const onChange = e => {
    setRegistration({ ...registration, [e.target.name]: e.target.value });
  };

  const onBlur = e => {
    setRegistration({ ...registration, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    try {
      e.preventDefault();
      setErrorMessages([]);
      setRegistering(true);
      const errors = [];
      if (!registration.email) {
        errors.push('Email is required');
      }
      if (!registration.firstName) {
        errors.push('First Name is required');
      }
      if (!registration.lastName) {
        errors.push('Last Name is required');
      }
      if (registration.password !== registration.confirmPassword) {
        errors.push("Password and Confirm Password don't match");
      }
      if ((registration.password?.length ?? 0) < 6) {
        errors.push("Password must be at least 6 characters long");
      }
      if (!/[a-z]/.test(registration.password)) {
        errors.push('Password must contain a lower case letter');
      }
      if (!/[A-Z]/.test(registration.password)) {
        errors.push('Password must contain an upper case letter');
      }
      if (!/[0-9]/.test(registration.password)) {
        errors.push('Password must contain a number');
      }
      if (!/[!@#$%^&*]/.test(registration.password)) {
        errors.push('Password must contain a symbol: ! @ # $ % ^ & *');
      }

      if (errors.length) {
        setErrorMessages(errors);
        setRegistering(false);
        return;
      }

      await register(registration);
      setRegistered(true);
      setRegistering(false);
    } catch (ex) {
      console.log(ex);
      console.log(ex.response);
      setRegistering(false);
      if (typeof ex.response.data === 'string') {
        setErrorMessages([ex.response.data]);
      } else if (typeof ex.response.data === 'object') {
        for (const error in ex.response.data.errors) {
          console.log(error);
          setErrorMessages([ex.response.data.errors[error]]);
        }
      } else {
        setErrorMessages(['An error occured when registering.']);
      }
    }
  };

  return (
    <form className="register" onSubmit={handleRegister}>
      <h2>Register</h2>
      {registered ? (
        <div className="center-text">Registered successfully and confirmation email sent. Returning to Login...</div>
      ) : (
        <>
          {errorMessages.map((error, idx) => <div key={idx} className="error center-text">{error}</div>)}
          <div className="details">
            <LabelledTextBox type="email" label="Email" value={registration.email} name="email" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="text" label="First Name" value={registration.firstName} name="firstName" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="text" label="Last Name" value={registration.lastName} name="lastName" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="password" label="Password" value={registration.password} name="password" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="password" label="Confirm Password" value={registration.confirmPassword} name="confirmPassword" onChange={onChange} onBlur={onBlur} />
          </div>
          <div className="form-actions">
            <button type="submit" className="btn btn-primary" disabled={registering}>Register</button>
          </div>
        </>
      )}
    </form>
  );
};

export default Register;