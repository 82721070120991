import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { confirmEmail } from '../services/api';
import EventEmitter from '../services/events';
import { useLocation } from 'react-router-dom';

const ConfirmEmail = () => {
  const [confirmingEmail, setConfirmingEmail] = useState(true);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');
  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    confirmAccountEmail();
  }, []);

  const confirmAccountEmail = async () => {
    try {
      setConfirmingEmail(true);
      await confirmEmail({
        userId,
        code,
      });
      setEmailConfirmed(true);
    } catch (ex) {
      console.log(ex);
    } finally {
      setConfirmingEmail(false);
    }
  };

  let jsx = (
    <div>
      <div>Confriming Email Account</div>
      <div className="spinner"></div>
    </div>
  );

  if (emailConfirmed) {
    jsx = (
      <div>
        <div>Email Confirmed</div>
        <Link to="/login">Return to Login</Link>
      </div>
    );
  } else if (!confirmingEmail) {
    jsx = (
      <div>
        <div>Unable to confirm account</div>
      </div>
    );
  }

  return (
    <div>
      <h2>Confirm Email</h2>
      {jsx}
    </div>
  );
};

export default ConfirmEmail;