import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getFireFlowTests = async (hydrantId) => {
  const result = await tryGet(`/fireFlowTests?hydrantId=${hydrantId || ''}`);
  return result.data;
};

export const getFireFlowTest = async (fireFlowTestId) => {
  const result = await tryGet(`/fireFlowTests/${fireFlowTestId}`);
  return result.data;
};

export const getFireFlowTestLatest = async (hydrantId) => {
  const result = await tryGet(`/fireFlowTests/latest/${hydrantId}`);
  return result.data;
};

export const getFireFlowTestEdit = async (fireFlowTestId) => {
  const result = await tryGet(`/fireFlowTests/${fireFlowTestId}/edit`);
  return result.data;
};

export const updateFireFlowTest = async (fireFlowTest) => {
  const result = await tryPut(`/fireFlowTests`, fireFlowTest);
  return result.data;
};

export const addFireFlowTest = async (fireFlowTest) => {
  const result = await tryPost(`/fireFlowTests`, fireFlowTest);
  return result.data;
};

export const deleteFireFlowTest = async (fireFlowTestId) => {
  const result = await tryDelete(`/fireFlowTests/${fireFlowTestId}`);
  return result.data;
}