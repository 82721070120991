import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../components/LabelledTextField';
import { dateOptions } from '../utils/dates';
import { makeValveExerciseSettings } from '../models';
import axios from 'axios';

const TorqueLogs = () => {
  const [exerciseSettings, setExerciseSettings] = useState(null);
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExerciseSettings();
  }, [valveId, history.location]);

  const setupExerciseSettings = async () => {
    const result = await axios.get('/api/valveExerciseSettings/' + valveId)
    console.log(result.data);
    setExerciseSettings(makeValveExerciseSettings(result.data));
  };
  
  return (
    <div>
      {exerciseSettings ? (
        <>
          <div className="details">
            <LabelledTextField label="Torque Set Point" text={exerciseSettings.torqueSetPoint} />
            <LabelledTextField label="RPM Set Point" text={exerciseSettings.rpmSetPoint} />
            <LabelledTextField label="Open Direction" text={exerciseSettings.openDirection} />
            <LabelledTextField label="Current State" text={exerciseSettings.currentState} />
            <LabelledTextField label="Revolutions" text={exerciseSettings.revolutions} />
            <LabelledTextField label="Stuck Revs" text={exerciseSettings.stuckRevs} />
            <LabelledTextField label="Stuck Attempts" text={exerciseSettings.stuckAttempts} />
            <LabelledTextField label="Open Recess" text={exerciseSettings.openRecess} />
            <LabelledTextField label="Close Recess" text={exerciseSettings.closeRecess} />
            <LabelledTextField label="flushRevolutions" text={exerciseSettings.flushRevolutions} />
            <LabelledTextField label="Flush Cycles" text={exerciseSettings.flushCycles} />
            <LabelledTextField label="Hardstop Speed" text={exerciseSettings.hardstopSpeed} />
          </div>
          <div className="actions">
            <Link to={`${url}/edit`}>Edit</Link>
          </div>
        </>
      ) : <div className="spinner"></div>}
    </div>
  );
};

export default TorqueLogs