import React, { useState } from 'react';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import CircleButton from '../CircleButton';

const DefaultHydrantExerciseSettingsRow = ({ defaultSettings, onDelete }) => {
  const [startDelete, setStartDelete] = useState(false);
  return (
    <tr id={defaultSettings.id}>
      <td>{defaultSettings.hydrantModelName}</td>
      <td>{defaultSettings.torqueSetPoint}</td>
      <td>{defaultSettings.rpmSetPoint}</td>
      <td>{defaultSettings.openDirection}</td>
      <td>{defaultSettings.currentState}</td>
      <td>{defaultSettings.revolutions}</td>
      <td className="action-cell">
        <div className="list-actions">
          <Link to={`defaultHydrantExerciseSettings/${defaultSettings.id}/edit`}>
            <CircleButton icon={faEdit} type="primary" title="Edit" />
          </Link>
          <CircleButton icon={faTrashAlt} type="danger" title="Delete" onClick={() => setStartDelete(true)} />
        </div>
        {startDelete && (
          <div className="row-confirm">
            <div className="form-actions">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={() => onDelete(defaultSettings.id)}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default DefaultHydrantExerciseSettingsRow;
