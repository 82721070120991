import { faCaretDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useWindowEvent } from '../utils/useWindowEvent';
import CircleButton from './CircleButton';

const CircleButtonCollapse = ({ icon, title, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setOpen(false);
  };
  useWindowEvent('click', handleClick);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className="circle-button-collapse">
      <CircleButton icon={icon || faEllipsisV} onClick={toggleDropdown} title={title} />
      <div className={`collapse${open ? ' open' : ''}`}>
        <div className="collapse-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CircleButtonCollapse;