import * as React from "react";
import useLocalStorage from "../../utils/useLocalStorage";
import { login as userLogin, logout as userLogout, register as userRegistration, refreshToken as userRefreshToken } from '../api/account';
import { parseJWT } from "../../utils/jwtParser";

const authContext = React.createContext();

function useAuth() {
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage('access_token');
  const [companyId, setCompanyId] = useLocalStorage('company_id');

  let timeLeft = 0;
  const token = parseJWT(accessToken);
  if (token) {
    timeLeft = token.exp * 1000 - new Date();
  }

  const [authed, setAuthed] = React.useState(token ? true : false); // && timeLeft > 0

  
  const rolesAccepted = token?.roles?.some(ur => ur.CompanyId === companyId) ?? false;
  const companySubscribed = token?.companies?.some(cs => cs.CompanyId === companyId && cs.IsSubscribed);

  // React.useEffect(() => {
  //   const timeout = setTimeout(async () => {
  //     console.log('Token refreshing');
  //     const loggedIn = await userRefreshToken();
  //     setAuthed(loggedIn);
  //   }, timeLeft - 30 * 60 * 1000);
  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, [accessToken]);

  return {
    authed,
    companyId: rolesAccepted ? companyId : null,
    currentCompany: rolesAccepted ? { companyId: companyId, isSubscribed: companySubscribed } : null,
    user: token,
    login(credentials) {
      return new Promise(async (resolve, reject) => {
        try {
          const loggedIn = await userLogin(credentials);
          setAuthed(loggedIn);
          resolve();
        } catch (ex) {
          reject(ex);
        }
      });
    },
    logout() {
      return new Promise(async (resolve, reject) => {
        try {
          setAuthed(false);
          await userLogout();
          resolve();
        } catch (ex) {
          console.log(ex);
          resovle();
        }
      });
    },
    refreshToken() {
      return new Promise(async (resolve, reject) => {
        try {
          const loggedIn = await userRefreshToken();
          setAuthed(loggedIn);
          resolve();
        } catch (ex) {
          reject(ex);
        }
      });
    },
    register(registration) {
      return new Promise(async (resolve, reject) => {
        try {
          await userRegistration(registration);
          resolve();
        } catch (ex) {
          reject(ex);
        }
      });
    },
    switchCompany(companyId) {
      setCompanyId(companyId);
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}