import { getAxios } from './index';
import { jwtDecode } from 'jwt-decode';

//credentials = { email: 'email@address', password: 'P@ssw0rd!@#$%^' };
export const login = async (credentials) => {
  const result = await getAxios().post('/identity/login', credentials);
  if (result.data.token) {
    localStorage.setItem('access_token', result.data.token);
    localStorage.setItem('refresh_token', result.data.refreshToken);
    const storageEvent = new StorageEvent('storage');
    storageEvent.initStorageEvent('storage',
        true,
        true,
        'access_token',
        null,
        result.data.token,
        '/',
        localStorage,
    );
    window.dispatchEvent(storageEvent);
    return true;
  }
  return false;
};

export const refreshToken = async () => {
  try {
    const token = localStorage.getItem('refresh_token');
    const result = await getAxios().post('/identity/refreshToken', { refreshToken: token });
    if (result.data.token) {
      localStorage.setItem('access_token', result.data.token);
      localStorage.setItem('refresh_token', result.data.refreshToken);
      const storageEvent = new StorageEvent('storage');
      storageEvent.initStorageEvent('storage',
          true,
          true,
          'access_token',
          null,
          result.data.token,
          '/',
          localStorage,
      );
      window.dispatchEvent(storageEvent);
      return true;
    }
    return false;
  } catch (ex) {
    localStorage.setItem('access_token', null);
    localStorage.setItem('refresh_token', null);
    const storageEvent = new StorageEvent('storage');
    storageEvent.initStorageEvent('storage',
        true,
        true,
        'access_token',
        null,
        null,
        '/',
        localStorage,
    );
    window.dispatchEvent(storageEvent);
    return false;
  }
};

// {
//   "firstName": "Warren",
//   "lastName": "Wegenke",
//   "email": "wjwegg@gmail.com",
//   "password": "P@ssw0rd!@#$%^",
//   "confirmPassword": "P@ssw0rd!@#$%^"
// }
export const register = async (registration) => {
  const result = await getAxios().post('/identity/register', registration);
  return result.data;
};

export const logout = async () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  const storageEvent = new StorageEvent('storage');
  storageEvent.initStorageEvent('storage',
      true,
      true,
      'access_token',
      null,
      result.data.token,
      '/',
      localStorage,
  );
  window.dispatchEvent(storageEvent);
  const result = await getAxios().post('/identity/logout');
};

export const getCurrentUser = (token) => {
  try {
    const _token = token || localStorage.getItem('access_token');
    if (!_token) {
      return null;
    }

    const _tokenDecoded = jwtDecode(_token);
    return _tokenDecoded;
  } catch (ex) {
    return null;
  }
};

export const sendEmailConfirmation = async (email) => {
  const result = await getAxios().post('/identity/sendEmailConfirmation', { email });
  return result.data;
};

export const confirmEmail = async (credentials) => {
  const result = await getAxios().post('/identity/confirmEmail', credentials);
  return result.data;
};

export const updatePassword = async (credentials) => {
  const result = await getAxios().post('/identity/updatePassword', credentials);
  return result.data;
};

export const forgotPassword = async (email) => {
  const result = await getAxios().post('/identity/forgotPassword', { email });
  return result.data;
};

export const resetPassword = async (credentials) => {
  const result = await getAxios().post('/identity/resetPassword', credentials);
  return result.data;
};