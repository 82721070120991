import React, { useState } from 'react';
import { getCurrentUser } from '../services/api';
import useAuth from '../services/authentication/useAuth';
import { sendEmailConfirmation } from '../services/api';
import LabelledTextBox from '../components/LabelledTextBox';
import { useHistory, Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

const Login = () => {
  const [credentials, setCredentials] = useState({});
  const [loggingIn, setLoggingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState(null);
  const [sendingConfirmationEmail, setSendingConfrimationEmail] = useState(false);
  const [sentConfirmationEmail, setSentConfirmationEmail] = useState(false);
  const history = useHistory();
  const { login } = useAuth();

  const onChange = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onBlur = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    try {
      setErrorMessage(null);
      setUnconfirmedEmail(null);
      setLoggingIn(true);
      e.preventDefault();
      await login(credentials);
      history.replace('/home');
    } catch (ex) {
      setLoggingIn(false);
      if (ex?.response?.data.error === 'Email Unconfirmed') {
        setUnconfirmedEmail(ex?.response?.data.email);
        setErrorMessage('Email Unconfirmed');
      } else {
        setErrorMessage('Invalid Email or Password');
      }
      setErrorMessageOpen(true);
      console.log(ex);
      console.log(ex.response);
    }
  };

  const sendEmailAccountConfirmation = async () => {
    try {
      setSentConfirmationEmail(false);
      setSendingConfrimationEmail(true);
      await sendEmailConfirmation(unconfirmedEmail);
      setSentConfirmationEmail(true);
    } catch (ex) {
      setErrorMessage('An error occurred while sending the confirmation email.');
    } finally {
      setSendingConfrimationEmail(false);
    }
  }

  return (
    <form className="login" onSubmit={handleLogin}>
      <h2>Login</h2>
      {errorMessage && <div className="error center-text">{errorMessage}</div>}
      {unconfirmedEmail && <div className="center-text"><div className="link" onClick={sendEmailAccountConfirmation}>Resend confirmation email</div></div>}
      {sentConfirmationEmail && <div className="center-text">Sent Confirmation Email</div>}
      <div className="details">
        <LabelledTextBox type="email" label="Email" value={credentials.email} name="email" onChange={onChange} onBlur={onBlur} />
        <LabelledTextBox type="password" label="Password" value={credentials.password} name="password" onChange={onChange} onBlur={onBlur} />
      </div>
      <div className="form-actions">
        <Link to="/account/forgotPassword">Forgot Password</Link>
        <button type="submit" className="btn btn-primary" disabled={loggingIn}>Login</button>
      </div>
      {(loggingIn || sendingConfirmationEmail) && <div className="spinner"></div>}
    </form>
  );
};

export default Login;
