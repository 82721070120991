import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Pill = ({ content, onDismiss }) => {

  return (
    <div className="pill">
      <div className="pill-body">
        <div className="pill-content">
          {content}
        </div>
        {onDismiss ? (
          <div className="pill-button" onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        ) : null}
      </div>
    </div>
  )
};

export default Pill;