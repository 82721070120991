import { tryGet, tryPost } from './index';

export const createOrder = async () => {
  const result = await tryGet('/payPal/create');
  return result.data;
};

export const captureOrder = async (companyId) => {
  const result = await tryPost(`/payPal/capture/${companyId}`);
  return result.data;
};

export const createSubscription = async (planId) => {
  const result = await tryPost(`/payPal/createSubscription/${planId}`);
  return result.data;
};

export const cancelSubscription = async (subscriptionId) => {
  const result = await tryPost(`/payPal/cancelSubscription/${subscriptionId}`);
  return result.data;
};

export const getSubscriptionStatus = async (subscriptionId) => {
  const result = await tryGet(`/payPal/getSubscriptionStatus/${subscriptionId}`);
  return result.data;
};

export const getSubscription = async (subscriptionId) => {
  const result = await tryGet(`/payPal/getSubscription/${subscriptionId}`);
  return result.data;
};

export const getPlans = async () => {
  const result = await tryGet(`/payPal/getPlans`);
  return result.data;
};

export const getPlan = async (planId) => {
  const result = await tryGet(`/payPal/getPlans/${planId}`);
  return result.data;
};

export const getClientId = async () => {
  const result = await tryGet(`/payPal/getClientId`);
  return result.data;
};