import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../../components/LabelledTextBox';
import { dateOptions } from '../../utils/dates';
import LabelledPicker from '../../components/LabelledPicker';
import { getDefaultHydrantExerciseSettings, updateDefaultHydrantExerciseSettings, getHydrantModels } from '../../services/api';
import { makeDefaultHydrantExerciseSettings, makeHydrantModel } from '../../models';
import EventEmitter from '../../services/events';
import CircleButton from '../../components/CircleButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddHydrantModelModal from './AddHydrantModelModal';

const EditDefaultHydrantExerciseSettings = () => {
  const [defaultSettings, setDefaultSettings] = useState(null);
  const [hydrantModels, setHydrantModels] = useState([]);
  const [addHydrantModelModalOpen, setAddHydrantModelModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { path, url } = useRouteMatch();
  const { defaultHydrantExerciseSettingsId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExerciseSettings();
    setupHydrantModels();
  }, [defaultHydrantExerciseSettingsId, history.location]);

  const setupExerciseSettings = async () => {
    const result = await getDefaultHydrantExerciseSettings(defaultHydrantExerciseSettingsId);
    setDefaultSettings(makeDefaultHydrantExerciseSettings(result));
  };

  const setupHydrantModels = async () => {
    const result = await getHydrantModels();
    setHydrantModels(result.map(r => makeHydrantModel(r)));
  };

  const save = async () => {
    try {
      setSaving(true);
      defaultSettings.validate();
      const result = await updateDefaultHydrantExerciseSettings(defaultSettings);
      history.replace('/defaultHydrantExerciseSettings');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Default Hydrant Exercise Settings', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Default Hydrant Exercise Settings', body: 'Unable to save default hydrant exercise settings.' });
      }
    } finally {
      setSaving(false);
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }
    setDefaultSettings(makeDefaultHydrantExerciseSettings({ ...defaultSettings, [name]: value }));
  };

  const onBlur = e => {
    const type = e.target.type;
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }

    switch (type) {
      case "number":
        value = +value;
        break;
    }

    setDefaultSettings(makeDefaultHydrantExerciseSettings({ ...defaultSettings, [name]: value }));
  };

  const handleAddHydrantModel = async (hydrantModelId) => {
    setAddHydrantModelModalOpen(false);
    setDefaultSettings(makeDefaultHydrantExerciseSettings({ ...defaultSettings, hydrantModelId }));
    await setupHydrantModels();
  };

  const years = Math.floor(defaultSettings?.exerciseFrequencyMonths / 12);
  const months = defaultSettings?.exerciseFrequencyMonths % 12;
  
  const hydrantModelOptions = hydrantModels.map((m, i) => <option key={m.id} value={m.id}>{m.modelId}</option>);

  return (
    <div>
      <h3>Edit Default Hydrant Exercise Settings</h3>
      {saving && <div className="spinner"></div>}
      {defaultSettings ? (
        <>
          <div className="details">
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledPicker label="Model ID" value={defaultSettings.hydrantModelId} name="hydrantModelId" onChange={onChange} onBlur={onBlur} >
                  <option value=""></option>
                  {hydrantModelOptions}
                </LabelledPicker>
              </div>
              <div className='input-actions'>
                <CircleButton icon={faPlus} type="success" title="Add New Hydrant Model" onClick={() => setAddHydrantModelModalOpen(true)}/>
              </div>
            </div>
            <LabelledTextBox type="number" label="Torque Set Point" value={defaultSettings.torqueSetPoint} name="torqueSetPoint" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="RPM Set Point" value={defaultSettings.rpmSetPoint} name="rpmSetPoint" onChange={onChange} onBlur={onBlur}/>
            <LabelledPicker label="Open Direction" value={defaultSettings.openDirection} name="openDirection" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </LabelledPicker>
            <LabelledPicker label="Current State" value={defaultSettings.currentState} name="currentState" onChange={onChange} onBlur={onBlur}>
              <option value=""></option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </LabelledPicker>
            <LabelledTextBox type="number" label="Revolutions" value={defaultSettings.revolutions} name="revolutions" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Stuck Revs" value={defaultSettings.stuckRevs} name="stuckRevs" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Stuck Attempts" value={defaultSettings.stuckAttempts} name="stuckAttempts" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Open Recess" value={defaultSettings.openRecess} name="openRecess" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Close Recess" value={defaultSettings.closeRecess} name="closeRecess" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Flush Revolutions" value={defaultSettings.flushRevolutions} name="flushRevolutions" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Flush Cycles" value={defaultSettings.flushCycles} name="flushCycles" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Hardstop Speed" value={defaultSettings.hardstopSpeed} name="hardstopSpeed" onChange={onChange} onBlur={onBlur}/>
            <LabelledTextBox type="number" label="Auto Cycles" value={defaultSettings.autoCycles} name="autoCycles" onChange={onChange} onBlur={onBlur}/>
            <div className="labelled-text-box">
              <span className="label">Hydrant Exercise Frequency</span>
              <div className="input">
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Years</span>
                  <input type="number" value={years?.toString() ?? ''}
                    name="exerciseFrequencyYears"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Hydrant Exercise Frequency (Years)"
                  />
                </div>
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Months</span>
                  <input type="number" value={months?.toString() ?? ''}
                    name="exerciseFrequencyMonths"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Hydrant Exercise Frequency (Months)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-secondary" onClick={cancel} disabled={saving}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save} disabled={saving}>Save</button>
          </div>
          <AddHydrantModelModal open={addHydrantModelModalOpen} onCancel={() => setAddHydrantModelModalOpen(false)} onComplete={handleAddHydrantModel} />
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditDefaultHydrantExerciseSettings;
