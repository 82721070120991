import React from 'react';
import { Link } from 'react-router-dom';
import LabelledTextField from '../LabelledTextField';
import { dateOptions } from '../../utils/dates';
import { faEye, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import CircleButton from '../CircleButton';

const ValveMapCard = React.forwardRef(({ valve, zoomTo }, ref ) => {
  const zoom = () => {
    zoomTo(valve.id, [valve.longitude, valve.latitude]);
  };
  return (
    <div className="map-card card" id={valve.id} ref={ref}>
      <h3>{valve.name}</h3>
      <div className='details'>
        <LabelledTextField label="Last Maintenance Date" text={valve.lastMaintenanceDate ? new Date(valve.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''} />
        <LabelledTextField label="Max Torque" text={valve.maxTorque} />
        <LabelledTextField label="Max Torque Turns" text={valve.maxTorqueRevolutions} />
      </div>
      <div className='actions'>
        <div className="link-icon" onClick={zoom}>
          <CircleButton icon={faSearchLocation} size="large" title="Zoom To" />
        </div>
        <Link to={`/valves/${valve.id}`}>
          <CircleButton icon={faEye} size="large" type="primary" title="Details" />
        </Link>
      </div>
    </div>
  )
});

export default ValveMapCard;