import React, { useState, useEffect } from 'react';
import { getCurrentCompany, addCompany, addCompanyLogo } from '../services/api';
import { makeCompany, makeCompanyLogo } from '../models';
import LabelledTextBox from '../components/LabelledTextBox';
import LabelledPicker from '../components/LabelledPicker';
import LabelledImagePicker from '../components/LabelledImagePicker';
import EventEmitter from '../services/events';
import { useHistory } from 'react-router-dom';
import useAuth from '../services/authentication/useAuth';
import { stateOptions } from '../utils/dropdownOptions/stateOptions';

const AddCompany = () => {
  const [company, setCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const { switchCompany, refreshToken } = useAuth();
  const history = useHistory();

  const save = async () => {
    try {
      company.validate();
      const result = await addCompany(company);
      switchCompany(result.id);
      await refreshToken();

      if (companyLogo) {
        try {
          companyLogo.validate();
          const data = await addCompanyLogo(makeCompanyLogo({ ...companyLogo, companyId: result.id }));
          //const uploadedImage = await data.json();
          if (data) {
            console.log("Successfully uploaded image");
          } else {
            console.log("Error Found");
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      
      EventEmitter.emit('show-notification', 'Created Company');
      history.replace('/company');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Creating Company', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Creating Company', body: 'Unable to create company.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    setCompany(makeCompany({ ...company, [e.target.name]: e.target.value }));
  };

  const onChangePhone = e => {
    let value = e.target.value;
    let name = e.target.name;

    const number = value.replace(/[^0-9]/g, '');

    setCompany(makeCompany({ ...company, [name]: number }));
  };

  const onBlur = e => {
    let value = e.target.value;

    setCompany(makeCompany({ ...company, [e.target.name]: value }));
  };

  const onBlurPhone = e => {
    let value = e.target.value;
    let name = e.target.name;

    const number = value.replace(/[^0-9]/g, '');

    setCompany(makeCompany({ ...company, [name]: number }));
  };

  const onLogoChange = (e) => {
    if (e) {
      setCompanyLogo(makeCompanyLogo({ companyId: company.id, ...companyLogo, ...e }))
    } else {
      setCompanyLogo(null);
    }
  };

  let phoneNumber = company?.phoneNumber ? `(${company?.phoneNumber.substring(0, 3)}) ${company?.phoneNumber.substring(3, 6)}-${company?.phoneNumber.substring(6, company?.phoneNumber.length)}` : '';
  phoneNumber = phoneNumber.replace(/[\(\)\s-]+$/, '');

  return (
    <div>
        <h2>Create Company</h2>
        <div className='details'>
          <LabelledTextBox label="Name" value={company?.name} name="name" onChange={onChange} onBlur={onBlur} />
          <LabelledTextBox label="Street Address" value={company?.streetAddress} name="streetAddress" onChange={onChange} onBlur={onBlur} />
          <LabelledTextBox label="City" value={company?.city} name="city" onChange={onChange} onBlur={onBlur} />
          <LabelledPicker label="State" value={company?.state} name="state" onChange={onChange} onBlur={onBlur} >
            <option value=""></option>
            {stateOptions}
          </LabelledPicker>
          <LabelledTextBox label="Zip Code" value={company?.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
          <LabelledTextBox label="Phone Number" value={phoneNumber} name="phoneNumber" onChange={onChangePhone} onBlur={onBlurPhone} />
          <LabelledImagePicker label="Logo" value={companyLogo} onChange={onLogoChange} />
        </div>
        <div className="form-actions">
          <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={save}>Save</button>
        </div>
    </div>
  )
};

export default AddCompany;