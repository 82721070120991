export const isFileImage = (file) => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  return file && acceptedImageTypes.includes(file['type'])
}

export const getBase64 = (file) => {
  return new Promise((res, rej) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      res(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      rej(error);
    };  
  });
}