import React, { useState, useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { faMapMarkedAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import Search from '../components/Search';
import CircleButton from '../components/CircleButton';
import useAuth from '../services/authentication/useAuth';
import DefaultValveExerciseSettingsRow from '../components/defaultValveExerciseSettings/DefaultValveExerciseSettingsRow';
import { getAllDefaultValveExerciseSettings, deleteDefaultValveExerciseSettings } from '../services/api';

const DefaultValveExerciseSettings = () => {
  const [defaultValveExerciseSettings, setDefaultValveExerciseSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { path, url } = useRouteMatch();
  const { companyId } = useAuth();

  useEffect(() => {
    setupDefaultSettings();
  }, []);

  const setupDefaultSettings = async () => {
    setLoading(true);
    const result = await getAllDefaultValveExerciseSettings();
    console.log(result);
    const resultSorted = result.sort((a, b) => a.valveSize <= b.valveSize ? -1 : 1);
    setDefaultValveExerciseSettings(resultSorted);
    setLoading(false);
  };

  const onDelete = async id => {
    await deleteDefaultValveExerciseSettings(id);
    await setupDefaultSettings();
  };

  const rows = defaultValveExerciseSettings?.map((defaultSettings, index) => <DefaultValveExerciseSettingsRow key={index} defaultSettings={defaultSettings} onDelete={onDelete} />);

  return (
    <div id="defaultValveExerciseSettings" className="list">
      <h2>Default Valve Exercise Settings</h2>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Valve Size</th>
              <th>Torque Set Point</th>
              <th>RPM Set Point</th>
              <th>Current State</th>
              <th>Open Direction</th>
              <th>Revolutions</th>
              <th className='command-row'>
                <Link to={'defaultValveExerciseSettings/add'}>
                  <CircleButton icon={faPlus} type="success" title="Add Default Valve Exercise Settings" />
                </Link>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DefaultValveExerciseSettings;