import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import useAuth from '../../services/authentication/useAuth';
import Pill from '../Pill';
import CircleButton from '../CircleButton';

const InviteTableRow = ({ invite, onUninvite, roles }) => {
  const { path, url } = useRouteMatch();
  const auth = useAuth();
  const inviteRoles = invite.roleIds?.map((roleId, idx) => <Pill key={idx} content={roles.find(r => r.id === roleId)?.roleName} />);
  return (
    <tr id={invite.id}>
      <td>{invite.email}</td>
      <td className="roles">{inviteRoles}</td>
      <td>
        <CircleButton icon={faTrashAlt} type="danger" title="Uninvite" onClick={() => onUninvite(invite.email)} />
      </td>
    </tr>
  );
};

export default InviteTableRow;