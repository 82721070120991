import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { getCurrentCompany, createOrder, createSubscription, getCompanyLogo } from '../services/api';
import { makeCompany, makeCompanyLogo } from '../models';
import CompanySubscription from '../components/company/CompanySubscription';
import CompanySubscriptionPlan from '../components/company/CompanySubscriptionPlan';
import LabelledTextField from '../components/LabelledTextField';
import LabelledImage from '../components/LabelledImage';
import IsAuthorized from '../services/authentication/IsAuthorized';

const Company = () => {
  console.log('loading');
  const { path, url } = useRouteMatch();
  const [company, setCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    setupCompany();
  }, []);
  useEffect(() => {
    setupCompanyLogo();
  }, [company]);

  const setupCompany = async () => {
    const result = await getCurrentCompany();
    setCompany(makeCompany(result));
  };

  const setupCompanyLogo = async () => {
    if (!company) return;
    const result = await getCompanyLogo(company.id);
    if (result.deletedDate) return;
    setCompanyLogo(makeCompanyLogo(result));
  };

  const createPayPalOrder = async (data, actions) => {
    console.log('Creating order...', data, actions);
    const order = await createOrder();
    console.log(order);
    return order.id;
    // const order = await actions.order.create({
    //   purchase_units: [{
    //     amount: {
    //       value: 5.01 // Can reference variables or functions. Example: `value: document.getElementById('...').value`
    //     }
    //   }]
    // });
    // console.log(order);
    // return order.id;
  };

  const createPayPalSubscription = async (data, actions) => {
    console.log('Creating subscription...', data, actions);
    const subscriptionId = await createSubscription();
    console.log(subscriptionId);
    return subscriptionId;
  }

  const onPayPalApprove = async (data, actions) => {
      console.log('Approving order...', data, actions);

      await setupCompany();
  };

  const onPayPalCancel = (data) => {
      console.log('cancel');
      console.log(data);
      console.log(JSON.stringify(data, null, 2));
      //alert(data);
  };

  const onPayPalError = (err) => {
      console.log('error');
      console.log(err)
      console.log(JSON.stringify(err, null, 2));
      //alert(err);
  };

  const initialPayPalOptions = {
      "client-id": "ARBj6RP0IvV0B5VOO-eJesBqqB8Q5JOG0vfdcleqLmwJpzBMkEo6bb25S8w5MUF8YqDFK8FEn3LM6VHM",
      components: "buttons",
      intent: "subscription",
      vault: true
  };

  const years = Math.floor((company?.valveExerciseFrequencyMonths ?? 0) / 12);
  const months = (company?.valveExerciseFrequencyMonths ?? 0) % 12;
  const frequency = `${years} year${years === 1 ? '' : 's'} ${months} month${months === 1 ? '' : 's'}`;

  const phoneNumber = company?.phoneNumber ? `(${company.phoneNumber.substring(0, 3)}) ${company.phoneNumber.substring(3, 6)}-${company.phoneNumber.substring(6, company.phoneNumber.length)}` : '';

  return (
    <div id="company" className="page">
      <h2>{company?.name ?? "Company"}</h2>
      <div className="detail-page">
        {company ? (
          <>
            <div className="details">
              <LabelledTextField label="Name" text={company.name} />
              <LabelledTextField label="Street Address" text={company.streetAddress} />
              <LabelledTextField label="City" text={company.city} />
              <LabelledTextField label="State" text={company.state} />
              <LabelledTextField label="Zip Code" text={company.zipCode} />
              <LabelledTextField label="Phone Number" text={phoneNumber} />
              <LabelledImage label="Logo" value={companyLogo} />
              {/* <LabelledTextField label="Valve Exercise Frequency" text={frequency} /> */}
            </div>
            <div className="form-actions">
              <IsAuthorized>
                <Link to={`${url}/edit`} className='link'>Edit</Link>
              </IsAuthorized>
            </div>
            <IsAuthorized>
              <CompanySubscription />
            </IsAuthorized>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Company;
