import React from 'react';
import { dateOptions, dateTimeOptions } from '../../../utils/dates';

const HydrantSummary = ({ hydrant }) => {

  return (
    <tr>
      <td>
        <div className="record-summary">
          <div className="summary-details">
            <div className="summary-detail">
              <label>Device Type:</label>
              <span>{hydrant?.deviceType ?? 'Hydrant'}</span>
            </div>
            <div className="summary-detail">
              <label>Device ID:</label>
              <span>{hydrant?.name}</span>
            </div>
          </div>
          <div className="record-details">
            <div className="record-detail">
              <label>Address:</label>
              <span>{(hydrant?.streetNumber + ' ' + hydrant?.streetName).trim()}</span>
            </div>
            <div className="record-detail">
              <label>Cross Street:</label>
              <span>{hydrant?.crossStreet}</span>
            </div>
            <div className="record-detail">
              <label>Install Date:</label>
              <span>{hydrant.installDate ? new Date(hydrant.installDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="record-detail">
              <label>District:</label>
              <span>{hydrant?.district}</span>
            </div>
            <div className="record-detail">
              <label>Sub-Division:</label>
              <span>{hydrant?.subDivision}</span>
            </div>
            <div className="record-detail">
              <label>Manufacturer:</label>
              <span>{hydrant?.manufacturer}</span>
            </div>
          </div>
          <div className="record-stats">
            <div className="record-stat">
              <label>Exercise Date:</label>
              <span>{hydrant.lastMaintenanceDate ? new Date(hydrant.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''}</span>
            </div>
            <div className="record-stat">
              <label>Main Size:</label>
              <span>{hydrant?.mainSize}</span>
            </div>
            <div className="record-stat">
              <label>Total Revs:</label>
              <span>{hydrant?.totalRevolutions}</span>
            </div>
            <div className="record-stat">
              <label>Cycle Count:</label>
              <span>{hydrant?.autoCycles}</span>
            </div>
            <div className="record-stat">
              <label>Max Torque:</label>
              <span>{hydrant?.maxTorque}</span>
            </div>
            <div className="record-stat">
              <label>Revs @ Max Torque:</label>
              <span>{hydrant?.maxTorqueRevolutions}</span>
            </div>
            <div className="record-stat">
              <label>Max Rev In Set Dir:</label>
              <span>{hydrant?.maxDirectionRevolutions}</span>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
};

export default HydrantSummary;