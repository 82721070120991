import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrants = async (searchText) => {
  const result = await tryGet(`/hydrants?search=${searchText || ''}`);
  return result.data;
};

export const getHydrant = async (hydrantId) => {
  const result = await tryGet(`/hydrants/${hydrantId}`);
  return result.data;
};

export const getHydrantEdit = async (hydrantId) => {
  const result = await tryGet(`/hydrants/${hydrantId}/edit`);
  return result.data;
};

export const updateHydrant = async (hydrant) => {
  const result = await tryPut(`/hydrants`, hydrant);
  return result.data;
};

export const addHydrant = async (hydrant) => {
  const result = await tryPost(`/hydrants`, hydrant);
  return result.data;
};

export const deleteHydrant = async (hydrantId) => {
  const result = await tryDelete(`/hydrants/${hydrantId}`);
  return result.data;
};

export const deleteHydrants = async (hydrantIds) => {
  console.log(hydrantIds);
  const result = await tryDelete('/hydrants', { data: hydrantIds });
  return result.data;
};

export const importHydrants = async (file, mappings) => {
  var data = new FormData();
  data.append('file', file);
  data.append('mapping', JSON.stringify(mappings));
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/hydrants/import?timeZoneName=${timeZoneName}`, data, true);
  return result.data;
};

export const exportHydrants = async (mappings) => {
  console.log(mappings);
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/hydrants/export?timeZoneName=${timeZoneName}`, mappings);
  return result.data;
};