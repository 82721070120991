import React, { useRef, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import valveImg from '../../assets/images/valve.png';
import hydrantImg from '../../assets/images/fire-hydrant.png';
import ValveMapCard from '../valveMap/ValveMapCard';
import HydrantMapCard from '../hydrantMap/HydrantMapCard';
import { faEarthAmericas, faRoad, faSatellite } from '@fortawesome/free-solid-svg-icons';
import Search from '../Search';
import CircleButton from '../CircleButton';
import CircleButtonCollapse from '../CircleButtonCollapse';
import { addMonths } from '../../utils/dates';
import useAuth from '../../services/authentication/useAuth';
import { getCurrentCompany } from '../../services/api';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import globals from '../../utils/globals';
import useLocalStorage from '../../utils/useLocalStorage';

mapboxgl.accessToken = 'pk.eyJ1Ijoid3dlZ2Vua2UiLCJhIjoiY2t3NWl5eWRlMTZocTJvbW5xenFlcDFlMCJ9.TQud3faSsauFW-7URATL4Q';

const Map = ({ valves: paramValves, hydrants: paramHydrants, searchText, setSearchText, toggleUpload, toggleDownload }) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const auth = useAuth();
  const [company, setCompany] = useState(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapCards = useRef({});
  const [mapStyle, setMapStyle, removeMapStyle] = useLocalStorage('map_style', globals.STREETS_STYLE);
  const mapStyleRef = useRef(mapStyle);
  const [lng, setLng] = useState(-93.3426);
  const [lat, setLat] = useState(39.2455);
  const [zoom, setZoom] = useState(4.2);
  const [loadingMap, setLoadingMap] = useState(true);
  const [loadingMapValves, setLoadingMapValves] = useState(true);
  const [loadingMapHydrants, setLoadingMapHydrants] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedHydrantId, setSelectedHydrantId] = useState(null);
  const [currentBounds, setCurrentBounds] = useState(null);
  const [boundsTimeout, setBoundsTimeout] = useState(null);
  const [valves, setValves] = useState([]);
  const boundsRef = useRef(currentBounds);
  const filteredValves = paramValves.filter(v => v.latitude && v.longitude);
  const [hydrants, setHydrants] = useState([]);
  const filteredHydrants = paramHydrants.filter(v => v.latitude && v.longitude);
  const location = useLocation();

  useEffect(() => {
    setLoadingMap(loadingMapValves || loadingMapHydrants);
  }, [loadingMapValves, loadingMapHydrants])

  useEffect(() => {
    setupCompany();
  }, [auth.companyId, auth.user]);

  const setupCompany = async () => {
    if (auth.companyId) {
      const result = await getCurrentCompany();
      setCompany(result);
    }
  };

  useEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [location]);

  //Update valves to only those that are on the current map
  useEffect(() => {
    if (boundsTimeout == null) {
      setBoundsTimeout(setTimeout(() => {
        updateMapCards();
        setBoundsTimeout(null);
      }, 2000));
    }

    return () => {
      if (boundsTimeout !== null) {
        clearTimeout(boundsTimeout);
        setBoundsTimeout(null);
      }
    }
  }, [paramValves, paramHydrants, currentBounds]);

  //Scroll to selected item
  useEffect(() => {
    if (selectedItemId && mapCards.current) {
      if (!mapCards.current[selectedItemId]) {
        updateMapCards();
      } else if (!mapCards.current[selectedItemId].classList.contains('selected')) {
        for (const i in mapCards.current) {
          const mapCard = mapCards.current[i];
          mapCard?.classList.remove('selected');
        }
        mapCards.current[selectedItemId].classList.add('selected');
        mapCards.current[selectedItemId]?.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (mapCards.current) {
      for (const i in mapCards.current) {
        const mapCard = mapCards.current[i];
        mapCard?.classList.remove('selected');
      }
    }
  }, [mapCards.current, selectedItemId]);

  //Highlight item on map
  useEffect(() => {
    if (!loadingMap && map.current) {
      if (map.current.getLayer('valves-highlight'))
        map.current.setFilter('valves-highlight', ['==', 'id', selectedItemId || '']);
      if (map.current.getLayer('hydrants-highlight'))
        map.current.setFilter('hydrants-highlight', ['==', 'id', selectedItemId || '']);
    }
  }, [map.current, loadingMap, selectedItemId]);

  useEffect(() => {
    if (map.current && map.current.style !== mapStyle) {
      map.current.setStyle(mapStyle);
    }
  }, [mapStyle, map.current]);

  //Load map
  useEffect(() => {
    let shouldLoadData = false;
    if (!map.current) // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: [lng, lat],
        zoom: zoom
      });
    else
      shouldLoadData = true;

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    map.current.on('move', handleMapMove);
    map.current.on('click', 'valves-layer', handleMapItemsClick);
    map.current.on('click', 'valves-cluster', handleValvesClusterClick);
    map.current.on('click', 'hydrants-layer', handleMapItemsClick);
    map.current.on('click', 'hydrants-cluster', handleHydrantsClusterClick);
    map.current.on('click', handleMapClick);
    map.current.on('style.load', handleStyleLoad);
    map.current.on('styledata', handleStyleData);
    map.current.once('sourcedata', handleSourceData);

    if (shouldLoadData)
      loadData(map.current, filteredValves, filteredHydrants);

    return () => {
      map.current.off('click', 'valves-layer', handleMapItemsClick);
      map.current.off('click', 'valves-cluster', handleValvesClusterClick);
      map.current.off('click', 'hydrants-layer', handleMapItemsClick);
      map.current.off('click', 'hydrants-cluster', handleHydrantsClusterClick);
      map.current.off('click', handleMapClick);
      map.current.off('move', handleMapMove);
      map.current.off('style.load', handleStyleLoad);
      map.current.off('styledata', handleStyleData);
      map.current.off('sourcedata', handleSourceData);
    };
  }, [paramValves, paramHydrants]);

  const handleStyleLoad = (e) => {
    setLayerStyles(e.target, company, setLoadingMapValves, setLoadingMapHydrants);
  };

  const handleStyleData = (e) => {
    loadData(e.target, filteredValves, filteredHydrants);
  };

  const handleSourceData = (e) => {
    fitMapToData(e.target, filteredValves, filteredHydrants);
  };

  const fitMapToData = (map, valves, hydrants) => {
    if (valves.length > 0 || hydrants.length > 0) {
      const newBbox = { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
      const items = [...valves, ...hydrants];
      items.forEach(item => {
        if (item.longitude < newBbox.minX) {
          newBbox.minX = item.longitude;
        }
        if (item.longitude > newBbox.maxX) {
          newBbox.maxX = item.longitude;
        }
        if (item.latitude < newBbox.minY) {
          newBbox.minY = item.latitude;
        }
        if (item.latitude > newBbox.maxY) {
          newBbox.maxY = item.latitude;
        }
      });
      const bounds = [[newBbox.minX - 0.1, newBbox.minY - 0.1], [newBbox.maxX + 0.1, newBbox.maxY + 0.1]];
      map.fitBounds(bounds);
    }
  };

  const loadData = (map, valves, hydrants) => {
    const valveFeatureCollection = {
      type: 'FeatureCollection',
      features: valves?.map(getGeoJson),
    };
    const hydrantFeatureCollection = {
      type: 'FeatureCollection',
      features: hydrants?.map(getGeoJson),
    };
    map.getSource('valves')?.setData(valveFeatureCollection);
    map.getSource('hydrants')?.setData(hydrantFeatureCollection);
  };

  const setLayerStyles = (map, company, setLoadingValves, setLoadingHydrants) => {
    const exerciseFrequency = company?.valveExerciseFrequencyMonths ?? 60;
    const exerciseHalfLife = addMonths(-Math.round(exerciseFrequency / 2)).toISOString().split('T')[0];
    const exerciseExpiration = addMonths(-exerciseFrequency).toISOString().split('T')[0];
    setLoadingValves(true);
    map.loadImage(valveImg, (error, valveImage) => {
      if (error) throw error;
      if (!map.hasImage('valve'))
        map.addImage('valve', valveImage, {
          'sdf': true
        });

      const valveFeatureCollection = {
        type: 'FeatureCollection',
        features: valves.map(getGeoJson),
      };
      if (!map.getSource('valves'))
        map.addSource('valves', {
          type: 'geojson',
          data: valveFeatureCollection,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });
      
      if (!map.getLayer('valves-cluster'))
        map.addLayer({
          'id': 'valves-cluster',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['has', 'point_count'],
          'layout': {
            'icon-image': 'valve',
            'icon-size': 0.2,
            'icon-allow-overlap': true,
          }
        });

      if (!map.getLayer('valves-cluster-count'))
        map.addLayer({
          id: 'valves-cluster-count',
          type: 'symbol',
          source: 'valves',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 0.4]
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
      
      if (!map.getLayer('valves-layer'))
        map.addLayer({
          'id': 'valves-layer',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['!', ['has', 'point_count']],
          'layout': {
            'icon-image': 'valve',
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'icon-size': 0.18,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
          },
          'paint': {
            'icon-color': [
              "case", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", exerciseHalfLife]
              ], "#0000aa", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", exerciseExpiration]
              ], "#880088", 
              "#aa0000"
            ],
          },
        });
      
      if (!map.getLayer('valves-highlight'))
        map.addLayer({
          'id': 'valves-highlight',
          'type': 'symbol',
          'source': 'valves',
          'filter': ['==', 'id', ''],
          'layout': {
            'icon-image': 'valve',
            'icon-size': 0.18,
            'icon-allow-overlap': true,
          },
          'paint': {
            'icon-color': "#00cc00"
            // 'icon-color': [
            //   "case", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", exerciseHalfLife]
            //   ], "#0088ff", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", exerciseExpiration]
            //   ], "#dd66dd", 
            //   "#ff8800"
            // ],
          },
        });
      
      setValvePaintProperties(map, exerciseHalfLife, exerciseExpiration, mapStyle);

      setLoadingValves(false);
    });
    setLoadingHydrants(true);
    map.loadImage(hydrantImg, (error, hydrantImage) => {
      if (error) throw error;
      if (!map.hasImage('hydrant'))
        map.addImage('hydrant', hydrantImage, {
          'sdf': true
        });

      const hydrantFeatureCollection = {
        type: 'FeatureCollection',
        features: hydrants.map(getGeoJson),
      };

      if (!map.getSource('hydrants'))
        map.addSource('hydrants', {
          type: 'geojson',
          data: hydrantFeatureCollection,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });
      
      if (!map.getLayer('hydrants-cluster'))
        map.addLayer({
          'id': 'hydrants-cluster',
          'type': 'symbol',
          'source': 'hydrants',
          'filter': ['has', 'point_count'],
          'layout': {
            'icon-image': 'hydrant',
            'icon-size': 0.2,
            'icon-allow-overlap': true,
          }
        });

      if (!map.getLayer('hydrants-cluster-count'))
        map.addLayer({
          id: 'hydrants-cluster-count',
          type: 'symbol',
          source: 'hydrants',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 0.4]
          },
          paint: {
            "text-color": "#ffffff"
          }
        });
      
      const fireFlowHalfLife = exerciseHalfLife || addMonths(-30).toISOString().split('T')[0];
      const fireFlowExpiration = exerciseExpiration || addMonths(-60).toISOString().split('T')[0];
      if (!map.getLayer('hydrants-layer'))
        map.addLayer({
          'id': 'hydrants-layer',
          'type': 'symbol',
          'source': 'hydrants',
          'filter': ['!', ['has', 'point_count']],
          'layout': {
            'icon-image': 'hydrant',
            'text-anchor': 'top',
            'text-field': '{name}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'icon-size': 0.18,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
          },
          'paint': {
            'icon-color': [
              "case", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", fireFlowHalfLife]
              ], "#0000aa", [
                ">=", 
                ["to-string", ["get", "lastMaintenanceDate"]],
                ["to-string", fireFlowExpiration]
              ], "#880088", 
              "#aa0000"
            ],
            'text-color': '#ffffff',
            'text-halo-color': '#000000',
            'text-halo-width': 1
          },
        });
      
      if (!map.getLayer('hydrants-highlight'))
        map.addLayer({
          'id': 'hydrants-highlight',
          'type': 'symbol',
          'source': 'hydrants',
          'filter': ['==', 'id', ''],
          'layout': {
            'icon-image': 'hydrant',
            'icon-size': 0.18,
            'icon-allow-overlap': true,
          },
          'paint': {
            'icon-color': "#00cc00"
            // 'icon-color': [
            //   "case", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", fireFlowHalfLife]
            //   ], "#0088ff", [
            //     ">=", 
            //     ["to-string", ["get", "lastMaintenanceDate"]],
            //     ["to-string", fireFlowExpiration]
            //   ], "#dd66dd", 
            //   "#ff8800"
            // ],
          },
        });

      setHydrantPaintProperties(map, exerciseHalfLife, exerciseExpiration, mapStyle);

      setLoadingHydrants(false);
    });
  };

  const setValvePaintProperties = (map, exerciseHalfLife, exerciseExpiration) => {
    const valvesLayer = map.getLayer('valves-layer');
    if (valvesLayer) {
      map.setPaintProperty('valves-layer', 'icon-color', [
        "case", [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseHalfLife]
        ], mapLegend[0].color, [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseExpiration]
        ], mapLegend[1].color, 
        mapLegend[2].color
      ]);
      map.setPaintProperty('valves-layer', 'text-color', '#ffffff');
      map.setPaintProperty('valves-layer', 'text-halo-color', '#000000');
      map.setPaintProperty('valves-layer', 'text-halo-width', 1);
    }

    const valvesHighlightLayer = map.getLayer('valves-highlight');
    if (valvesHighlightLayer) {
      map.setPaintProperty('valves-highlight', 'icon-color', [
        "case", [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseHalfLife]
        ], mapLegend[0].highlight, [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseExpiration]
        ], mapLegend[1].highlight, 
        mapLegend[2].highlight
      ]);
      map.setPaintProperty('valves-highlight', 'text-color', '#ffffff');
      map.setPaintProperty('valves-highlight', 'text-halo-color', '#000000');
      map.setPaintProperty('valves-highlight', 'text-halo-width', 1);
    }
  };

  const setHydrantPaintProperties = (map, exerciseHalfLife, exerciseExpiration) => {
    const hydrantsLayer = map.getLayer('hydrants-layer');
    if (hydrantsLayer) {
      map.setPaintProperty('hydrants-layer', 'icon-color', [
        "case", [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseHalfLife]
        ], mapLegend[0].color, [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseExpiration]
        ], mapLegend[1].color, 
        mapLegend[2].color
      ]);
      map.setPaintProperty('hydrants-layer', 'text-color', '#ffffff');
      map.setPaintProperty('hydrants-layer', 'text-halo-color', '#000000');
      map.setPaintProperty('hydrants-layer', 'text-halo-width', 1);
    }

    const hydrantsHighlightLayer = map.getLayer('hydrants-highlight');
    if (hydrantsHighlightLayer) {
      map.setPaintProperty('hydrants-highlight', 'icon-color', [
        "case", [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseHalfLife]
        ], mapLegend[0].highlight, [
          ">=", 
          ["to-string", ["get", "lastMaintenanceDate"]],
          ["to-string", exerciseExpiration]
        ], mapLegend[1].highlight, 
        mapLegend[2].highlight
      ]);
      map.setPaintProperty('hydrants-highlight', 'text-color', '#ffffff');
      map.setPaintProperty('hydrants-highlight', 'text-halo-color', '#000000');
      map.setPaintProperty('hydrants-highlight', 'text-halo-width', 1);
    };
  };

  const updateMapCards = () => {
    mapCards.current = {};
    const valves = (filteredValves?.filter((v, i) =>
      v.id === selectedItemId || (
        v.latitude > (boundsRef.current?._sw.lat ?? -Infinity) && v.latitude < (boundsRef.current?._ne.lat ?? Infinity)
        && v.longitude > (boundsRef.current?._sw.lng ?? -Infinity) && v.longitude < (boundsRef.current?._ne.lng ?? Infinity)
      )
    ).filter((v, i) => v.id === selectedItemId || i < 20) ?? []);
    
    const hydrants = (filteredHydrants?.filter((v, i) =>
      v.id === selectedHydrantId || (
        v.latitude > (boundsRef.current?._sw.lat ?? -Infinity) && v.latitude < (boundsRef.current?._ne.lat ?? Infinity)
        && v.longitude > (boundsRef.current?._sw.lng ?? -Infinity) && v.longitude < (boundsRef.current?._ne.lng ?? Infinity)
      )
    ).filter((v, i) => v.id === selectedHydrantId || i < 20) ?? []);
    setValves(valves);
    setHydrants(hydrants);
  };

  const handleMapMove = () => {
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
    boundsRef.current = map.current.getBounds();
    setCurrentBounds(boundsRef.current);
  };

  const handleMapItemsClick = (e) => {
    e.clickOnLayer = true;
    const selectedId = e.features[0].properties.id;
    setSelectedItemId(selectedId);
    mapCards.current[selectedId]?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleValvesClusterClick = (e) => {
    e.clickOnLayer = true;
    const features = e.features;
    const clusterId = features[0].properties.cluster_id;
    map.current.getSource('valves').getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
       
      map.current.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom
      });
    });
  };

  const handleHydrantsClusterClick = (e) => {
    e.clickOnLayer = true;
    const features = e.features;
    const clusterId = features[0].properties.cluster_id;
    map.current.getSource('hydrants').getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
       
      map.current.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom
      });
    });
  };

  const handleMapClick = (e) => {
    if (e.clickOnLayer) return;
    setSelectedItemId(null);
  }

  const zoomTo = (id, coordinates) => {
    setSelectedItemId(id);
    map.current.easeTo({
      center: coordinates,
      zoom: 18,
    });
  };

  const handleStyleChange = (style) => {
    mapStyleRef.current = style;
    setMapStyle(style);
  };

  return (
    <div className="map-container">
      <div ref={mapContainer} className="map" />
      <div className="map-actions">
        {mapStyle === globals.SATELLITE_STYLE && <CircleButton icon={faMap} title="Street Map" onClick={() => handleStyleChange(globals.STREETS_STYLE)} />}
        {mapStyle === globals.STREETS_STYLE && <CircleButton icon={faEarthAmericas} title="Satellite" onClick={() => handleStyleChange(globals.SATELLITE_STYLE)} />}
      </div>
      <div className="map-legend">
        {mapLegend.map((key, idx) => (
          <div className="legend-key" key={idx}>
            <div className="legend-key-icon" style={{backgroundColor: key.color}}></div>
            <div className="legend-key-text">{key.text}</div>
          </div>
        ))}
      </div>
      <div className="map-list">
        <div className="map-list-actions">
          <Search searchText={searchText} setSearchText={setSearchText} />
          <CircleButtonCollapse>
            <CircleButton img={valveImg} type="primary" title="View Valves" onClick={() => history.push(`valves`)} />
            <CircleButton img={hydrantImg} type="primary" title="View Hydrants" onClick={() => history.push(`hydrants`)} />
          </CircleButtonCollapse>
        </div>
        <div className="map-cards">
          {valves?.map((v, i) => <ValveMapCard valve={v} key={i} zoomTo={zoomTo} ref={(element) => mapCards.current[v.id] = element} />)}
          {hydrants?.map((h, i) => <HydrantMapCard hydrant={h} key={i} zoomTo={zoomTo} ref={(element) => mapCards.current[h.id] = element} />)}
        </div>
      </div>
    </div>
  );
};

const getGeoJson = (object, idField = 'id') => {
  const geoJson = {
    type: 'Feature',
    id: object[idField],
    properties: {},
    geometry: {
        type: 'Point',
        coordinates: [object.longitude, object.latitude],
    },
  };
  for (const property in object) {
    if (property === 'Latitude' || property === 'Longitude') {
      continue;
    }

    geoJson.properties[property] = object[property];
  }

  return geoJson;
};

const mapLegend = [
  {
    color: '#0000aa',
    highlight: '#00cc00',
    text: 'Exercised'
  },
  {
    color: '#880088',
    highlight: '#00cc00',
    text: 'Exercise Half Life'
  },
  {
    color: '#aa0000',
    highlight: '#00cc00',
    text: 'Exercise Needed'
  }
]

export default Map;