import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import useAuth from '../../services/authentication/useAuth';
import Pill from '../Pill';
import CircleButton from '../CircleButton';

const UserTableRow = ({ user, onEdit }) => {
  const { path, url } = useRouteMatch();
  const auth = useAuth();
  const roles = user.companyUsers?.filter(cu => cu.company.id === auth.companyId).map((cu, idx) => <Pill key={idx} content={cu.role.roleName} />);
  return (
    <tr id={user.id}>
      <td>{user.email}</td>
      <td className="roles">{roles}</td>
      <td>
        <Link to={`users/${user.id}/editRoles`}>
          <CircleButton icon={faEdit} type="primary" title="Edit" />
        </Link>
        {/* <div className="link" onClick={() => onEdit(user.id)}>Edit Roles</div> */}
      </td>
    </tr>
  );
};

export default UserTableRow;