import React from 'react';

const LabelledCheckBox = ({ label, type, value, name, onChange, onBlur }) => {

  const onCheckChange = (e) => {
    onChange({
      ...e,
      value: e.target.checked,
      name: e.target.name,
      type: e.target.type,
    });
  };

  const onCheckBlur = (e) => {
    onBlur({
      ...e,
      value: e.target.checked,
      name: e.target.name,
      type: e.target.type,
    });
  };

  return (
    <div className="labelled-check-box">
      <span className="label">{label}</span>
      <input type={type ? type : 'checkbox'} checked={value ?? false}
        name={name}
        value={value}
        onChange={onCheckChange}
        onBlur={onCheckBlur}
        placeholder={label}
      />
    </div>
  );
};

export default LabelledCheckBox;
