import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextBox from '../../components/LabelledTextBox';
import { dateOptions } from '../../utils/dates';
import LabelledPicker from '../../components/LabelledPicker';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import LabelledTextBoxCheck from '../../components/LabelledTextBoxCheck';
import LabelledPickerCheck from '../../components/LabelledPickerCheck';
import { getDefaultHydrantExerciseSettings, getHydrantExerciseSetting, updateHydrantExerciseSetting } from '../../services/api';
import { makeDefaultHydrantExerciseSettings, makeHydrantExerciseSettings } from '../../models';
import EventEmitter from '../../services/events';
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { getDefaultHydrantExerciseSettingsByHydrantId } from '../../services/api';
import CircleButton from '../../components/CircleButton';

const EditHydrantExerciseSettings = ({ onUpdate }) => {
  const [exerciseSettings, setExerciseSettings] = useState(null);
  const [defaultExerciseSettings, setDefaultExerciseSettings] = useState(null);
  const { path, url } = useRouteMatch();
  const { hydrantId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExerciseSettings();
    setupDefaultExerciseSettings();
  }, [hydrantId, history.location]);

  const setupExerciseSettings = async () => {
    const result = await getHydrantExerciseSetting(hydrantId);
    setExerciseSettings(makeHydrantExerciseSettings(result));
  };

  const setupDefaultExerciseSettings = async () => {
    const result = await getDefaultHydrantExerciseSettingsByHydrantId(hydrantId);
    setDefaultExerciseSettings(makeDefaultHydrantExerciseSettings(result));
  };

  const save = async () => {
    try {
      exerciseSettings.validate();
      const result = await updateHydrantExerciseSetting(exerciseSettings);
      onUpdate(hydrantId);
      history.replace(`/hydrants/${hydrantId}/hydrantExerciseSettings`);
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Exercise Settings', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Hydrant Exercise Settings', body: 'Unable to save hydrant exercise settings.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const setToDefault = async () => {
    setExerciseSettings({ ...exerciseSettings.unlock(), ...defaultExerciseSettings });
  };

  const onChange = e => {
    console.log(e);
    const type = e.type;
    let value = e.value;
    let name = e.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }
    if (type === 'checkbox') {
      value = !!e.value;
    }

    let checked = !!e.checked;
    if (checked !== exerciseSettings[e.checkName]) { //Check change
      if (!checked && defaultExerciseSettings) {
        value = defaultExerciseSettings[e.name] || value;
      }
    } else {
      checked = true;
    }
    
    setExerciseSettings(makeHydrantExerciseSettings({ ...exerciseSettings, [e.checkName]: checked, [name]: value }));
  };

  const onBlur = e => {
    const type = e.type;
    let value = e.value;
    let name = e.name;
    if (name === 'exerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'exerciseFrequencyMonths';
    } else if (name === 'exerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'exerciseFrequencyMonths';
    }

    switch (type) {
      case "number":
        value = +value;
        break;
      case "checkbox":
        value = value === 'true';
        break;
    }

    let checked = !!e.checked;
    if (checked !== exerciseSettings[e.checkName]) { //Check change
      if (!checked && defaultExerciseSettings) {
        value = defaultExerciseSettings[e.name] || value;
      }
    } else {
      checked = true;
    }
    
    setExerciseSettings(makeHydrantExerciseSettings({ ...exerciseSettings, [e.checkName]: checked, [name]: value }));
  };

  const years = Math.floor(exerciseSettings?.exerciseFrequencyMonths / 12);
  const months = exerciseSettings?.exerciseFrequencyMonths % 12;
  
  return (
    <div>
      <h3>Edit Exercise Settings</h3>
      {exerciseSettings ? (
        <>
          <div className="details">
            <LabelledTextBoxCheck type="number" label="Torque Set Point" value={exerciseSettings.torqueSetPoint} name="torqueSetPoint" onChange={onChange} onBlur={onBlur}
              checkName="torqueSetPointLocked" checkTitle={exerciseSettings.torqueSetPointLocked ? "Update with default settings" : "Stop updating with default settings"}
              checked={exerciseSettings.torqueSetPointLocked} checkedIcon={faUnlink} uncheckedIcon={faLink} />
            <LabelledTextBoxCheck type="number" label="RPM Set Point" value={exerciseSettings.rpmSetPoint} name="rpmSetPoint" onChange={onChange} onBlur={onBlur}
              checkName="rpmSetPointLocked" checkTitle={exerciseSettings.rpmSetPointLocked ? "Update with default settings" : "Stop updating with default settings"}
              checked={exerciseSettings.rpmSetPointLocked} />
            <LabelledPickerCheck label="Open Direction" value={exerciseSettings.openDirection} name="openDirection" onChange={onChange} onBlur={onBlur}
              checkName="openDirectionLocked" checkTitle={exerciseSettings.openDirectionLocked ? "Update with default settings" : "Stop updating with default settings"}
              checked={exerciseSettings.openDirectionLocked} >
              <option value=""></option>
              <option value="Right">Right</option>
              <option value="Left">Left</option>
            </LabelledPickerCheck>
            <LabelledPickerCheck label="Current State" value={exerciseSettings.currentState} name="currentState" onChange={onChange} onBlur={onBlur}
              checkName="currentStateLocked" checkTitle={exerciseSettings.currentStateLocked ? "Update with default settings" : "Stop updating with default settings"}
              checked={exerciseSettings.currentStateLocked} >
              <option value=""></option>
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </LabelledPickerCheck>
            <LabelledTextBoxCheck type="number" label="Revolutions" value={exerciseSettings.revolutions} name="revolutions" onChange={onChange} onBlur={onBlur}
              checkName="revolutionsLocked" checkTitle={exerciseSettings.revolutionsLocked ? "Update with default settings" : "Stop updating with default settings"}
              checked={exerciseSettings.revolutionsLocked} />
            <LabelledTextBoxCheck type="number" label="Stuck Revs" value={exerciseSettings.stuckRevs} name="stuckRevs" onChange={onChange} onBlur={onBlur}
              checkName="stuckRevsLocked" checked={exerciseSettings.stuckRevsLocked}
              checkTitle={exerciseSettings.stuckRevsLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Stuck Attempts" value={exerciseSettings.stuckAttempts} name="stuckAttempts" onChange={onChange} onBlur={onBlur}
              checkName="stuckAttemptsLocked" checked={exerciseSettings.stuckAttemptsLocked}
              checkTitle={exerciseSettings.stuckAttemptsLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Open Recess" value={exerciseSettings.openRecess} name="openRecess" onChange={onChange} onBlur={onBlur}
              checkName="openRecessLocked" checked={exerciseSettings.openRecessLocked}
              checkTitle={exerciseSettings.openRecessLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Close Recess" value={exerciseSettings.closeRecess} name="closeRecess" onChange={onChange} onBlur={onBlur}
              checkName="closeRecessLocked" checked={exerciseSettings.closeRecessLocked}
              checkTitle={exerciseSettings.closeRecessLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Flush Revolutions" value={exerciseSettings.flushRevolutions} name="flushRevolutions" onChange={onChange} onBlur={onBlur}
              checkName="flushRevolutionsLocked" checked={exerciseSettings.flushRevolutionsLocked}
              checkTitle={exerciseSettings.flushRevolutionsLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Flush Cycles" value={exerciseSettings.flushCycles} name="flushCycles" onChange={onChange} onBlur={onBlur}
              checkName="flushCyclesLocked" checked={exerciseSettings.flushCyclesLocked}
              checkTitle={exerciseSettings.flushCyclesLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Hardstop Speed" value={exerciseSettings.hardstopSpeed} name="hardstopSpeed" onChange={onChange} onBlur={onBlur}
              checkName="hardstopSpeedLocked" checked={exerciseSettings.hardstopSpeedLocked}
              checkTitle={exerciseSettings.hardstopSpeedLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <LabelledTextBoxCheck type="number" label="Auto Cycles" value={exerciseSettings.autoCycles} name="autoCycles" onChange={onChange} onBlur={onBlur}
              checkName="autoCyclesLocked" checked={exerciseSettings.autoCyclesLocked}
              checkTitle={exerciseSettings.autoCyclesLocked ? "Update with default settings" : "Stop updating with default settings"} />
            <div className="labelled-text-box">
              <span className="label">Hydrant Exercise Frequency</span>
              <div className="input">
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Years</span>
                  <input type="number" value={years?.toString() ?? ''}
                    name="exerciseFrequencyYears"
                    onChange={e => {
                      const change = {
                        value: e.target.value,
                        name: e.target.name,
                        type: e.target.type,
                        checked: exerciseSettings.exerciseFrequencyMonthsLocked,
                        checkName: 'exerciseFrequencyMonthsLocked'
                      };
                      onChange(change);
                    }}
                    onBlur={e => {
                      const change = {
                        value: e.target.value,
                        name: e.target.name,
                        type: e.target.type,
                        checked: exerciseSettings.exerciseFrequencyMonthsLocked,
                        checkName: 'exerciseFrequencyMonthsLocked'
                      };
                      onBlur(change);
                    }}
                    placeholder="Hydrant Exercise Frequency (Years)"
                  />
                </div>
                <div className="labelled-text-box-partial">
                  <span className="label-partial">Months</span>
                  <input type="number" value={months?.toString() ?? ''}
                    name="exerciseFrequencyMonths"
                    onChange={e => {
                      const change = {
                        value: e.target.value,
                        name: e.target.name,
                        type: e.target.type,
                        checked: exerciseSettings.exerciseFrequencyMonthsLocked,
                        checkName: 'exerciseFrequencyMonthsLocked'
                      };
                      onChange(change);
                    }}
                    onBlur={e => {
                      const change = {
                        value: e.target.value,
                        name: e.target.name,
                        type: e.target.type,
                        checked: exerciseSettings.exerciseFrequencyMonthsLocked,
                        checkName: 'exerciseFrequencyMonthsLocked'
                      };
                      onBlur(change);
                    }}
                    placeholder="Hydrant Exercise Frequency (Months)"
                  />
                </div>
              </div>
              <CircleButton icon={exerciseSettings.exerciseFrequencyMonthsLocked ? faUnlink : faLink}
                title={exerciseSettings.exerciseFrequencyMonthsLocked ? "Update with default settings" : "Stop updating with default settings"}
                onClick={() => {
                  const change = {
                    value: exerciseSettings.exerciseFrequencyMonths,
                    name: 'exerciseFrequencyMonths',
                    type: 'number',
                    checked: !exerciseSettings.exerciseFrequencyMonthsLocked,
                    checkName: 'exerciseFrequencyMonthsLocked'
                  };
                  onChange(change);
                }} />
            </div>
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-link link" onClick={setToDefault}>Set to Default</button>
            <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={save}>Save</button>
          </div>
        </>
      ) : (
        <span className="spinner"></span>
      )}
    </div>
  );
};

export default EditHydrantExerciseSettings;
