import React from 'react';
import { dateOptions } from '../../utils/dates';

const ValveExerciseTableRow = ({ exercise, onSelect }) => {  
  return (
    <tr id={exercise.id}>
      <td>{exercise.testDate ? new Date(exercise.testDate).toLocaleDateString('en-US', dateOptions) : ''}</td>
      <td>{exercise.nozzlePSI}</td>
      <td>{exercise.staticPSI}</td>
      <td>{exercise.residualPSI}</td>
      <td>{exercise.percentDrop}</td>
      <td>{exercise.totalGallonsUsed}</td>
      <td>{exercise.maxGPM}</td>
      <td>{exercise.elapsedTime}</td>
      <td className='actions'>
        <div className='link' onClick={() => onSelect(exercise.id)}>Select</div>
      </td>
    </tr>
  );
};

export default ValveExerciseTableRow;