import axios from 'axios';
import { refreshToken } from './account';

export const getAxios = (formData = false) => {
  const instance = axios.create({
    baseURL: '/api',
    withCredentials: true,
  });
  if (!formData) {
    instance.defaults.headers.common['content-type'] = 'application/json';
  }

  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  
  const companyId = localStorage.getItem('company_id');
  if (companyId) {
    instance.defaults.headers.common['CompanyId'] = companyId;
  }

  return instance;
};

export const tryGet = async (url, body) => {
  try {
    return await getAxios().get(url, body);
  } catch (ex) {
    if (ex.response.status === 401 || ex.response.status === 403) {
      await refreshToken();
      return await getAxios().get(url, body);
    } else {
      throw ex;
    }
  }
};

export const tryPost = async (url, body, formData = false) => {
  try {
    return await getAxios(formData).post(url, body);
  } catch (ex) {
    if (ex.response.status === 401 || ex.response.status === 403) {
      await refreshToken();
      return await getAxios(formData).post(url, body);
    } else {
      throw ex;
    }
  }
};

export const tryPut = async (url, body, formData = false) => {
  try {
    return await getAxios(formData).put(url, body);
  } catch (ex) {
    if (ex.response.status === 401 || ex.response.status === 403) {
      await refreshToken();
      return await getAxios().put(url, body);
    } else {
      throw ex;
    }
  }
};

export const tryDelete = async (url, body) => {
  try {
    return await getAxios().delete(url, body);
  } catch (ex) {
    if (ex.response.status === 401 || ex.response.status === 403) {
      await refreshToken();
      return await getAxios().delete(url, body);
    }
  }
};

export { login, register, logout, getCurrentUser, refreshToken, sendEmailConfirmation, confirmEmail, updatePassword, forgotPassword, resetPassword } from './account';
export { getUsers, getUser, getUserEdit, updateUser, addUser, updateUserRoles, addUserRoles } from './users';
export { getRoles } from './roles';
export { getUserInvites, inviteUser, uninviteUser } from './userInvites';
export { getValves, getValve, getValveEdit, updateValve, addValve, importValves, exportValves, deleteValve, deleteValves } from './valves';
export { getValveExerciseSettings, getValveExerciseSetting, getValveExerciseSettingEdit, updateValveExerciseSetting, addValveExerciseSetting, importValveExerciseSettings, exportValveExerciseSettings } from './valveExerciseSettings';
export { getCompanies, getCompany, getCompanyEdit, updateCompany, addCompany, getCurrentCompany } from './companies';
export { getCompanyLogos, getCompanyLogo, getCompanyLogoEdit, updateCompanyLogo, addCompanyLogo, getCurrentCompanyLogo, deleteCompanyLogo } from './companyLogos';
export { getValveExercises, getValveExercise, getValveExerciseLatest, getValveExerciseEdit, updateValveExercise, addValveExercise, deleteValveExercise, exportAllValveExerciseResults, exportValveExerciseResults } from './valveExercises';
export { getAllDefaultValveExerciseSettings, getDefaultValveExerciseSettings, getDefaultValveExerciseSettingsEdit, updateDefaultValveExerciseSettings, addDefaultValveExerciseSettings, deleteDefaultValveExerciseSettings, getDefaultValveExerciseSettingsByValveId,getDefaultValveExerciseSettingsByValveSize } from './defaultValveExerciseSettings';
export { createOrder, captureOrder, createSubscription, cancelSubscription, getSubscription, getSubscriptionStatus, getPlans, getPlan, getClientId } from './payPal';
export { getHydrants, getHydrant, getHydrantEdit, updateHydrant, addHydrant, importHydrants, exportHydrants, deleteHydrant, deleteHydrants } from './hydrants';
export { getFireFlowTests, getFireFlowTest, getFireFlowTestLatest, getFireFlowTestEdit, updateFireFlowTest, addFireFlowTest, deleteFireFlowTest } from './fireFlowTests';
export { getHydrantManufacturers, getHydrantManufacturer, getHydrantManufacturerEdit, updateHydrantManufacturer, addHydrantManufacturer, deleteHydrantManufacturer, deleteHydrantManufacturers } from './hydrantManufacturers';
export { getHydrantModels, getHydrantModel, getHydrantModelEdit, updateHydrantModel, addHydrantModel, deleteHydrantModel, deleteHydrantModels } from './hydrantModels';
export { getHydrantImages, getHydrantImage, getHydrantImageEdit, updateHydrantImage, addHydrantImage, deleteHydrantImage, deleteHydrantImages } from './hydrantImages';
export { getAllDefaultHydrantExerciseSettings, getDefaultHydrantExerciseSettings, getDefaultHydrantExerciseSettingsEdit, updateDefaultHydrantExerciseSettings, addDefaultHydrantExerciseSettings, deleteDefaultHydrantExerciseSettings, getDefaultHydrantExerciseSettingsByHydrantId,getDefaultHydrantExerciseSettingsByHydrantSize } from './defaultHydrantExerciseSettings';
export { getHydrantExerciseSettings, getHydrantExerciseSetting, getHydrantExerciseSettingEdit, updateHydrantExerciseSetting, addHydrantExerciseSetting, importHydrantExerciseSettings, exportHydrantExerciseSettings } from './hydrantExerciseSettings';
export { getHydrantExercises, getHydrantExercise, getHydrantExerciseLatest, getHydrantExerciseEdit, updateHydrantExercise, addHydrantExercise, deleteHydrantExercise } from './hydrantExercises';
export { exportExerciseResults } from './reports';