import { faPrint } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { getValves, getHydrants, getCurrentCompany, getCompanyLogo  } from '../../../services/api';
import ValveSummary from './ValveSummary';
import HydrantSummary from './HydrantSummary';
import CircleButton from '../../../components/CircleButton';
import hurcoLogoImg from '../../../assets/images/HurcoLogo.png';
import LabelledTextBox from '../../../components/LabelledTextBox';
import { makeValve, makeCompany, makeCompanyLogo, makeHydrant } from '../../../models';
import { addDays } from '../../../utils/dates';
import LabelledPicker from '../../../components/LabelledPicker';

const SummaryReport = () => {
  const [valves, setValves] = useState([]);
  const [hydrants, setHydrants] = useState([]);
  const [filteredValves, setFilteredValves] = useState([]);
  const [filteredHydrants, setFilteredHydrants] = useState([]);
  const [company, setCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingValves, setLoadingValves] = useState(true);
  const [loadingHydrants, setLoadingHydrants] = useState(true);
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    deviceType: 'all'
  });

  useEffect(() => {
    setupCompany();
    setupValves();
    setupHydrants();
  }, []);
  useEffect(() => {
    setupCompanyLogo();
  }, [company]);

  useEffect(() => {
    filterValves();
    filterHydrants();
  }, [filter, valves, hydrants]);

  useEffect(() => {
    setLoading(loadingCompany || loadingValves || loadingHydrants);
  }, [loadingCompany, loadingValves, loadingHydrants]);

  const setup = async () => {
    try {
      setLoading(true);
      await setupCompany();
      await setupValves();
      await setupHydrants();
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  }

  const setupCompany = async () => {
    try {
      setLoadingCompany(true);
      const fetchedCompany = await getCurrentCompany();
      setCompany(makeCompany(fetchedCompany));
      setLoadingCompany(false);
    } catch (ex) {
      console.log(ex);
      setLoadingCompany(false);
    }
  };

  const setupCompanyLogo = async () => {
    if (!company) return;
    const result = await getCompanyLogo(company.id);
    if (result.deletedDate) return;
    setCompanyLogo(makeCompanyLogo(result));
  };

  const setupValves = async () => {
    try {
      setLoadingValves(true);
      const fetchedValves = await getValves();
      const mappedValves = fetchedValves.map(v => makeValve(v));
      setValves(mappedValves);
      setLoadingValves(false);
    } catch (ex) {
      console.log(ex);
      setLoadingValves(false);
    }
  };

  const setupHydrants = async () => {
    try {
      setLoadingHydrants(true);
      const fetchedHydrants = await getHydrants();
      const mappedHydrants = fetchedHydrants.map(v => makeHydrant(v));
      setHydrants(mappedHydrants);
      setLoadingHydrants(false);
    } catch (ex) {
      console.log(ex);
      setLoadingHydrants(false);
    }
  };

  const filterValves = () => {
    const filtered = valves.filter(v => {
      return (filter.deviceType === 'valves' || filter.deviceType === 'all')
        && v.lastMaintenanceDate
        && (!filter.startDate || new Date(v.lastMaintenanceDate) >= new Date(filter.startDate))
        && (!filter.endDate || new Date(v.lastMaintenanceDate) < addDays(1, new Date(filter.endDate)));
    });
    setFilteredValves(filtered);
  };

  const filterHydrants = () => {
    const filtered = hydrants.filter(v => {
      return (filter.deviceType === 'hydrants' || filter.deviceType === 'all')
        && v.lastMaintenanceDate
        && (!filter.startDate || new Date(v.lastMaintenanceDate) >= new Date(filter.startDate))
        && (!filter.endDate || new Date(v.lastMaintenanceDate) < addDays(1, new Date(filter.endDate)));
    });
    setFilteredHydrants(filtered);
  };

  const onChange = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value + ' 0:0');
      //   break;
    }
    setFilter({ ...filter, [e.target.name]: value });
  };

  const onBlur = e => {
    console.log(e.target.type);
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value + ' 0:0');
      //   break;
    }

    setFilter({ ...filter, [e.target.name]: value });
  };

  const print = () => {
    window.print();
  };

  const filteredRecords = [ ...filteredValves.map(v => { return { deviceType: 'Valve', ...v }}), ...filteredHydrants.map(h => { return { deviceType: 'Hydrant', ...h }}) ];
  const sortedRecords = filteredRecords.sort((a, b) => a.exerciseDate >= b.exerciseDate ? 1 : -1);
  const recordSummaries = sortedRecords.map((r, idx) => r.deviceType === 'Valve' ? <ValveSummary key={idx} valve={r} /> : <HydrantSummary key={idx} hydrant={r} />);

  // const valveSummaries = filteredValves.map((v, idx) => <ValveSummary key={idx} valve={v} />);
  let phoneNumber = company?.phoneNumber ? `(${company?.phoneNumber.substring(0, 3)}) ${company?.phoneNumber.substring(3, 6)}-${company?.phoneNumber.substring(6, company?.phoneNumber.length)}` : null;
  phoneNumber = phoneNumber?.replace(/[\(\)\s-]+$/, '');

  return (
    <div className="report summary-reprot">
      {loading && <div className="spinner"></div>}
      <div className="report-filter no-print">
        <LabelledTextBox type="date" label="Start Date" value={filter.startDate} name="startDate" onChange={onChange} onBlur={onBlur}/>
        <LabelledTextBox type="date" label="End Date" value={filter.endDate} name="endDate" onChange={onChange} onBlur={onBlur}/>
        <LabelledPicker label="Device Type" value={filter.deviceType} name="deviceType" onChange={onChange} onBlur={onBlur}>
          <option value="all">All</option>
          <option value="valves">Valves</option>
          <option value="hydrants">Hydrants</option>
        </LabelledPicker>
      </div>
      <div className="report-title">
        <h2>Valve Exercise Summary Report</h2>
        {loading || <CircleButton icon={faPrint} size="large" title="Print" onClick={print} className="no-print" />}
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <td>
              <div className="report-header">
                {companyLogo ?
                  <div className="header-logo">
                    <img src={`${companyLogo.dataType},${companyLogo.image}`} alt={companyLogo.label} />
                  </div>
                : null}
                {company ?
                  <div className="header-details">
                    <div className="header-detail">
                      <span>{company?.name}</span>
                    </div>
                    <div className="header-detail">
                      <span>{company?.streetAddress}</span>
                    </div>
                    <div className="header-detail">
                      <span>{company?.city}{company?.city && company?.state ? ',' : null} </span>
                      <span>{company?.state} </span> 
                      <span>{company?.zipCode}</span>
                    </div>
                    {phoneNumber ?
                      <div className="header-detail">
                        <span>{phoneNumber}</span>
                      </div>
                    : null}
                  </div>
                  : null}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {recordSummaries}
          <tr>
            <td>
              <div className="report-summary">
                <div className="summary-details">
                  <div className="summary-detail">
                    <label>Devices Exercised:</label>
                    <span>{filteredValves.length}</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SummaryReport;