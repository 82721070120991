import React, { useState, useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { faMapMarkedAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import Search from '../../components/Search';
import CircleButton from '../../components/CircleButton';
import useAuth from '../../services/authentication/useAuth';
import DefaultHydrantExerciseSettingsRow from '../../components/defaultHydrantExerciseSettings/DefaultHydrantExerciseSettingsRow';
import { getAllDefaultHydrantExerciseSettings, deleteDefaultHydrantExerciseSettings } from '../../services/api';

const DefaultHydrantExerciseSettings = () => {
  const [defaultHydrantExerciseSettings, setDefaultHydrantExerciseSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { path, url } = useRouteMatch();
  const { companyId } = useAuth();

  useEffect(() => {
    setupDefaultSettings();
  }, []);

  const setupDefaultSettings = async () => {
    setLoading(true);
    const result = await getAllDefaultHydrantExerciseSettings();
    console.log(result);
    const resultSorted = result.sort((a, b) => a.hydrantSize <= b.hydrantSize ? -1 : 1);
    setDefaultHydrantExerciseSettings(resultSorted);
    setLoading(false);
  };

  const onDelete = async id => {
    await deleteDefaultHydrantExerciseSettings(id);
    await setupDefaultSettings();
  };

  const rows = defaultHydrantExerciseSettings?.map((defaultSettings, index) => <DefaultHydrantExerciseSettingsRow key={index} defaultSettings={defaultSettings} onDelete={onDelete} />);

  return (
    <div id="defaultHydrantExerciseSettings" className="list">
      <h2>Default Hydrant Exercise Settings</h2>
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Hydrant Model</th>
              <th>Torque Set Point</th>
              <th>RPM Set Point</th>
              <th>Current State</th>
              <th>Open Direction</th>
              <th>Revolutions</th>
              <th className='command-row'>
                <Link to={'defaultHydrantExerciseSettings/add'}>
                  <CircleButton icon={faPlus} type="success" title="Add Default Hydrant Exercise Settings" />
                </Link>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DefaultHydrantExerciseSettings;