import React, { useState } from 'react';
import useAuth from '../services/authentication/useAuth';
import LabelledTextField from '../components/LabelledTextField';
import LabelledTextBox from '../components/LabelledTextBox';
import { updatePassword } from '../services/api';
import EventEmitter from '../services/events';

const Account = () => {
  const [credentials, setCredentials] = useState({});
  const [passwordError, setPasswordError] = useState(null);
  const [changingPassword, setChangingPassword] = useState(null);
  const auth = useAuth();

  const onChange = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onBlur = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const changePassword = async () => {
    try {
      setPasswordError(null);
      setChangingPassword(true);
      if (credentials.password !== credentials.confirmPassword) {
        setPasswordError("Password and Confirm Password don't match");
        return;
      }
      if (credentials.password.length < 6) {
        setPasswordError("Password must be at least 6 characters long");
        return;
      }
      if (!/[a-z]/.test(credentials.password)) {
        setPasswordError('Password must contain a lower case letter');
        return;
      }
      if (!/[A-Z]/.test(credentials.password)) {
        setPasswordError('Password must contain an upper case letter');
        return;
      }
      if (!/[0-9]/.test(credentials.password)) {
        setPasswordError('Password must contain a number');
        return;
      }
      if (!/[!@#$%^&*]/.test(credentials.password)) {
        setPasswordError('Password must contain a symbol: ! @ # $ % ^ & *');
        return;
      }

      await updatePassword({ password: credentials.password });
      setCredentials({});
      
      EventEmitter.emit('show-notification', 'Password Changed Successfully');

    } catch (ex) {
      setPasswordError('Unable to update password');
    } finally {
      setChangingPassword(false);
    }
  };

  return (
    <div>
      <h2>Account</h2>
      <div className="user-details">
        <LabelledTextField label="Email" text={auth?.user.email} />
      </div>
      <h3>Change Password</h3>
      <div className="update-password">
        {passwordError && <div className="error center-text">{passwordError}</div> }
        <LabelledTextBox type="password" label="Password" value={credentials.password} name="password" onChange={onChange} onBlur={onBlur} />
        <LabelledTextBox type="password" label="Confirm Password" value={credentials.confirmPassword} name="confirmPassword" onChange={onChange} onBlur={onBlur} />
      </div>
      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={changePassword} disabled={changingPassword}>Change Password</button>
      </div>
      {changingPassword && <div className="spinner"></div>}
    </div>
  );
};

export default Account;