export default function buildMakeHydrantModel({ validate, validators }) {
  return function makeHydrantModel({
    id,
    companyId,
    name,
    address,
    address2,
    city,
    state,
    zipCode,
    phone,
    mobilePhone,
    fax,
    email,
    website,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      companyId: {
        prettyName: 'Company ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
      name: {
        prettyName: "Name",
        validators: [validators.isString(), validators.maxLength(100)],
      },
      address: {
        prettyName: "Address",
        validators: [validators.isString(), validators.maxLength(100)],
      },
      address2: {
        prettyName: "Address 2",
        validators: [validators.isString(), validators.maxLength(100)],
      },
      city: {
        prettyName: "City",
        validators: [validators.isString(), validators.maxLength(50)],
      },
      state: {
        prettyName: "State",
        validators: [validators.isString(), validators.maxLength(2)],
      },
      zipCode: {
        prettyName: "Zip Code",
        validators: [validators.isString(), validators.maxLength(15)],
      },
      phone: {
        prettyName: "Phone",
        validators: [validators.isString(), validators.maxLength(15)],
      },
      mobilePhone: {
        prettyName: "Mobile Phone",
        validators: [validators.isString(), validators.maxLength(15)],
      },
      fax: {
        prettyName: "Fax",
        validators: [validators.isString(), validators.maxLength(15)],
      },
      email: {
        prettyName: "Email",
        validators: [validators.isString(), validators.maxLength(255)],
      },
      website: {
        prettyName: "Website",
        validators: [validators.isString(), validators.maxLength(255)],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get name() { return name },
      get address() { return address },
      get address2() { return address2 },
      get city() { return city },
      get state() { return state },
      get zipCode() { return zipCode },
      get phone() { return phone },
      get mobilePhone() { return mobilePhone },
      get fax() { return fax },
      get email() { return email },
      get website() { return website },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}