import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { ValveExerciseSettings, TorqueLogs, EditValveExerciseSettings, ValveExercise } from './';
import { Aside, ErrorBoundaryRoute } from '../components/Layout';
import EditValve from './EditValve';
import DetailValve from './DetailValve';
import axios from 'axios';
import LabelledTextField from '../components/LabelledTextField';
import { dateOptions } from '../utils/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea, faCoffee, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-regular-svg-icons'
import EditValveExercise from './EditValveExercise';
import { getValve } from '../services/api';
import RequireAuth from '../services/authentication/RequireAuth';
import Role from '../services/authentication/Roles';
import { makeValve } from '../models';

const Valve = ({ onUpdate, onDelete }) => {
  const [valve, setValve] = useState(null);
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupValve();
  }, [valveId, history.location]);

  const setupValve = async () => {
    const result = await getValve(valveId);
    setValve(makeValve(result));
  };

  const save = async () => {
    try {
      
      history.replace('/valves/' + valve.id)
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  return (
    <div id="valve" className="page">
      <h2>{valve?.name ?? "Valve"}</h2>
      <Switch>
        <ErrorBoundaryRoute exact path={path}>
          <DetailValve valve={valve} onDelete={onDelete} />
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/edit`}>
          <RequireAuth roles={[Role.Admin]}>
            <EditValve onUpdate={onUpdate} />
          </RequireAuth>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/valveExerciseSettings`}>
          <ValveExerciseSettings/>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/valveExerciseSettings/edit`}>
          <RequireAuth roles={[Role.Admin]}>
            <EditValveExerciseSettings onUpdate={onUpdate} />
          </RequireAuth>
        </ErrorBoundaryRoute>
        <ErrorBoundaryRoute exact path={`${path}/torqueLogs`} component={TorqueLogs} />
        <ErrorBoundaryRoute exact path={`${path}/valveExercise/:valveExerciseId?`} component={ValveExercise} />
        <ErrorBoundaryRoute exact path={`${path}/valveExercise/:valveExerciseId/edit`} >
          <RequireAuth roles={[Role.Admin]}>
            <EditValveExercise onUpdate={onUpdate} />
          </RequireAuth>
        </ErrorBoundaryRoute>
      </Switch>
    </div>
  );
};

export const ValveAside = () => {
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const id = +valveId;

  return (
    <Aside>
      <Link to={`${url}`} className='aside-item'>
        <div className="icon-button" title="Valve Details">
          <FontAwesomeIcon icon={faListAlt} />
        </div>
        <span>Valve Details</span>
      </Link>
      <Link to={`${url}/valveExerciseSettings`} className='aside-item'>
        <div className="icon-button" title="Exercise Settings">
          <FontAwesomeIcon icon={faSlidersH} />
        </div>
        <span>Exercise Settings</span>
      </Link>
      <Link to={`${url}/valveExercise`} className='aside-item'>
        <div className="icon-button" title="Exercise Results">
          <FontAwesomeIcon icon={faChartArea} />
        </div>
        <span>Exercise Results</span>
      </Link>
    </Aside>
  );
};

export default Valve