import React, { useState, useEffect, useRef } from 'react';
import ValveMap from '../components/valveMap/ValveMap';
import ValveList from '../components/valveList/ValveList';
import { useLocation } from 'react-router-dom';
import { getValves, importValves, exportValves, deleteValves, getHydrants } from '../services/api';
import useLocalStorage from '../utils/useLocalStorage';
import Upload from '../components/Upload';
import Download from '../components/Download';
import FileDownload from 'js-file-download';
import useAuth from '../services/authentication/useAuth';
import Map from '../components/map/Map';

const Home = ({ navigator }) => {
  const [mode, setMode] = useLocalStorage('home_mode', 'map');

  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef(searchText);
  const [searchTimeout, setSearchTimeout] = useState(null);
  
  const [selectedId, setSelectedId] = useState(null);
  const [loadingValves, setLoadingValves] = useState(false);
  const [valves, setValves] = useState([]);
  
  const [loadingHydrants, setLoadingHydrants] = useState(false);
  const [hydrants, setHydrants] = useState([]);

  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadErrors, setDownloadErrors] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    if (searchTimeout == null) {
      setSearchTimeout(setTimeout(() => {
        console.log(searchTextRef.current);
        setupValves(searchTextRef.current);
        setupHydrants(searchTextRef.current);
        setSearchTimeout(null);
      }, 500));
    }

    return () => {
      if (searchTimeout !== null) {
        clearTimeout(searchTimeout);
        setSearchTimeout(null);
      }
    }
  }, [searchText, auth.companyId, auth.user]);

  const setupValves = async (searchText) => {
    try {
      setLoadingValves(true);
      const result = await getValves(searchText);
      setValves(result);
      setLoadingValves(false);
    } catch (ex) {
      console.log(ex);
      setLoadingValves(false);
    }
  };

  const setupHydrants = async (searchText) => {
    try {
      setLoadingHydrants(true);
      const result = await getHydrants(searchText);
      setHydrants(result);
      setLoadingHydrants(false);
    } catch (ex) {
      console.log(ex);
      setLoadingHydrants(false);
    }
  };

  const updateSearchText = (text) => {
    searchTextRef.current = text;
    setSearchText(text);
  };

  const toggleUpload = () => {
    setUploadOpen(!uploadOpen);
  };

  const toggleDownload = () => {
    setDownloadOpen(!downloadOpen);
  }

  const uploadValves = async (files, mappings) => {
    try {
      setUploadErrors([]);
      setUploading(true);
      const result = await importValves(files[0], mappings);
      if (result.success) {
        setUploadOpen(false);
        setupValves(searchTextRef.current);
      } else {
        setUploadErrors(result.messages || ['An error has occured with the upload.']);
      }
    } catch (ex) {
      if (!ex.response.data)
        setUploadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setUploading(false);
    }
  };

  const downloadValves = async (mappings) => {
    try {
      setDownloadErrors([]);
      setDownloading(true);
      const result = await exportValves(mappings);
      FileDownload(result, 'valves_export.csv');
      setDownloadOpen(false);
    } catch (ex) {
      if (!ex.response.data)
        setDownloadErrors(['An error has occured with the upload.']);
      else
        setUploadErrors(ex.response.data);
      console.log(ex);
    } finally {
      setDownloading(false);
    }
  };

  const removeValves = async (valveIds) => {
    await deleteValves(valveIds);
    await setupValves(searchText);
  };

  const removeHydrants = async (hydrantIds) => {
    await deleteHydrants(hydrantIds);
    await setupHydrants(searchText);
  };

  const showHomePage = location.pathname === '/' || location.pathname === '/home' || location.pathname === '/home/';

  return (
    <>
        <div className={`fill${showHomePage ? '' : ' hidden'}`}>
          <Map valves={valves} hydrants={hydrants} selectedId={selectedId} searchText={searchText} setSearchText={updateSearchText} toggleUpload={toggleUpload} toggleDownload={toggleDownload} />
        </div>
        {loadingValves || loadingHydrants && <div className={`spinner${showHomePage ? '' : ' hidden'}`}></div>}
    </>
  );
};

export default Home;
