import { faFileExport, faPrint } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { getValves, getHydrants, getCurrentCompany, getCompanyLogo, exportExerciseResults } from '../../../services/api';
import ValveResult from './ValveResult';
import HydrantResult from './HydrantResult';
import CircleButton from '../../../components/CircleButton';
import hurcoLogoImg from '../../../assets/images/HurcoLogo.png';
import LabelledTextBox from '../../../components/LabelledTextBox';
import { makeValve, makeHydrant, makeCompany, makeCompanyLogo } from '../../../models';
import FileDownload from 'js-file-download';
import LabelledPicker from '../../../components/LabelledPicker';
import EventEmitter from '../../../services/events';

//TODO: Load exercises here instead of child component.
//      Then we can tell when we're done loading and the child only has to worry about displaying.
const ExerciseResultsReport = () => {
  const [valves, setValves] = useState([]);
  const [hydrants, setHydrants] = useState([]);
  const [filteredValves, setFilteredValves] = useState([]);
  const [filteredHydrants, setFilteredHydrants] = useState([]);
  const [company, setCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [loadingValves, setLoadingValves] = useState(true);
  const [loadingHydrants, setLoadingHydrants] = useState(true);
  const [loadingExercises, setLoadingExercise] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    deviceType: 'all'
  });

  useEffect(() => {
    setupCompany();
    setupValves();
    setupHydrants();
  }, []);
  useEffect(() => {
    setupCompanyLogo();
  }, [company]);

  useEffect(() => {
    filterValves();
    filterHydrants();
  }, [filter, valves, hydrants]);

  useEffect(() => {
    if ((!valves || valves.length === 0) && (!hydrants || hydrants.length === 0)) {
      setLoadingExercise(false);
    }
  }, []);

  useEffect(() => {
    setLoading(loadingCompany || loadingValves || loadingHydrants || loadingExercises);
  }, [loadingCompany, loadingValves, loadingHydrants, loadingExercises]);

  const setup = async () => {
    try {
      setLoading(true);
      await setupCompany();
      console.log(company);
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };

  const setupCompany = async () => {
    try {
      setLoadingCompany(true);
      const fetchedCompany = await getCurrentCompany();
      setCompany(makeCompany(fetchedCompany));
      setLoadingCompany(false);
    } catch (ex) {
      console.log(ex);
      setLoadingCompany(false);
    }
  };

  const setupCompanyLogo = async () => {
    if (!company) return;
    const result = await getCompanyLogo(company.id);
    if (result.deletedDate) return;
    setCompanyLogo(makeCompanyLogo(result));
  };

  const setupValves = async () => {
    try {
      setLoadingValves(true);
      const fetchedValves = await getValves('', true);
      const mappedValves = fetchedValves.map(v => makeValve(v));
      setValves(mappedValves);
      setLoadingValves(false);
    } catch (ex) {
      console.log(ex);
      setLoadingValves(false);
    }
  };

  const filterValves = () => {
    const filtered = valves.filter(v => {
      return (filter.deviceType === 'valves' || filter.deviceType === 'all')
        && v.lastMaintenanceDate
        && (!filter.startDate || new Date(v.lastMaintenanceDate) >= new Date(filter.startDate))
        && (!filter.endDate || new Date(v.lastMaintenanceDate) < addDays(1, new Date(filter.endDate)));
    });
    setFilteredValves(filtered);
  };

  const setupHydrants = async () => {
    try {
      setLoadingHydrants(true);
      const fetchedHydrants = await getHydrants('', true);
      const mappedHydrants = fetchedHydrants.map(v => makeHydrant(v));
      setHydrants(mappedHydrants);
      setLoadingHydrants(false);
    } catch (ex) {
      console.log(ex);
      setLoadingHydrants(false);
    }
  };

  const filterHydrants = () => {
    const filtered = hydrants.filter(v => {
      return (filter.deviceType === 'hydrants' || filter.deviceType === 'all')
        && v.lastMaintenanceDate
        && (!filter.startDate || new Date(v.lastMaintenanceDate) >= new Date(filter.startDate))
        && (!filter.endDate || new Date(v.lastMaintenanceDate) < addDays(1, new Date(filter.endDate)));
    });
    setFilteredHydrants(filtered);
  };

  const print = () => {
    window.print();
  };

  const exportCsv = async () => {
    try {
      setExporting(true);
      const result = await exportExerciseResults({ valveIds: filteredValves.map(v => v.id), hydrantids: filteredHydrants.map(h => h.id) });
      FileDownload(result, 'exercise_results.csv');
    } catch (ex) {
      console.log(ex);
      EventEmitter.emit('show-error', { title: 'Error Exporting Results', body: 'Unable to export results.' });
    } finally {
      setExporting(false);
    }
  };

  const onChange = e => {
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value + ' 0:0');
      //   break;
    }
    setFilter({ ...filter, [e.target.name]: value });
  };

  const onBlur = e => {
    console.log(e.target.type);
    const type = e.target.type;
    let value = e.target.value;
    switch (type) {
      case "number":
        value = +value;
        break;
      // case "date":
      //   value = new Date(value + ' 0:0');
      //   break;
    }

    setFilter({ ...filter, [e.target.name]: value });
  };

  const filteredRecords = [ ...filteredValves.map(v => { return { deviceType: 'Valve', ...v }}), ...filteredHydrants.map(h => { return { deviceType: 'Hydrant', ...h }}) ];
  const sortedRecords = filteredRecords.sort((a, b) => a.exerciseDate >= b.exerciseDate ? 1 : -1);
  const recordResults = sortedRecords.map((r, idx) => r.deviceType === 'Valve' ? <ValveResult key={idx} valve={r} /> : <HydrantResult key={idx} hydrant={r} />);
  const valveResults = filteredValves.map((v, idx) => <ValveResult key={idx} valve={v} />);
  const hydrantResults = filteredHydrants.map((h, idx) => <HydrantResult key={idx} hydrant={h} />);
  let phoneNumber = company?.phoneNumber ? `(${company?.phoneNumber.substring(0, 3)}) ${company?.phoneNumber.substring(3, 6)}-${company?.phoneNumber.substring(6, company?.phoneNumber.length)}` : null;
  phoneNumber = phoneNumber?.replace(/[\(\)\s-]+$/, '');

  return (
    <div className="report valve-results-report">
      <div className="report-filter no-print">
        <LabelledTextBox type="date" label="Start Date" value={filter.startDate} name="startDate" onChange={onChange} onBlur={onBlur}/>
        <LabelledTextBox type="date" label="End Date" value={filter.endDate} name="endDate" onChange={onChange} onBlur={onBlur}/>
        <LabelledPicker label="Device Type" value={filter.deviceType} name="deviceType" onChange={onChange} onBlur={onBlur}>
          <option value="all">All</option>
          <option value="valves">Valves</option>
          <option value="hydrants">Hydrants</option>
        </LabelledPicker>
      </div>
      {(loading || exporting) && <div className="spinner"></div>}
      <div className="report-title">
        <h2>Exercise Results Report</h2>
        <div className="report-actions">
          {loading || <CircleButton icon={faFileExport} size="large" title="Export" onClick={exportCsv} className="no-print" />}
          {loading || <CircleButton icon={faPrint} size="large" title="Print" onClick={print} className="no-print" />}
        </div>
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <td>
              <div className="report-header">
                {companyLogo ?
                  <div className="header-logo">
                    <img src={`${companyLogo?.dataType},${companyLogo?.image}`} alt={companyLogo.label} />
                  </div>
                : null}
                {company ?
                  <div className="header-details">
                    <div className="header-detail">
                      <span>{company?.name}</span>
                    </div>
                    <div className="header-detail">
                      <span>{company?.streetAddress}</span>
                    </div>
                    <div className="header-detail">
                      <span>{company?.city}{company?.city && company?.state ? ',' : null} </span>
                      <span>{company?.state} </span> 
                      <span>{company?.zipCode}</span>
                    </div>
                    {phoneNumber ?
                      <div className="header-detail">
                        <span>{phoneNumber}</span>
                      </div>
                    : null}
                  </div>
                : null}
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {recordResults}
        </tbody>
      </table>
    </div>
  );
};

export default ExerciseResultsReport;