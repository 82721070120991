import React, { useState, useEffect } from 'react';
import { getCurrentCompany, updateCompany, getCompanyLogo, updateCompanyLogo, deleteCompanyLogo } from '../services/api';
import { makeCompany, makeCompanyLogo } from '../models';
import LabelledTextBox from '../components/LabelledTextBox';
import LabelledPicker from '../components/LabelledPicker';
import LabelledImagePicker from '../components/LabelledImagePicker';
import EventEmitter from '../services/events';
import { useHistory } from 'react-router-dom';
import { stateOptions } from '../utils/dropdownOptions/stateOptions';

const EditCompany = () => {
  const [company, setCompany] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setupCompany();
  }, []);
  useEffect(() => {
    setupCompanyLogo();
  }, [company]);

  const setupCompany = async () => {
    const result = await getCurrentCompany();
    console.log(result);
    setCompany(makeCompany(result));
  };

  const setupCompanyLogo = async () => {
    if (!company) return;
    const result = await getCompanyLogo(company.id);
    if (result.deletedDate) return;
    console.log(result);
    setCompanyLogo(makeCompanyLogo(result));
  };

  const save = async () => {
    try {
      company.validate();
      const result = await updateCompany(company);

      if (companyLogo) {
        try {
          companyLogo.validate();
          const data = await updateCompanyLogo(companyLogo);
          //const uploadedImage = await data.json();
          if (data) {
            console.log("Successfully uploaded image");
          } else {
            console.log("Error Found");
          }  
        } catch (ex) {
          console.log(ex);
        }
      } else {
        try {
          await deleteCompanyLogo(company.id);
        } catch (ex) {
          console.log(ex);
        }
      }

      EventEmitter.emit('show-notification', 'Updated Company');
      history.replace('/company');
    } catch (error) {
      console.log(error);
      if (error.name === 'ValidationError') {
        EventEmitter.emit('show-error', { title: 'Error Saving Company', body: error.message.split('\n').map((str, idx) => <p key={idx}>{str}</p>) });
      } else {
        EventEmitter.emit('show-error', { title: 'Error Saving Company', body: 'Unable to save company.' });
      }
    }
  };

  const cancel = async () => {
    history.goBack();
  };

  const onChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'valveExerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'valveExerciseFrequencyMonths';
    } else if (name === 'valveExerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'valveExerciseFrequencyMonths';
    }

    setCompany(makeCompany({ ...company, [name]: value }));
  };

  const onBlur = e => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === 'valveExerciseFrequencyYears') {
      value = (+months || 0) + 12 * +value;
      name = 'valveExerciseFrequencyMonths';
    } else if (name === 'valveExerciseFrequencyMonths') {
      value = +value + 12 * (+years || 0);
      name = 'valveExerciseFrequencyMonths';
    }

    setCompany(makeCompany({ ...company, [name]: value }));
  };

  const onChangePhone = e => {
    let value = e.target.value;
    let name = e.target.name;

    const number = value.replace(/[^0-9]/g, '');

    setCompany(makeCompany({ ...company, [name]: number }));
  };

  const onBlurPhone = e => {
    let value = e.target.value;
    let name = e.target.name;

    const number = value.replace(/[^0-9]/g, '');

    setCompany(makeCompany({ ...company, [name]: number }));
  };

  const onLogoChange = (e) => {
    if (e) {
      setCompanyLogo(makeCompanyLogo({ companyId: company.id, ...companyLogo, ...e }))
    } else {
      setCompanyLogo(null);
    }
  };

  const years = Math.floor(company?.valveExerciseFrequencyMonths / 12);
  const months = company?.valveExerciseFrequencyMonths % 12;
  let phoneNumber = company?.phoneNumber ? `(${company?.phoneNumber.substring(0, 3)}) ${company?.phoneNumber.substring(3, 6)}-${company?.phoneNumber.substring(6, company?.phoneNumber.length)}` : '';
  phoneNumber = phoneNumber.replace(/[\(\)\s-]+$/, '');

  return (
    <div>
        <h3>Edit Company</h3>
        {company ? (
          <>
            <div className='details'>
              <LabelledTextBox label="Name" value={company.name} name="name" onChange={onChange} onBlur={onBlur} />
              <LabelledTextBox label="Street Address" value={company.streetAddress} name="streetAddress" onChange={onChange} onBlur={onBlur} />
              <LabelledTextBox label="City" value={company.city} name="city" onChange={onChange} onBlur={onBlur} />
              <LabelledPicker label="State" value={company.state} name="state" onChange={onChange} onBlur={onBlur} >
                <option value=""></option>
                {stateOptions}
              </LabelledPicker>
              <LabelledTextBox label="Zip Code" value={company.zipCode} name="zipCode" onChange={onChange} onBlur={onBlur} />
              <LabelledTextBox label="Phone Number" value={phoneNumber} name="phoneNumber" onChange={onChangePhone} onBlur={onBlurPhone} />
              <LabelledImagePicker label="Logo" value={companyLogo} onChange={onLogoChange} />
              {/* <div className="labelled-text-box">
                <span className="label">Valve Exercise Frequency</span>
                <div className="input">
                  <div className="labelled-text-box-partial">
                    <span className="label-partial">Years</span>
                    <input type="number" value={years?.toString() ?? ''}
                      name="valveExerciseFrequencyYears"
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Valve Exercise Frequency (Years)"
                    />
                  </div>
                  <div className="labelled-text-box-partial">
                    <span className="label-partial">Months</span>
                    <input type="number" value={months?.toString() ?? ''}
                      name="valveExerciseFrequencyMonths"
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Valve Exercise Frequency (Months)"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="form-actions">
              <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
              <button type="button" className="btn btn-primary" onClick={save}>Save</button>
            </div>
          </>
        ) : (
          <span className="spinner"></span>
        )}
    </div>
  )
};

export default EditCompany;