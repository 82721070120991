import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getHydrantImages = async () => {
  const result = await tryGet(`/hydrantImages`);
  return result.data;
};

export const getHydrantImage = async (hydrantImageId) => {
  const result = await tryGet(`/hydrantImages/${hydrantImageId}`);
  return result.data;
};

export const getHydrantImageEdit = async (hydrantImageId) => {
  const result = await tryGet(`/hydrantImages/${hydrantImageId}/edit`);
  return result.data;
};

export const updateHydrantImage = async (hydrantImage) => {
  const result = await tryPut(`/hydrantImages`, hydrantImage);
  return result.data;
};

export const addHydrantImage = async (hydrantImage) => {
  const result = await tryPost(`/hydrantImages`, hydrantImage);
  return result.data;
};

export const deleteHydrantImage = async (hydrantImageId) => {
  const result = await tryDelete(`/hydrantImages/${hydrantImageId}`);
  return result.data;
};

export const deleteHydrantImages = async (hydrantImageIds) => {
  console.log(hydrantImageIds);
  const result = await tryDelete('/hydrantImages', { data: hydrantImageIds });
  return result.data;
};