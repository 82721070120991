import React from 'react';
import { dateOptions } from '../../utils/dates';

const HydrantExerciseTableRow = ({ exercise, onSelect }) => {  
  return (
    <tr id={exercise.id}>
      <td>{exercise.exerciseDate ? new Date(exercise.exerciseDate).toLocaleDateString('en-US', dateOptions) : ''}</td>
      <td>{exercise.maxTorque}</td>
      <td>{exercise.maxTorqueRevolutions}</td>
      <td>{exercise.totalRevolutions}</td>
      <td className='actions'>
        <div className='link' onClick={() => onSelect(exercise.id)}>Select</div>
      </td>
    </tr>
  );
};

export default HydrantExerciseTableRow;