import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { ValveExerciseSettings, TorqueLogs } from './';
import LabelledTextField from '../components/LabelledTextField';
import { dateOptions, dateTimeOptions } from '../utils/dates';
import { getValve } from '../services/api';
import { deleteValve } from '../services/api';
import IsAuthorized from '../services/authentication/IsAuthorized';
import { gpsFixes } from '../utils/dropdownOptions/gpsFixOptions';
import { makeValve } from '../models';
import CircleButton from '../components/CircleButton';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const DetailValve = ({ onDelete }) => {
  const [valve, setValve] = useState(null);
  const [startDelete, setStartDelete] = useState(false);
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupValve();
  }, [valveId, history.location]);

  const setupValve = async () => {
    const result = await getValve(valveId);
    setValve(makeValve(result));
  };

  const deleteRecord = async () => {
    await deleteValve(valveId);
    onDelete(valveId);
    history.replace('/valves');
  };

  const gpsFix = gpsFixes.find(g => g.id === valve?.gpsFixId);

  return (
    <div className="detail-page">
      {valve ? (
        <>
          {!startDelete ? (
            <div className="form-actions">
              <IsAuthorized>
                <Link to={`${url}/edit`}>
                  <CircleButton icon={faEdit} size='medium' type='primary' title='Edit' />
                </Link>
                <div className="link-icon" onClick={() => setStartDelete(true)}>
                  <CircleButton icon={faTrashAlt} size='medium' type='danger' title='Delete' />
                </div>
              </IsAuthorized>
            </div>
          ) : (
            <div className="form-actions">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
          <div className="details">
            <LabelledTextField label="Latitude" text={valve.latitude} />
            <LabelledTextField label="Longitude" text={valve.longitude} />
            <LabelledTextField label="GPS Fix" text={gpsFix?.name} />
            <LabelledTextField label="Elevation" text={valve.elevation} />
            <LabelledTextField label="HDOP" text={valve.hdop} />
            <LabelledTextField label="VDOP" text={valve.vdop} />
            <LabelledTextField label="State X" text={valve.stateX} />
            <LabelledTextField label="State Y" text={valve.stateY} />
            <LabelledTextField label="Access Type" text={valve.accessType} />
            <LabelledTextField label="Street Number" text={valve.streetNumber} />
            <LabelledTextField label="Street Name" text={valve.streetName} />
            <LabelledTextField label="Cross Street" text={valve.crossStreet} />
            <LabelledTextField label="Location Notes" text={valve.locationNotes} />
            <LabelledTextField label="City" text={valve.city} />
            <LabelledTextField label="State" text={valve.state} />
            <LabelledTextField label="Zip Code" text={valve.zipCode} />
            <LabelledTextField label="Manufacturer" text={valve.manufacturer} />
            <LabelledTextField label="Install Date" text={valve.installDate ? new Date(valve.installDate).toLocaleDateString('en-US', dateOptions) : ''} />
            <LabelledTextField label="Size" text={valve.size} />
            <LabelledTextField label="Box Size" text={valve.boxSize} />
            <LabelledTextField label="Box Depth" text={valve.boxDepth} />
            <LabelledTextField label="Type" text={valve.type} />
            <LabelledTextField label="Function" text={valve.function} />
            <LabelledTextField label="Drive" text={valve.drive} />
            <LabelledTextField label="District" text={valve.district} />
            <LabelledTextField label="Sub Division" text={valve.subDivision} />
            <LabelledTextField label="Status" text={valve.status} />
            <LabelledTextField label="Comments" text={valve.comments} />
            <LabelledTextField label="Last Maintenance Date" text={valve.lastMaintenanceDate ? new Date(valve.lastMaintenanceDate).toLocaleDateString('en-US', dateOptions) : ''} />
            <LabelledTextField label="Max Torque" text={valve.maxTorque} />
            <LabelledTextField label="Max Torque Turns" text={valve.maxTorqueRevolutions} />
            <LabelledTextField label="Max Turns" text={valve.maxRevolutions} />
            <LabelledTextField label="Total Turns" text={valve.totalRevolutions} />
            <LabelledTextField label="Created By:" text={valve.createdBy} />
            <LabelledTextField label="Created Date:" text={valve.createdDate ? new Date(valve.createdDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
            <LabelledTextField label="Modified By:" text={valve.modifiedBy} />
            <LabelledTextField label="Modified Date:" text={valve.modifiedDate ? new Date(valve.modifiedDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
          </div>
        </>
      ) : <div className="spinner"></div>}
    </div>
  );
};

export default DetailValve;
