import React, { useState, useEffect, useRef } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { getCurrentCompany, createOrder, captureOrder, createSubscription, cancelSubscription, getSubscription, getPlan, getClientId } from '../../services/api';
import LabelledTextField from '../LabelledTextField';
import { dateOptions } from '../../utils/dates';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import globals from '../../utils/globals';
import useAuth from '../../services/authentication/useAuth';
import SubscriptionSelection from './SubscriptionSelection';
import CompanySubscriptionPlan from './CompanySubscriptionPlan';
import { sub } from 'date-fns';

const CompanySubscription = () => {
  const [loading, setLoading] = useState(true);
  const [payPalClientId, setPayPalClientId] = useState(globals.payPalClientId);
  const [subscription, setSubscription] = useState(null);
  const [plan, setPlan] = useState(null);
  const [company, setCompany] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [startCancel, setStartCancel] = useState(false);
  const selectedPlanIdRef = useRef(selectedPlanId);
  const { refreshToken } = useAuth();

  useEffect(() => {
    setupPayPalClientId();
    setupCompany();
  }, []);

  useEffect(() => {
    if (company) {
      console.log(company);
      setupSubscription(company.subscriptionId);
    } else {
      setLoading(false);
    }
  }, [company]);

  useEffect(() => {
    if (subscription) {
      console.log(subscription);
      setupPlan(subscription.planId);
    } else {
      setLoading(false);
    }
  }, [subscription]);

  const setupPayPalClientId = async () => {
    const clientId = await getClientId();
    console.log(clientId);
    setPayPalClientId(clientId);
  };

  const setupCompany = async () => {
    const result = await getCurrentCompany();
    console.log(result);
    setCompany(result);
  };

  const setupSubscription = async (subscriptionId) => {
    try {
      if (subscriptionId !== null) {
        const result = await getSubscription(subscriptionId);
        console.log(result);
        setSubscription(result);
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  const setupPlan = async (planId) => {
    try {
      console.log(planId);
      if (planId !== null) {
        const result = await getPlan(planId);
        console.log(result);
        setPlan(result);
      }
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const createPayPalSubscription = async (data, actions) => {
    console.log('Creating subscription...', data, actions);
    const subscriptionId = await createSubscription(selectedPlanIdRef.current);
    console.log(subscriptionId);
    return subscriptionId;
  };

  const onPayPalApprove = async (data, actions) => {
      console.log('Approving order...', data, actions);
      await setupCompany();
      await refreshToken();
      // onApprove();
  };

  const onPayPalCancel = (data) => {
      console.log('cancel');
      console.log(data);
      console.log(JSON.stringify(data, null, 2));
      //alert(data);
  };

  const onPayPalError = (err) => {
      console.log('error');
      console.log(err)
      console.log(JSON.stringify(err, null, 2));
      //alert(err);
  };

  const onCancelSubscription = async (subscriptionId) => {
    try {
      await cancelSubscription(subscriptionId);
      setupSubscription();
    } catch(ex) {
      console.log(ex);
    } finally {
      console.log(subscriptionId);
      setStartCancel(false);
    }
  };

  const onSelectionChange = id => {
    selectedPlanIdRef.current = id
    setSelectedPlanId(id);
  };

  const initialPayPalOptions = {
      "client-id": payPalClientId,
      components: "buttons",
      intent: "subscription",
      vault: true
  };

  let jsx = null;
  if (subscription?.status === "ACTIVE") {
    jsx = (
      <>
        <CompanySubscriptionPlan plan={plan} />
        <div className="card card-spaced">
          <LabelledTextField label="Subscription ID" text={subscription.id} />
          <LabelledTextField label="Status" text={subscription.status} />
          <LabelledTextField label="Last Payment" text={subscription?.latestPayment?.paymentDate ? new Date(subscription.latestPayment.paymentDate).toLocaleDateString('en-US', dateOptions) : ''} />
          <LabelledTextField label="Amount" text={subscription?.latestPayment?.amount ? '$' + subscription?.latestPayment?.amount.toFixed(2) : ''} />
          <LabelledTextField label="Next Due" text={subscription?.nextBillingDate ? new Date(subscription.nextBillingDate).toLocaleDateString('en-US', dateOptions) : ''} />
          {startCancel ? (
            <div className="form-actions">
              <span>Are you sure you want to cancel this subscription?</span>
              <button type="button" className="btn btn-danger" onClick={() => onCancelSubscription(subscription.id)}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartCancel(false)}>No</button>
            </div>
          ) : (
            <div className="form-actions">
              <button type="button" className="btn btn-danger" onClick={() => setStartCancel(true)}>Cancel Subscription</button>
            </div>
          )}
          </div>
      </>
    );
  } else if (subscription?.status === "SUSPENDED") {
    jsx = (
      <>
        <CompanySubscriptionPlan plan={plan} />
        <div className="card card-spaced">
          <LabelledTextField label="Subscription ID" text={subscription.id} />
          <LabelledTextField label="Status" text={subscription.status} />
          <LabelledTextField label="Last Payment" text={subscription?.latestPayment?.paymentDate ? new Date(subscription.latestPayment.paymentDate).toLocaleDateString('en-US', dateOptions) : ''} />
          <LabelledTextField label="Amount" text={subscription?.latestPayment?.amount ? '$' + subscription?.latestPayment?.amount.toFixed(2) : ''} />
          <div>Contact Hurco to re-activate subscription.</div>
          {startCancel ? (
            <div className="form-actions">
              <span>Are you sure you want to cancel this subscription?</span>
              <button type="button" className="btn btn-danger" onClick={() => onCancelSubscription(subscription.id)}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartCancel(false)}>No</button>
            </div>
          ) : (
            <div className="form-actions">
              <button type="button" className="btn btn-danger" onClick={() => setStartCancel(true)}>Cancel Subscription</button>
            </div>
          )}
        </div>
      </>
    );
  } else {
    jsx = (
      <div>
        <SubscriptionSelection selectedId={selectedPlanId} onSelectionChange={onSelectionChange} />
        {selectedPlanId &&
          <div className='subscription-buttons'>
            <PayPalScriptProvider options={initialPayPalOptions}>
              <PayPalButtons
                style={{ layout: 'vertical', shape: 'rect', color: 'gold', label: 'subscribe' }}
                onApprove={onPayPalApprove}
                onCancel={onPayPalCancel}
                onError={onPayPalError}
                createSubscription={createPayPalSubscription} />
            </PayPalScriptProvider>
          </div>
        }
      </div>
    );
  }

  return loading ? <span className="spinner"></span> : jsx;
};

export default CompanySubscription;
