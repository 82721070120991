import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import CircleButton from './CircleButton';
import Pill from './Pill';

const LabelledMultiPicker = ({ label, type, values, options, name, onAdd, onRemove }) => {
  const [selectedValue, setSelectedValue] = useState('');
  values = values || [];

  const change = (e) => {
    setSelectedValue(e.target.value);
    add(e.target.value);
  };

  const remove = (value) => {
    onRemove(value);
  };

  const add = (value) => {
    onAdd(value || selectedValue);
    setSelectedValue('');
  };

  const selectedOptionsJSX = options.filter(o => values.includes(o.value))
    .map((o, i) => <Pill key={i} content={o.content} onDismiss={() => remove(o.value)} />);
  const optionsJSX = options.filter(o => !values.includes(o.value)).map((o, i) => <option key={i} value={o.value}>{o.content}</option>);

  return (
    <div className="labelled-multi-picker">
      <span className="label">{label}</span>
      <div className="multi-picker">
        <div className="multi-picker-selected">{selectedOptionsJSX}</div>
        <div className="multi-picker-selector">
          <select type={type ? type : 'text'} value={selectedValue}
            name={name}
            onChange={change}
            placeholder={label}>
            <option value="" disabled={true}>Add {label}</option>
            {optionsJSX}
          </select>
        </div>
      </div>
    </div>
  );
};

export default LabelledMultiPicker;
