import React from 'react';
import { Link } from 'react-router-dom';

const Reports = () => {

  return (
    <div className="reports-page">
      <div className="report-links">
        <Link className="link" to="/reports/summary">Exercise Summary Report</Link>
        <Link className="link" to="/reports/results">Exercise Results Report</Link>
      </div>
    </div>
  );
};

export default Reports;