export default function buildMakeCompany({ validate, validators, makeDefaultValveExerciseSettings }) {
  return function makeCompany({
    id,
    name,
    streetAddress,
    city,
    state,
    zipCode,
    phoneNumber,
    subscriptionId,
    valveExerciseFrequencyMonths,
    defaultValveExerciseSettings,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      name: {
        prettyName: 'Name',
        validators: [validators.notEmpty(), validators.maxLength(100)],
      },
      streetAddress: {
        prettyName: 'Street Address',
        validators: [validators.maxLength(100)],
      },
      city: {
        prettyName: 'City',
        validators: [validators.maxLength(50)],
      },
      state: {
        prettyName: 'State',
        validators: [validators.exactLength(2)],
      },
      zipCode: {
        prettyName: 'Zip Code',
        validators: [validators.maxLength(15)],
      },
      phoneNumber: {
        prettyName: 'Phone Number',
        validators: [validators.maxLength(20)],
      },
      subscriptionId: {
        prettyName: 'Subscription ID',
        validators: [validators.maxLength(50)],
      },
      valveExerciseFrequencyMonths: {
        prettyName: 'Valve Exercise Frequency (Months)',
        validators: [validators.isInteger()],
      },
    };

    return Object.freeze({
      get id() { return id },
      get name() { return name },
      get streetAddress() { return streetAddress },
      get city() { return city },
      get state() { return state },
      get zipCode() { return zipCode },
      get phoneNumber() { return phoneNumber },
      get subscriptionId() { return subscriptionId },
      get valveExerciseFrequencyMonths() { return valveExerciseFrequencyMonths },
      get defaultValveExerciseSettings() { return defaultValveExerciseSettings ? defaultValveExerciseSettings.map(d => makeDefaultValveExerciseSettings(d)) : [] },
      validate() {
        console.log(this);
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}