import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../../components/LabelledTextField';
import { dateOptions, dateTimeOptions } from '../../utils/dates';
import { getHydrantModel } from '../../services/api';
import { deleteHydrant } from '../../services/api';
import IsAuthorized from '../../services/authentication/IsAuthorized';
import { gpsFixes } from '../../utils/dropdownOptions/gpsFixOptions';
import { makeHydrantModel, makeHydrantImage } from '../../models';
import CircleButton from '../../components/CircleButton';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const DetailHydrantModel = () => {
  const [hydrantModel, setHydrantModel] = useState(null);
  const [hydrantImage, setHydrantImage] = useState(null);
  const [startDelete, setStartDelete] = useState(false);
  const { path, url } = useRouteMatch();
  const { hydrantModelId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupHydrantModel();
  }, [hydrantModelId, history.location]);

  useEffect(() => {
    setupHydrantModel();
    setupHydrantImage();
  }, [hydrantModel]);

  const setupHydrantModel = async () => {
    const result = await getHydrantModel(hydrantModelId);
    setHydrantModel(makeHydrantModel(result));
  };

  const setupHydrantImage = async () => {
    if (!hydrantModel || !hydrantModel.hydrantImageId) {
      setHydrantImage(null);
      return;
    }

    const result = await getHydrantImage(hydrantModel.hydrantImageId);
    setHydrantImage(makeHydrantImage(result));
  };

  const deleteRecord = async () => {
    await deleteHydrant(hydrantModelId);
    history.goBack();
  };

  const gpsFix = gpsFixes.find(g => g.id === hydrantModel?.gpsFixId);

  return (
    <div className="detail-page">
      {hydrantModel ? (
        <>
          {!startDelete ? (
            <div className="form-actions">
              <IsAuthorized>
                <Link to={`${url}/edit`}>
                  <CircleButton icon={faEdit} size='medium' type='primary' title='Edit' />
                </Link>
                <div className="link" onClick={() => setStartDelete(true)}>
                  <CircleButton icon={faTrashAlt} size='medium' type='danger' title='Delete' />
                </div>
              </IsAuthorized>
            </div>
          ) : (
            <div className="form-actions">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
          <div className="details">
            <LabelledTextField label="Model ID" text={hydrantModel.modelId} />
            <LabelledTextField label="Description" text={hydrantModel.description} />
            <LabelledTextField label="Open Direction" text={hydrantModel.openDirection} />
            <LabelledTextField label="Valve Turns" text={hydrantModel.valveTurns} />
            <LabelledTextField label="Valve Opening Size" text={hydrantModel.valveOpeningSize} />
            <LabelledTextField label="Barrel Type" text={hydrantModel.barrelType} />
            <LabelledTextField label="Pumper Count" text={hydrantModel.pumperCount} />
            <LabelledTextField label="Pumper Diameter" text={hydrantModel.pumperDiameter} />
            <LabelledTextField label="Pumper Coefficient" text={hydrantModel.pumperCoefficient} />
            <LabelledTextField label="Pumper Thread Diameter" text={hydrantModel.pumperThreadDiameter} />
            <LabelledTextField label="Pumper Threads Per Inch" text={hydrantModel.pumperThreadsPerInch} />
            <LabelledTextField label="Nozzle Count" text={hydrantModel.nozzleCount} />
            <LabelledTextField label="Nozzle Diameter" text={hydrantModel.nozzleDiameter} />
            <LabelledTextField label="Nozzle Coefficient" text={hydrantModel.nozzleCoefficient} />
            <LabelledTextField label="Nozzle Thread Diameter" text={hydrantModel.nozzleThreadDiameter} />
            <LabelledTextField label="Nozzle Threads Per Inch" text={hydrantModel.nozzleThreadsPerInch} />
            <LabelledTextField label="Created By:" text={hydrantModel.createdBy} />
            <LabelledTextField label="Created Date:" text={hydrantModel.createdDate ? new Date(hydrantModel.createdDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
            <LabelledTextField label="Modified By:" text={hydrantModel.modifiedBy} />
            <LabelledTextField label="Modified Date:" text={hydrantModel.modifiedDate ? new Date(hydrantModel.modifiedDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
          </div>
        </>
      ) : <div className="spinner"></div>}
    </div>
  );
};

export default DetailHydrantModel;
