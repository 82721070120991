export const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
export const dateTimeOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
export const timeOptions = { hour: 'numeric', minute: 'numeric' };
export const secondOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
export const timeSecondOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', fractionalSecondDigits: 3 };
export const addDays = (days, date = null) => {
  var returnDate = date ? new Date(date.valueOf()) : new Date();
  returnDate.setDate(returnDate.getDate() + days);
  return returnDate;
};
export const addMonths = (months, date = null) => {
  var returnDate = date ? new Date(date.valueOf()) : new Date();
  returnDate.setMonth(returnDate.getMonth() + months);
  return returnDate;
};
export const addYears = (years, date = null) => {
  var returnDate = date ? new Date(date.valueOf()) : new Date();
  returnDate.setFullYear(returnDate.getFullYear() + years);
  return returnDate;
};