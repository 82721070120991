import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../components/LabelledTextField';
import { dateOptions } from '../utils/dates';
import axios from 'axios';
import IsAuthorized from '../services/authentication/IsAuthorized';
import { getValveExerciseSetting } from '../services/api';
import { makeValveExerciseSettings } from '../models';
import CircleButton from '../components/CircleButton';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const ValveExerciseSettings = () => {
  const [exerciseSettings, setExerciseSettings] = useState(null);
  const { path, url } = useRouteMatch();
  const { valveId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupExerciseSettings();
  }, [valveId, history.location]);

  const setupExerciseSettings = async () => {
    const result = await getValveExerciseSetting(valveId);
    setExerciseSettings(makeValveExerciseSettings(result));
  };
  
  const years = Math.floor((exerciseSettings?.exerciseFrequencyMonths ?? 0) / 12);
  const months = (exerciseSettings?.exerciseFrequencyMonths ?? 0) % 12;
  const frequency = `${years} year${years === 1 ? '' : 's'} ${months} month${months === 1 ? '' : 's'}`;

  return (
    <div>
      <h3>Exercise Settings</h3>
      {exerciseSettings ? (
        <>
          <div className="form-actions">
            <IsAuthorized>
              <Link to={`${url}/edit`}>
                <CircleButton icon={faEdit} size='medium' type='primary' title='Edit' />
              </Link>
            </IsAuthorized>
          </div>
          <div className="details">
            <LabelledTextField label="Torque Set Point" text={exerciseSettings.torqueSetPoint} />
            <LabelledTextField label="RPM Set Point" text={exerciseSettings.rpmSetPoint} />
            <LabelledTextField label="Open Direction" text={exerciseSettings.openDirection} />
            <LabelledTextField label="Current State" text={exerciseSettings.currentState} />
            <LabelledTextField label="Revolutions" text={exerciseSettings.revolutions} />
            <LabelledTextField label="Stuck Revs" text={exerciseSettings.stuckRevs} />
            <LabelledTextField label="Stuck Attempts" text={exerciseSettings.stuckAttempts} />
            <LabelledTextField label="Open Recess" text={exerciseSettings.openRecess} />
            <LabelledTextField label="Close Recess" text={exerciseSettings.closeRecess} />
            <LabelledTextField label="Flush Revolutions" text={exerciseSettings.flushRevolutions} />
            <LabelledTextField label="Flush Cycles" text={exerciseSettings.flushCycles} />
            <LabelledTextField label="Hardstop Speed" text={exerciseSettings.hardstopSpeed} />
            <LabelledTextField label="Auto Cycles" text={exerciseSettings.autoCycles} />
            <LabelledTextField label="Exercise Frequency" text={frequency} />
          </div>
        </>
      ) : <div className="spinner"></div>}
    </div>
  );
};

export default ValveExerciseSettings