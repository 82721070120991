import React, { useState, useEffect } from 'react';
import { getPlan } from '../../services/api';

const CompanySubscriptionPlan = ({ plan }) => {
  return plan && (
    <div id="subscriptionPlan" className="card card-spaced">
      <h4>{plan?.name}</h4>
      <div className="subscripton-details">
        <div>${plan?.amount.toFixed(2)} / {plan?.frequency.toLowerCase()}</div>
      </div>
    </div>
  );
};

export default CompanySubscriptionPlan;