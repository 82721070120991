import React, { useState } from 'react';
import LabelledTextBox from '../components/LabelledTextBox';
import { resetPassword } from '../services/api';
import EventEmitter from '../services/events';
import { useLocation, Link } from 'react-router-dom';

const ResetPassword = () => {
  const [credentials, setCredentials] = useState({});
  const [passwordError, setPasswordError] = useState(null);
  const [resettingPassword, setResettingPassword] = useState(null);
  const [passwordReset, setPasswordReset] = useState(false);
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get('userId');
  const code = new URLSearchParams(search).get('code');

  const onChange = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onBlur = e => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const resetAccountPassword = async () => {
    try {
      setPasswordError(null);
      setResettingPassword(true);
      if (credentials.password !== credentials.confirmPassword) {
        setPasswordError("Password and Confirm Password don't match");
        return;
      }
      if (credentials.password.length < 6) {
        setPasswordError("Password must be at least 6 characters long");
        return;
      }
      if (!/[a-z]/.test(credentials.password)) {
        setPasswordError('Password must contain a lower case letter');
        return;
      }
      if (!/[A-Z]/.test(credentials.password)) {
        setPasswordError('Password must contain an upper case letter');
        return;
      }
      if (!/[0-9]/.test(credentials.password)) {
        setPasswordError('Password must contain a number');
        return;
      }
      if (!/[!@#$%^&*]/.test(credentials.password)) {
        setPasswordError('Password must contain a symbol: ! @ # $ % ^ & *');
        return;
      }

      await resetPassword({
        userId,
        code,
        password: credentials.password,
        confirmPassword: credentials.confirmPassword
      });
      setPasswordReset(false);
      setCredentials({});
      
      EventEmitter.emit('show-notification', 'Password Reset Successfully');

    } catch (ex) {
      setPasswordError('Unable to update password');
    } finally {
      setResettingPassword(false);
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      {passwordReset ? (
        <div>
          <div>Password reset successfully</div>
          <Link to="/login">Return to Login</Link>
        </div>
      ) : (
        <>
          <div className="reset-password">
            {passwordError && <div className="error center-text">{passwordError}</div> }
            <LabelledTextBox type="password" label="Password" value={credentials.password} name="password" onChange={onChange} onBlur={onBlur} />
            <LabelledTextBox type="password" label="Confirm Password" value={credentials.confirmPassword} name="confirmPassword" onChange={onChange} onBlur={onBlur} />
          </div>
          <div className="form-actions">
            <button type="button" className="btn btn-primary" onClick={resetAccountPassword} disabled={resettingPassword}>Reset Password</button>
          </div>
        </>
      )}
      {resettingPassword && <div className="spinner"></div>}
    </div>
  );
};

export default ResetPassword;