import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCaretDown, faCaretUp, faCarrot, faFileExport, faFileImport, faMapMarkedAlt, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import HydrantTableRow from './HydrantTableRow';
import Search from '../Search';
import CircleButton from '../CircleButton';
import Upload from '../Upload';
import IsAuthorized from '../../services/authentication/IsAuthorized';

const HydrantList = ({ hydrants, searchText, setSearchText, toggleUpload, toggleDownload, deleteHydrants }) => {
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [selectedHydrants, setSelectedVales] = useState([]);
  const [sortedHydrants, setSortedHydrants] = useState([]);
  const [startDelete, setStartDelete] = useState(false);
  const [sorts, setSorts] = useState(null);
  const [sortOrder, setSortOrder] = useState([]);
  const { path, url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    sortHydrants();
  }, [hydrants, sorts, sortOrder]);

  useEffect(() => {
    setStartDelete(false);
    setSelectedVales([]);
  }, [sortedHydrants, page, take]);

  const maxPage = take >= 0 ? Math.max(0, Math.ceil((hydrants?.length ?? 0) / take) - 1) : 0;
  if (page > maxPage) {
    setPage(maxPage);
  }

  const goNextPage = () => {
    setPage(Math.min(maxPage, page + 1));
  };

  const goLastPage = () => {
    setPage(maxPage);
  };
  
  const goPrevPage = () => {
    setPage(Math.max(0, page - 1));
  };

  const goFirstPage = () => {
    setPage(0);
  };

  const updateSort = (field, descending) => {
    const filteredSortOrder = sortOrder.filter(s => s !== field);
    setSortOrder([field, ...filteredSortOrder]);

    setSorts({
      ...sorts,
      [field]: descending,
    });
  };

  const sortHydrants = () => {
    const orderedHydrants = [...hydrants].sort((a, b) => {
      let result = 0;
      for (const field of sortOrder) {
        if (result !== 0) {
          return result;
        }

        result = (a[field] || '') < (b[field] || '') ? -1 : ((a[field] || '') > (b[field] || '') ? 1 : 0);
        if (sorts[field]) {
          result *= -1;
        }
      }

      return result;
    });

    setSortedHydrants(orderedHydrants);
  };
  
  const startIndex = take >= 0 ? Math.min(hydrants?.length ?? 0, take * page) : 0;
  const endIndex = take >= 0 ? Math.min(hydrants?.length ?? 0, take * (page + 1)) : hydrants?.length ?? 0;
  const pageHydrants = sortedHydrants?.slice(startIndex, endIndex);

  const toggleHydrantSelect = (id, select) => {
    setStartDelete(false);
    const selected = selectedHydrants.filter(s => s !== id);
    if (select)
      selected.push(id);
    setSelectedVales(selected);
  };

  const selectAllHydrants = () => {
    setStartDelete(false);
    setSelectedVales(pageHydrants?.map(v => v.id));
  }

  const deselectAllHydrants = () => {
    setStartDelete(false);
    setSelectedVales([]);
  };

  const hydrantRows = pageHydrants?.map((hydrant, index) => {
    const selected = selectedHydrants.some(s => s === hydrant.id);
    const toggleSelected = select => {
      toggleHydrantSelect(hydrant.id, select);
    };
    return <HydrantTableRow key={index} hydrant={hydrant} selected={selected} toggleSelected={toggleSelected} />
  });

  const removeHydrants = () => {
    deleteHydrants(selectedHydrants);
  };

  return (
    <div className="list">
      <div className="list-actions">
        <Search searchText={searchText} setSearchText={setSearchText} />
        <CircleButton icon={faPlus} type="success" title="Add Hydrant" onClick={() => history.push(`hydrants/add`)} />
        <CircleButton icon={faFileImport} type="alternate" title="Import Hydrants" onClick={toggleUpload} />
        <CircleButton icon={faFileExport} type="alternate" title="Export Hydrants" onClick={toggleDownload} />
      </div>
      <div className="table-actions">
        <select value={take} title="Hydrants per page" onChange={(e) => setTake(Number(e.target.value))}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={-1}>All</option>
        </select>
        <CircleButton icon={faAngleDoubleLeft} className={page <= 0 ? 'disabled' : null} title="First Page" onClick={goFirstPage} />
        <CircleButton icon={faAngleLeft} className={page <= 0 ? 'disabled' : null} title="Prev Page" onClick={goPrevPage} />
        <span>{page + 1}</span>
        <CircleButton icon={faAngleRight} className={page >= maxPage  ? 'disabled' : null} title="Next Page" onClick={goNextPage} />
        <CircleButton icon={faAngleDoubleRight} className={page >= maxPage  ? 'disabled' : null} title="Last Page" onClick={goLastPage} />
        
        {!startDelete ? (
            <IsAuthorized>
              <CircleButton icon={faTrashAlt} type="danger" className={selectedHydrants.length > 0 ? null : 'disabled'} title="Delete Hydrants" onClick={() => selectedHydrants.length > 0 ? setStartDelete(true) : null} />
            </IsAuthorized>
          ) : (
            <div className="form-actions">
              <span>{`Are you sure you want to delete ${selectedHydrants.length} record${selectedHydrants.length > 1 ? 's' : ''}?`}</span>
              <button type="button" className="btn btn-primary" onClick={removeHydrants}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>
              <input type="checkbox" checked={selectedHydrants?.length === pageHydrants?.length} onChange={e => {
                if (selectedHydrants?.length === pageHydrants?.length) {
                  deselectAllHydrants();
                } else {
                  selectAllHydrants();
                }
              }} />
            </th>
            <th>
              <div className="th-field">
                <span>Name</span>
                <CircleButton
                  icon={(sorts?.name ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'name' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('name', !(sorts?.name ?? false))} />
              </div>
            </th>
            <th>
              <div className="th-field">
                <span>Street Number</span>
                <CircleButton
                  icon={(sorts?.streetNumber ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'streetNumber' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('streetNumber', !(sorts?.streetNumber ?? false))} />
              </div>
            </th>
            <th>
              <div className="th-field">
                <span>Street Name</span>
                <CircleButton
                  icon={(sorts?.streetName ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'streetName' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('streetName', !(sorts?.streetName ?? false))} />
              </div>
            </th>
            <th>
              <div className="th-field">
                <span>City</span>
                <CircleButton
                  icon={(sorts?.city ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'city' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('city', !(sorts?.city ?? false))} />
              </div>
            </th>
            <th>
              <div className="th-field">
                <span>State</span>
                <CircleButton
                  icon={(sorts?.state ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'state' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('state', !(sorts?.state ?? false))} />
              </div>
            </th>
            <th>
              <div className="th-field">
                <span>Last Maintenance Date</span>
                <CircleButton
                  icon={(sorts?.lastMaintenanceDate ?? false) ? faCaretUp : faCaretDown} type={sortOrder[0] === 'lastMaintenanceDate' ? 'primary' : null}
                  title="Order"
                  onClick={() => updateSort('lastMaintenanceDate', !(sorts?.lastMaintenanceDate ?? false))} />
              </div>
            </th>
            <th className='command-row'></th>
          </tr>
        </thead>
        <tbody>
          {hydrantRows}
        </tbody>
      </table>
    </div>
  );
};

export default HydrantList;