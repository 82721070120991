import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody
} from 'reactstrap';
import { getHydrantExercises } from '../../services/api';
import HydrantExerciseTableRow from './HydrantExerciseTableRow';
import { makeHydrantExercise } from '../../models';

const HydrantExerciseModal = ({ hydrantId, open, toggle, onSelect, hydrantExercise }) => {
  const [hydrantExercises, setHydrantExercises] = useState([]);

  useEffect(() => {
    setupHydrantExercise();
  }, [hydrantId, hydrantExercise]);

  const setupHydrantExercise = async () => {
    const result = await getHydrantExercises(hydrantId);
    result.sort((a, b) => {
      if (a.exerciseDate < b.exerciseDate) {
        return -1;
      } else if (a.exerciseDate > b.exerciseDate) {
        return 1;
      } else {
        return 0;
      }
    });
    const mappedExercises = result.map(e => makeHydrantExercise(e));
    setHydrantExercises(mappedExercises);
  };

  const select = id => {
    toggle();
    onSelect(id);
  };

  const hydrantExerciseRows = hydrantExercises?.map(exercise => 
    <HydrantExerciseTableRow key={exercise.id} exercise={exercise} onSelect={select} />);

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalBody>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Exercise Date</th>
              <th>Max Torque</th>
              <th>Max Torque Revolutions</th>
              <th>Total Turns</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {hydrantExerciseRows}
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default HydrantExerciseModal;