import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getAllDefaultHydrantExerciseSettings = async () => {
  const result = await tryGet('/defaultHydrantExerciseSettings');
  return result.data;
};

export const getDefaultHydrantExerciseSettings = async (defaultHydrantExerciseSettingsId) => {
  const result = await tryGet(`/defaultHydrantExerciseSettings/${defaultHydrantExerciseSettingsId}`);
  return result.data;
};

export const getDefaultHydrantExerciseSettingsEdit = async (defaultHydrantExerciseSettingsId) => {
  const result = await tryGet(`/defaultHydrantExerciseSettings/${defaultHydrantExerciseSettingsId}/edit`);
  return result.data;
};

export const updateDefaultHydrantExerciseSettings = async (defaultHydrantExerciseSettings) => {
  const result = await tryPut('/defaultHydrantExerciseSettings', defaultHydrantExerciseSettings);
  return result.data;
};

export const addDefaultHydrantExerciseSettings = async (defaultHydrantExerciseSettings) => {
  const result = await tryPost('/defaultHydrantExerciseSettings', defaultHydrantExerciseSettings);
  return result.data;
};

export const deleteDefaultHydrantExerciseSettings = async (defaultHydrantExerciseSettingsId) => {
  const result = await tryDelete(`/defaultHydrantExerciseSettings/${defaultHydrantExerciseSettingsId}`);
  return result.data;
};

export const getDefaultHydrantExerciseSettingsByHydrantId = async (hydrantId) => {
  const result = await tryGet(`/defaultHydrantExerciseSettings/byHydrantId/${hydrantId}`);
  return result.data;
};

export const getDefaultHydrantExerciseSettingsByHydrantSize = async (hydrantSize) => {
  const result = await tryGet(`/defaultHydrantExerciseSettings/byHydrantId/${hydrantSize}`);
  return result.data;
};
