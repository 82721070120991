import { tryGet, tryPost, tryPut } from './index';

export const getUsers = async (searchText) => {
  const result = await tryGet(`/users?search=${searchText || ''}`);
  return result.data;
};

export const getUser = async (userId) => {
  const result = await tryGet(`/users/${userId}`);
  return result.data;
};

export const getUserEdit = async (userId) => {
  const result = await tryGet(`/users/${userId}/edit`);
  return result.data;
};

export const updateUser = async (user) => {
  const result = await tryPut(`/users/${user.id}`, user);
  return result.data;
};

export const addUser = async (user) => {
  const result = await tryPost(`/users/${user.id}`, user);
  return result.data;
};

export const updateUserRoles = async (userId, userRoles) => {
  const result = await tryPost(`/users/${userId}/UpdateRoles`, userRoles);
  return result.data;
};

export const addUserRoles = async (email, userRoles) => {
  const data = {
    email,
    roleIds: userRoles,
  };
  const result = await tryPost(`/users/UpdateRolesByEmail`, data);
  return result.data;
};