export default function buildMakeFireFlowTest({ validate, validators, makeTorqueLog }) {
  return function makeFireFlowTest({
    id,
    companyId,
    testDate,
    pumperCount,
    pumpersValue,
    nozzleCount,
    nozzlesValue,
    nozzlePSI,
    staticPSI,
    residualPSI,
    percentDrop,
    totalGallonsUsed,
    maxGPM,
    elapsedSeconds,
    predictedGPM,
    fireFlowLogs,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      companyId: {
        prettyName: 'Company ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      testDate: {
        prettyName: 'Test Date',
        validators: [validators.notNull(), validators.isDate()],
      },
      pumperCount: {
        prettyName: 'Pumper Count',
        validators: [validators.isInteger()],
      },
      pumpersValue: {
        prettyName: 'Pumper Value',
        validators: [validators.isNumber()],
      },
      nozzleCount: {
        prettyName: 'Nozzle Count',
        validators: [validators.isNumber()],
      },
      nozzlesValue: {
        prettyName: 'Nozzles Value',
        validators: [validators.isNumber()],
      },
      nozzlePSI: {
        prettyName: 'Nozzle PSI',
        validators: [validators.isNumber()],
      },
      staticPSI: {
        prettyName: 'Static PSI',
        validators: [validators.isNumber()],
      },
      residualPSI: {
        prettyName: 'Residual PSI',
        validators: [validators.isNumber()],
      },
      percentDrop: {
        prettyName: 'Percent Drop',
        validators: [validators.isNumber()],
      },
      totalGallonsUsed: {
        prettyName: 'Total Gallons Used',
        validators: [validators.isNumber()],
      },
      maxGPM: {
        prettyName: 'Max GPM',
        validators: [validators.isNumber()],
      },
      elapsedSeconds: {
        prettyName: 'Elapsed Seconds',
        validators: [validators.isInteger()],
      },
      predictedGPM: {
        prettyName: 'Predicted GPM',
        validators: [validators.isNumber()],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get testDate() { return testDate ? new Date(testDate) : null  },
      get pumperCount() { return pumperCount },
      get pumpersValue() { return pumpersValue },
      get nozzleCount() { return nozzleCount },
      get nozzlesValue() { return nozzlesValue },
      get nozzlePSI() { return nozzlePSI },
      get staticPSI() { return staticPSI },
      get residualPSI() { return residualPSI },
      get percentDrop() { return percentDrop },
      get totalGallonsUsed() { return totalGallonsUsed },
      get maxGPM() { return maxGPM },
      get elapsedSeconds() { return elapsedSeconds },
      get predictedGPM() { return predictedGPM },
      get fireFlowLogs() { return fireFlowLogs ? fireFlowLogs.map(t => makeTorqueLog(t)) : [] },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}