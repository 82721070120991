import React from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getCurrentUser } from '../services/api';
import useLocalStorage from '../utils/useLocalStorage';

const Companies = () => {
  const [companyId, setCompanyId, removeCompanyId] = useLocalStorage('company_id');

  const setCompany = () => {
    setCompanyId('4A7627AA-8B1A-4948-86A8-027F3F209265')
  };

  return (
    <div>
      <h2>Companies</h2>
      <div className="link" onClick={setCompany}>Set company</div>
    </div>
  );
};

export default Companies;