import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getCompanyLogos = async (searchText) => {
  const result = await tryGet(`/companyLogos?search=${searchText || ''}`);

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCompanyLogo = async (companyId) => {
  const result = await tryGet(`/companyLogos/${companyId}`);

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCurrentCompanyLogo = async () => {
  const result = await tryGet('/companyLogos/current');

  // const currentCompanyId = localStorage.getItem('company_id');
  // if (!currentCompanyId && result.data.length > 0) {
  //   localStorage.setItem('company_id', result.data[0].id);
  // }

  return result.data;
};

export const getCompanyLogoEdit = async (companyId) => {
  const result = await tryGet(`/companies/${companyId}/edit`);
  return result.data;
};

export const updateCompanyLogo = async (companyLogo) => {
  const result = await tryPut('/companyLogos', companyLogo);
  return result.data;
};

export const addCompanyLogo = async (companyLogo) => {
  const result = await tryPost('/companyLogos', companyLogo);
  return result.data;
};

export const deleteCompanyLogo = async (companyId) => {
  const result = await tryDelete(`/companyLogos/${companyId}`);
  return result.data;
};