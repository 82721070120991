import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getAllDefaultValveExerciseSettings = async () => {
  const result = await tryGet('/defaultValveExerciseSettings');
  return result.data;
};

export const getDefaultValveExerciseSettings = async (defaultValveExerciseSettingsId) => {
  const result = await tryGet(`/defaultValveExerciseSettings/${defaultValveExerciseSettingsId}`);
  return result.data;
};

export const getDefaultValveExerciseSettingsEdit = async (defaultValveExerciseSettingsId) => {
  const result = await tryGet(`/defaultValveExerciseSettings/${defaultValveExerciseSettingsId}/edit`);
  return result.data;
};

export const updateDefaultValveExerciseSettings = async (defaultValveExerciseSettings) => {
  const result = await tryPut('/defaultValveExerciseSettings', defaultValveExerciseSettings);
  return result.data;
};

export const addDefaultValveExerciseSettings = async (defaultValveExerciseSettings) => {
  const result = await tryPost('/defaultValveExerciseSettings', defaultValveExerciseSettings);
  return result.data;
};

export const deleteDefaultValveExerciseSettings = async (defaultValveExerciseSettingsId) => {
  const result = await tryDelete(`/defaultValveExerciseSettings/${defaultValveExerciseSettingsId}`);
  return result.data;
};

export const getDefaultValveExerciseSettingsByValveId = async (valveId) => {
  const result = await tryGet(`/defaultValveExerciseSettings/byValveId/${valveId}`);
  return result.data;
};

export const getDefaultValveExerciseSettingsByValveSize = async (valveSize) => {
  const result = await tryGet(`/defaultValveExerciseSettings/byValveId/${valveSize}`);
  return result.data;
};
