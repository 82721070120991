import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useRefCallback from '../utils/useRefCallback';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useLocalStorage from '../utils/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Upload = ({ onUpload, multiple, errors, uploading, open, toggle, title, defaultFields, fieldsStorageKey }) => {
  const defaultFieldList = defaultFields || [
    "Name",
    "Latitude",
    "Longitude",
    "Elevation",
    "Hdop",
    "Vdop",
    "StateX",
    "StateY",
    "AccessType",
    "StreetNumber",
    "StreetName",
    "CrossStreet",
    "LocationNotes",
    "City",
    "State",
    "ZipCode",
    "Manufacturer",
    "InstallDate",
    "Size",
    "BoxSize",
    "BoxDepth",
    "Type",
    "Function",
    "Drive",
    "District",
    "SubDivision",
    "Status",
    "Comments",
    "TorqueSetPoint",
    "RPMSetPoint",
    "OpenDirection",
    "CurrentState",
    "Revolutions",
    "StuckRevs",
    "StuckAttempts",
    "OpenRecess",
    "CloseRecess",
    "FlushRevolutions",
    "FlushCycles",
    "HardstopSpeed",
    "AutoCycles",
    "LastMaintenanceDate",
    "MaxTorque",
    "MaxTorqueRevolutions"
  ];
  const [storedUploadList, setStoredUploadList, removeStoredUploadList] = useLocalStorage(fieldsStorageKey || 'importExport_list');
  const uploadList = storedUploadList ? JSON.parse(storedUploadList) : defaultFieldList;
  // React state to track order of items
  const [fieldList, setFieldList] = useState(uploadList);
  const [excludeList, setExcludeList] = useState(defaultFieldList.filter(f => !uploadList.includes(f)));

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    //Cannot remove Name from the include list
    if (droppedItem.draggableId === 'Name' && droppedItem.destination.droppableId !== 'field-list') return;

    var updatedFieldList = [...fieldList];
    var updatedExcludeList = [...excludeList];
    let reorderedItem = null;

    // Remvoe dragged item
    if (droppedItem.source.droppableId === 'field-list') {
      [reorderedItem] = updatedFieldList.splice(droppedItem.source.index, 1);
    } else if (droppedItem.source.droppableId === 'exclude-list') {
      [reorderedItem] = updatedExcludeList.splice(droppedItem.source.index, 1);
    }

    // Add dropped item
    if (droppedItem.destination.droppableId === 'field-list') {
      updatedFieldList.splice(droppedItem.destination.index, 0, reorderedItem);
    } else if (droppedItem.destination.droppableId === 'exclude-list') {
      updatedExcludeList.splice(droppedItem.destination.index, 0, reorderedItem);
    }

    // Update State
    setFieldList(updatedFieldList);
    setExcludeList(updatedExcludeList);
  };

  const reset = () => {
    setFieldList(defaultFieldList);
    setExcludeList([]);
  };

  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [dragDiv, setDragDiv] = useState(null);
  const dragDivRef = useCallback(node => {
    if (node !== null) {
      setDragDiv(node);
    };
  });
  const fileInputRef = useRef();

  useEffect(() => {
    let dragCounter = 0;
    const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDragging(true);
      }
    };
    const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter--;
      if (dragCounter === 0) {
        setDragging(false);
      }
    };
    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        setFiles(multiple ? [...files, ...e.dataTransfer.files] : [e.dataTransfer.files[0]]);
        e.dataTransfer.clearData();
        dragCounter = 0;
      }
    };

    if (dragDiv) {
      dragDiv.addEventListener('dragenter', handleDragIn);
      dragDiv.addEventListener('dragleave', handleDragOut);
      dragDiv.addEventListener('dragover', handleDrag);
      dragDiv.addEventListener('drop', handleDrop);

      return () => {
        dragDiv.removeEventListener('dragenter', handleDragIn);
        dragDiv.removeEventListener('dragleave', handleDragOut);
        dragDiv.removeEventListener('dragover', handleDrag);
        dragDiv.removeEventListener('drop', handleDrop);
      };
    }
  }, [dragDiv]);

  const inputFile = (e) => {
    if (e.target.files.length > 0) {
      setFiles(multiple ? [...files, ...e.target.files] : [e.target.files[0]]);
      e.target.value = null;
    }
  };

  const chooseFile = () => {
    fileInputRef.current.click();
  };

  const clear = () => {
    setFiles([]);
  };

  const upload = () => {
    setStoredUploadList(JSON.stringify(fieldList));
    const fieldMap = fieldList.reduce((map, field, index) => {
      return {
        ...map,
        [field]: index
      };
    }, {});
    onUpload(files, fieldMap);
  };

  let errorJsx = null;
  if (Array.isArray(errors)) {
    errorJsx = errors.map((err, idx) => <div key={idx} className="error">{err}</div>);
  } else if (errors) {
    errorJsx = <div className="error">An error has occurred with the upload.</div>
  }

  return (
    <Modal isOpen={open} toggle={toggle} className="upload-modal">
      <ModalHeader>
        <div>{title ? title : 'Upload'}</div>
      </ModalHeader>
      <ModalBody>
        {errorJsx}
        {uploading ? (
          <span className="spinner"></span>
        ) : (
          <>
            <div className="upload">
              <input ref={fileInputRef} multiple={multiple} className="hidden" type="file" onChange={inputFile} />
              <div ref={dragDivRef} className="drag-and-drop" onClick={chooseFile}>
                {files.length ?
                  files.map((f, idx) => <div key={idx} className="file">{f.name}</div>) : (
                    <div>Click or Drop File{multiple ? 's' : ''} Here</div>
                  )
                }
                {dragging && <div className="drop">Drop Here!</div>}
              </div>
            </div>
            <DragDropContext onDragEnd={handleDrop}>
              <div className="field-containers">
                <div className="list-container">
                  <div className="list-title">Field List</div>
                  <Droppable droppableId="field-list">
                    {(provided) => (
                      <div className="field-droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {fieldList.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided) => (
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                title="Must include"
                              >
                                {item}
                                {item === 'Name' && <FontAwesomeIcon icon={faLock} />}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div className="exclude-container">
                  <div className="list-title">Exclude List</div>
                  <Droppable droppableId="exclude-list">
                    {(provided) => (
                      <div className="exclude-droppable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {excludeList.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided) => (
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                {item}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="actions">
          <button type="button" className="btn btn-secondary" onClick={toggle}>Cancel</button>
          <button type="button" className="btn btn-warning" onClick={clear}>Clear</button>
          <button type="button" className="btn btn-primary" onClick={upload}>Upload</button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default Upload;