import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useLocalStorage from '../utils/useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Download = ({ onDownload, errors, downloading, open, toggle, title, defaultFields, fieldsStorageKey }) => {
  const defaultFieldList = defaultFields || [
    "Name",
    "Latitude",
    "Longitude",
    "Elevation",
    "Hdop",
    "Vdop",
    "StateX",
    "StateY",
    "AccessType",
    "StreetNumber",
    "StreetName",
    "CrossStreet",
    "LocationNotes",
    "City",
    "State",
    "ZipCode",
    "Manufacturer",
    "InstallDate",
    "Size",
    "BoxSize",
    "BoxDepth",
    "Type",
    "Function",
    "Drive",
    "District",
    "SubDivision",
    "Status",
    "Comments",
    "TorqueSetPoint",
    "RPMSetPoint",
    "OpenDirection",
    "CurrentState",
    "Revolutions",
    "StuckRevs",
    "StuckAttempts",
    "OpenRecess",
    "CloseRecess",
    "FlushRevolutions",
    "FlushCycles",
    "HardstopSpeed",
    "AutoCycles",
    "LastMaintenanceDate",
    "MaxTorque",
    "MaxTorqueRevolutions"
  ];
  const [storedDownloadList, setStoredDownloadList, removeStoredDownloadList] = useLocalStorage(fieldsStorageKey || 'importExport_list');
  const downloadList = storedDownloadList ? JSON.parse(storedDownloadList) : defaultFieldList;
  // React state to track order of items
  const [fieldList, setFieldList] = useState(downloadList);
  const [excludeList, setExcludeList] = useState(defaultFieldList.filter(f => !downloadList.includes(f)));

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    //Cannot remove Name from the include list
    if (droppedItem.draggableId === 'Name' && droppedItem.destination.droppableId !== 'field-list') return;

    var updatedFieldList = [...fieldList];
    var updatedExcludeList = [...excludeList];
    let reorderedItem = null;

    // Remvoe dragged item
    if (droppedItem.source.droppableId === 'field-list') {
      [reorderedItem] = updatedFieldList.splice(droppedItem.source.index, 1);
    } else if (droppedItem.source.droppableId === 'exclude-list') {
      [reorderedItem] = updatedExcludeList.splice(droppedItem.source.index, 1);
    }

    // Add dropped item
    if (droppedItem.destination.droppableId === 'field-list') {
      updatedFieldList.splice(droppedItem.destination.index, 0, reorderedItem);
    } else if (droppedItem.destination.droppableId === 'exclude-list') {
      updatedExcludeList.splice(droppedItem.destination.index, 0, reorderedItem);
    }

    // Update State
    setFieldList(updatedFieldList);
    setExcludeList(updatedExcludeList);
  };

  const reset = () => {
    setFieldList(defaultFieldList);
    setExcludeList([]);
  };

  const download = () => {
    setStoredDownloadList(JSON.stringify(fieldList));
    const fieldMap = fieldList.reduce((map, field, index) => {
      return {
        ...map,
        [field]: index
      };
    }, {});
    onDownload(fieldMap);
  };

  let errorJsx = null;
  if (Array.isArray(errors)) {
    errorJsx = errors.map((err, idx) => <div key={idx} className="error">{err}</div>);
  } else if (errors) {
    errorJsx = <div className="error">An error has occurred with the download.</div>
  }

  return (
    <Modal isOpen={open} toggle={toggle} className="download-modal">
      <ModalHeader>
        <div>{title ? title : 'Download'}</div>
      </ModalHeader>
      <ModalBody>
        {errorJsx}
        {downloading ? (
          <span className="spinner"></span>
        ) : (
          <DragDropContext onDragEnd={handleDrop}>
            <div className="field-containers">
              <div className="list-container">
                <div className="list-title">Field List</div>
                <Droppable droppableId="field-list">
                  {(provided) => (
                    <div className="field-droppable"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {fieldList.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              title="Must include"
                            >
                              {item}
                              {item === 'Name' && <FontAwesomeIcon icon={faLock} />}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="exclude-container">
                <div className="list-title">Exclude List</div>
                <Droppable droppableId="exclude-list">
                  {(provided) => (
                    <div className="exclude-droppable"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {excludeList.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              {item}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="actions">
          <button type="button" className="btn btn-secondary" onClick={toggle}>Cancel</button>
          <button type="button" className="btn btn-warning" onClick={reset}>Reset</button>
          <button type="button" className="btn btn-primary" onClick={download}>Download</button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Download;