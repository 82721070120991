export default function buildMakeHydrantModel({ validate, validators }) {
  return function makeHydrantModel({
    id,
    companyId,
    modelId,
    description,
    hydrantManufacturerId,
    openDirection,
    valveTurns,
    valveOpeningSize,
    barrelType,
    pumperCount,
    pumperDiameter,
    pumperCoefficient,
    pumperThreadDiameter,
    pumperThreadsPerInch,
    nozzleCount,
    nozzleDiameter,
    nozzleCoefficient,
    nozzleThreadDiameter,
    nozzleThreadsPerInch,
    hydrantImageId,
    company,
    hydrantImage,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      companyId: {
        prettyName: 'Company ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
      modelId: {
        prettyName: 'Model ID',
        validators: [validators.isString()],
      },
      description: {
        prettyName: 'Description',
        validators: [validators.isString()],
      },
      hydrantManufacturerId: {
        prettyName: 'Manufacturer ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
      openDirection: {
        prettyName: 'Open Direction',
        validators: [validators.isString(), validators.maxLength(5)],
      },
      valveTurns: {
        prettyName: 'Valve Turns',
        validators: [validators.isNumber()],
      },
      valveOpeningSize: {
        prettyName: 'Valve Opening Size',
        validators: [validators.isNumber()],
      },
      barrelType: {
        prettyName: 'Barrel Type',
        validators: [validators.isString()],
      },
      pumperCount: {
        prettyName: 'Pumper Count',
        validators: [validators.isNumber()],
      },
      pumperDiameter: {
        prettyName: 'Pumper Diameter',
        validators: [validators.isNumber()],
      },
      pumperCoefficient: {
        prettyName: 'Pumper Coefficient',
        validators: [validators.isNumber()],
      },
      pumperThreadDiameter: {
        prettyName: 'Pumper Thread Diameter',
        validators: [validators.isNumber()],
      },
      pumperThreadsPerInch: {
        prettyName: 'Pumper Threads Per Inch',
        validators: [validators.isNumber()],
      },
      nozzleCount: {
        prettyName: 'Nozzle Count',
        validators: [validators.isNumber()],
      },
      nozzleDiameter: {
        prettyName: 'Nozzle Diameter',
        validators: [validators.isNumber()],
      },
      nozzleCoefficient: {
        prettyName: 'Nozzle Coefficient',
        validators: [validators.isNumber()],
      },
      nozzleThreadDiameter: {
        prettyName: 'Nozzle Thread Diameter',
        validators: [validators.isNumber()],
      },
      nozzleThreadsPerInch: {
        prettyName: 'Nozzle Threads Per Inch',
        validators: [validators.isNumber()],
      },
      hydrantImageId: {
        prettyName: 'Hydrant Image ID',
        validators: [validators.isString(), validators.maxLength(36)],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get modelId() { return modelId },
      get description() { return description },
      get hydrantManufacturerId() { return hydrantManufacturerId },
      get openDirection() { return openDirection },
      get valveTurns() { return valveTurns },
      get valveOpeningSize() { return valveOpeningSize },
      get barrelType() { return barrelType },
      get pumperCount() { return pumperCount },
      get pumperDiameter() { return pumperDiameter },
      get pumperCoefficient() { return pumperCoefficient },
      get pumperThreadDiameter() { return pumperThreadDiameter },
      get pumperThreadsPerInch() { return pumperThreadsPerInch },
      get nozzleCount() { return nozzleCount },
      get nozzleDiameter() { return nozzleDiameter },
      get nozzleCoefficient() { return nozzleCoefficient },
      get nozzleThreadDiameter() { return nozzleThreadDiameter },
      get nozzleThreadsPerInch() { return nozzleThreadsPerInch },
      get hydrantImageId() { return hydrantImageId },
      get company() { return company },
      get hydrantImage() { return hydrantImage },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}