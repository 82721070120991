import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LabelledDatePicker = ({ label, value, name, onChange, onBlur, dateFormat, showTimeSelect }) => {

  const onDateChange = (date) => {
    if (onChange) {
      onChange({
        value: date,
        name: name,
        type: 'date',
        target: {
          value: date,
          name: name,
          type: 'date',
        },
      });
    }
  };

  const onDateBlur = (date) => {
    if (onBlur) {
      onBlur({
        value: date,
        name: name,
        type: 'date',
        target: {
          value: date,
          name: name,
          type: 'date',
        },
      });
    }
  };

  return (
    <div className="labelled-date-picker">
      <span className="label">{label}</span>
      <DatePicker selected={value}
        name={name}
        onChange={onDateChange}
        onClickOutside={onDateBlur}
        placeholder={label}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
      />
    </div>
  );
};

export default LabelledDatePicker;
