import React from 'react';
import { dateOptions } from '../../utils/dates';

const ValveHistory = ({ exercise }) => {

  return (
    <tr>
      <td>
        {exercise?.exerciseDate ? new Date(exercise.exerciseDate).toLocaleDateString('en-US', dateOptions) : ''}
      </td>
      <td>
        {(exercise?.totalRevolutions ?? 0).toFixed(2)}
      </td>
      <td>
        {(exercise?.cycleCount ?? 0).toFixed(2)}
      </td>
      <td>
        {(exercise?.maxTorque ?? 0).toFixed(2)}
      </td>
      <td>
        {(exercise?.maxTorqueRevolutions ?? 0).toFixed(2)}
      </td>
      <td>
        {(exercise?.maxRevolutions ?? 0).toFixed(2)}
      </td>
    </tr>
  )
};

export default ValveHistory;