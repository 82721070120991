export default function buildMakeValveExercise({ validate, validators, makeTorqueLog }) {
  return function makeValveExercise({
    id,
    companyId,
    valveId,
    exerciseDate,
    manual,
    difficulty,
    comments,
    torqueSetPoint,
    rpmSetPoint,
    openDirection,
    currentState,
    revolutions,
    stuckRevs,
    stuckAttempts,
    openRecess,
    closeRecess,
    flushRevolutions,
    flushCycles,
    hardstopSpeed,
    maxTorque,
    maxTorqueRevolutions,
    maxRevolutions,
    totalRevolutions,
    torqueLogs,
    createdBy,
    createdDate = new Date(),
    modifiedBy,
    modifiedDate = new Date(),
    deletedBy,
    deletedDate,
  }) {
    const schema = {
      id: {
        prettyName: 'ID',
        validators: [validators.isString(), validators.exactLength(36)],
      },
      valveId: {
        prettyName: 'Valve ID',
        validators: [validators.notNull(), validators.isString(), validators.exactLength(36)],
      },
      exerciseDate: {
        prettyName: 'Exercise Date',
        validators: [validators.notNull(), validators.isDate()],
      },
      manual: {
        prettyName: 'Manual',
        validators: [validators.isBoolean()],
      },
      difficulty: {
        prettyName: 'Difficulty',
        validators: [validators.within(['Easy', 'Medium', 'Hard'])],
      },
      comments: {
        prettyName: 'Comments',
        validators: [validators.isString()],
      },
      torqueSetPoint: {
        prettyName: 'Torque Set Point',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(800)],
      },
      rpmSetPoint: {
        prettyName: 'RPM Set Point',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(1), validators.lessThanOrEqualTo(255)],
      },
      openDirection: {
        prettyName: 'Open Direction',
        validators: [validators.within(['Left', 'Right'])],
      },
      currentState: {
        prettyName: 'Current State',
        validators: [validators.within(['Open', 'Closed'])],
      },
      revolutions: {
        prettyName: 'Revolutions',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(1), validators.lessThanOrEqualTo(255)],
      },
      stuckRevs: {
        prettyName: 'Stuck Revs',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      stuckAttempts: {
        prettyName: 'Stuck Attempts',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      openRecess: {
        prettyName: 'Open Recess',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      closeRecess: {
        prettyName: 'Close Recess',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      flushRevolutions: {
        prettyName: 'Flush Revolutions',
        validators: [validators.isNumber(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      flushCycles: {
        prettyName: 'Flush Cycles',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(50)],
      },
      hardstopSpeed: {
        prettyName: 'Hardstop Speed',
        validators: [validators.isInteger(), validators.greaterThanOrEqualTo(0), validators.lessThanOrEqualTo(100)],
      },
    };

    return Object.freeze({
      get id() { return id },
      get companyId() { return companyId },
      get valveId() { return valveId },
      get exerciseDate() { return exerciseDate ? new Date(exerciseDate) : null },
      get manual() { return manual },
      get difficulty() { return difficulty },
      get comments() { return comments },
      get torqueSetPoint() { return torqueSetPoint },
      get rpmSetPoint() { return rpmSetPoint },
      get openDirection() { return openDirection },
      get currentState() { return currentState },
      get revolutions() { return revolutions },
      get stuckRevs() { return stuckRevs },
      get stuckAttempts() { return stuckAttempts },
      get openRecess() { return openRecess },
      get closeRecess() { return closeRecess },
      get flushRevolutions() { return flushRevolutions },
      get flushCycles() { return flushCycles },
      get hardstopSpeed() { return hardstopSpeed },
      get maxTorque() { return maxTorque },
      get maxTorqueRevolutions() { return maxTorqueRevolutions },
      get maxRevolutions() { return maxRevolutions },
      get totalRevolutions() { return totalRevolutions },
      get torqueLogs() { return torqueLogs ? torqueLogs.map(t => makeTorqueLog(t)) : [] },
      get createdBy() { return createdBy },
      get createdDate() { return createdDate ? new Date(createdDate) : null },
      get modifiedBy() { return modifiedBy },
      get modifiedDate() { return modifiedDate ? new Date(modifiedDate) : null },
      get deletedBy() { return deletedBy },
      get deletedDate() { return deletedDate ? new Date(deletedDate) : null },
      validate() {
        const errors = validate(this, schema);
        if (errors.length > 0) {
          const message = errors.map(e => e.name + ': ' + e.message).join('\n');
          const validationError = new Error(message);
          validationError.name = 'ValidationError';
          throw validationError;
        }
      }
    });
  }
}