import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useWindowEvent } from '../utils/useWindowEvent';

const Dropdown = ({ label, children }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    setOpen(false);
  };
  useWindowEvent('click', handleClick);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      <div className="dropdown-button" onClick={toggleDropdown}>
        <div className="dropdown-label">{label}</div>
        <div className="dropdown-action-icon"><FontAwesomeIcon icon={faCaretDown} /></div>
      </div>
      <div className={`dropdown-content${open ? ' open' : ''}`}>
        {children}
      </div>
    </div>
  )
};

export const DropdownItem = ({ children }) => {
  return <div className="dropdown-option">{children}</div>
};

export default Dropdown;