import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import LabelledTextField from '../../components/LabelledTextField';
import { dateOptions, dateTimeOptions } from '../../utils/dates';
import { getHydrant, getValve, getHydrantModel, deleteHydrant, getHydrantImage, getHydrantManufacturer } from '../../services/api';
import IsAuthorized from '../../services/authentication/IsAuthorized';
import { gpsFixes } from '../../utils/dropdownOptions/gpsFixOptions';
import { makeHydrant, makeHydrantModel, makeHydrantImage, makeHydrantManufacturer } from '../../models';
import CircleButton from '../../components/CircleButton';
import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import HydrantModelModal from './HydrantModelModal';
import HydrantManufacturerModal from './HydrantManufacturerModal';
import LabelledImage from '../../components/LabelledImage';

const DetailHydrant = () => {
  const [hydrant, setHydrant] = useState(null);
  const [hydrantModel, setHydrantModel] = useState(null);
  const [hydrantManufacturer, setHydrantManufacturer] = useState(null);
  const [hydrantImage, setHydrantImage] = useState(null);
  const [startDelete, setStartDelete] = useState(false);
  const [controlValve, setControlValve] = useState(null);
  const [hydrantModelModalOpen, setHydrantModelModalOpen] = useState(false);
  const [hydrantManufacturerModalOpen, setHydrantManufacturerModalOpen] = useState(false);
  const { path, url } = useRouteMatch();
  const { hydrantId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupHydrant();
  }, [hydrantId, history.location]);

  useEffect(() => {
    setupControlValve();
    setupHydrantModel();
    setupHydrantManufacturer();
    setupHydrantImage();
  }, [hydrant]);

  const setupHydrant = async () => {
    const result = await getHydrant(hydrantId);
    setHydrant(makeHydrant(result));
  };

  const setupHydrantModel = async () => {
    if (!hydrant || !hydrant.hydrantModelId) {
      setHydrantModel(null);
      return;
    }

    const result = await getHydrantModel(hydrant.hydrantModelId);
    setHydrantModel(makeHydrantModel(result));
  };

  const setupHydrantManufacturer = async () => {
    if (!hydrant || !hydrant.hydrantManufacturerId) {
      setHydrantManufacturer(null);
      return;
    }

    const result = await getHydrantManufacturer(hydrant.hydrantManufacturerId);
    console.log(result);
    setHydrantManufacturer(makeHydrantManufacturer(result));
  };

  const setupHydrantImage = async () => {
    const result = await getHydrantImage(hydrantId);
    if (result.deletedDate) return;
    setHydrantImage(makeHydrantImage(result));
  }

  const setupControlValve = async () => {
    if (!hydrant || !hydrant.controlValveId) {
      setControlValve(null);
      return;
    }
    const result = await getValve(hydrant.controlValveId);
    setControlValve(makeValve(result));
  };

  const deleteRecord = async () => {
    await deleteHydrant(hydrantId);
    history.replace('/hydrants');
  };

  const handleHydrantModelDelete = async () => {
    await setupHydrant();
    setHydrantModelModalOpen(false);
  };

  const handleHydrantModelClose = async () => {
    setHydrantModelModalOpen(false);
    await setupHydrantModel();
  };

  const handleHydrantManufacturerDelete = async () => {
    await setupHydrant();
    setHydrantManufacturerModalOpen(false);
  };

  const handleHydrantManufacturerClose = async () => {
    setHydrantManufacturerModalOpen(false);
    await setupHydrantManufacturer();
  };

  const gpsFix = gpsFixes.find(g => g.id === hydrant?.gpsFixId);

  return (
    <div className="detail-page">
      {hydrant ? (
        <>
          {!startDelete ? (
            <div className="form-actions">
              <IsAuthorized>
                <Link to={`${url}/edit`}>
                  <CircleButton icon={faEdit} size='medium' type='primary' title='Edit' />
                </Link>
                <div className="link-icon" onClick={() => setStartDelete(true)}>
                  <CircleButton icon={faTrashAlt} size='medium' type='danger' title='Delete' />
                </div>
              </IsAuthorized>
            </div>
          ) : (
            <div className="form-actions">
              <span>Are you sure you want to delete this record?</span>
              <button type="button" className="btn btn-primary" onClick={deleteRecord}>Yes</button>
              <button type="button" className="btn btn-secondary" onClick={() => setStartDelete(false)}>No</button>
            </div>
          )}
          <div className="details">
            <LabelledTextField label="Latitude" text={hydrant.latitude} />
            <LabelledTextField label="Longitude" text={hydrant.longitude} />
            <LabelledTextField label="GPS Fix" text={gpsFix?.name} />
            <LabelledTextField label="Elevation" text={hydrant.elevation} />
            <LabelledTextField label="HDOP" text={hydrant.hdop} />
            <LabelledTextField label="VDOP" text={hydrant.vdop} />
            <LabelledTextField label="State X" text={hydrant.stateX} />
            <LabelledTextField label="State Y" text={hydrant.stateY} />
            <LabelledTextField label="Street Number" text={hydrant.streetNumber} />
            <LabelledTextField label="Street Name" text={hydrant.streetName} />
            <LabelledTextField label="Cross Street" text={hydrant.crossStreet} />
            <LabelledTextField label="General Location" text={hydrant.generalLocation} />
            <LabelledTextField label="Location Notes" text={hydrant.locationNotes} />
            <LabelledTextField label="City" text={hydrant.city} />
            <LabelledTextField label="State" text={hydrant.state} />
            <LabelledTextField label="Zip Code" text={hydrant.zipCode} />
            <LabelledTextField label="District" text={hydrant.district} />
            <LabelledTextField label="Sub Division" text={hydrant.subDivision} />
            <LabelledTextField label="Install Date" text={hydrant.installDate ? new Date(hydrant.installDate).toLocaleDateString('en-US', dateOptions) : ''} />
            <LabelledTextField label="Status" text={hydrant.status} />
            <LabelledTextField label="Description" text={hydrant.description} />
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledTextField label="Manufacturer" text={hydrantManufacturer?.name} />
              </div>
              <div className='input-actions'>
                {hydrantManufacturer && (
                  <CircleButton icon={faEye} type='primary' title="View Hydrant Manufacturer" onClick={() => setHydrantManufacturerModalOpen(true)} />
                )}
              </div>
            </div>
            <div className='actionable-input-group'>
              <div className='actionable-inputs'>
                <LabelledTextField label="Model" text={hydrantModel?.modelId} />
              </div>
              <div className='input-actions'>
                {hydrantModel && (
                  <CircleButton icon={faEye} type='primary' title="View Hydrant Model" onClick={() => setHydrantModelModalOpen(true)} />
                )}
              </div>
            </div>
            <LabelledTextField label="Paint Color" text={hydrant.paintColor} />
            <LabelledTextField label="Vandal Proof Type" text={hydrant.vandalProofType} />
            <LabelledTextField label="Restraint" text={hydrant.restraint} />
            <LabelledTextField label="Main Size" text={hydrant.mainSize} />
            <LabelledTextField label="Bury Depth" text={hydrant.buryDepth} />
            <LabelledTextField label="Control Valve" text={controlValve?.name} />
            <LabelledTextField label="Control Valve Distance" text={hydrant.controlValveDistance} />
            <LabelledTextField label="Testing Number" text={hydrant.testingNumber} />
            <LabelledTextField label="Comments" text={hydrant.comments} />
            <LabelledTextField label="Created By" text={hydrant.createdBy} />
            <LabelledTextField label="Created Date" text={hydrant.createdDate ? new Date(hydrant.createdDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
            <LabelledTextField label="Modified By" text={hydrant.modifiedBy} />
            <LabelledTextField label="Modified Date" text={hydrant.modifiedDate ? new Date(hydrant.modifiedDate).toLocaleDateString('en-US', dateTimeOptions) : ''} />
            <LabelledImage label="Image" value={hydrantImage} />
          </div>
          <HydrantModelModal open={hydrantModelModalOpen} onClose={handleHydrantModelClose} onDelete={handleHydrantModelDelete} hydrantModelId={hydrant.hydrantModelId} mode="view" />
          <HydrantManufacturerModal open={hydrantManufacturerModalOpen} onClose={handleHydrantManufacturerClose} onDelete={handleHydrantManufacturerDelete} hydrantManufacturerId={hydrant.hydrantManufacturerId} mode="view" />
        </>
      ) : <div className="spinner"></div>}
    </div>
  );
};

export default DetailHydrant;
