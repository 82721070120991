import React from 'react';
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons';
import CircleButton from './CircleButton';

const LabelledPickerCheck = ({ label, type, value, name, onChange, onBlur, children, checked, checkName, checkedIcon, uncheckedIcon, checkTitle }) => {

  const onPickerChange = (e) => {
    onChange({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
      checkName,
      checked
    });
  };

  const onPickerBlur = (e) => {
    onBlur({
      ...e,
      value: e.target.value,
      name: e.target.name,
      type: e.target.type,
      checkName,
      checked
    });
  };

  const onCheckToggle = () => {
    onChange({ name, value, type, checkName, checked: !checked });
  };

  return (
    <div className="labelled-picker-check">
      <span className="label">{label}</span>
      <select type={type ? type : 'text'} value={value?.toString() ?? ''}
        name={name}
        onChange={onPickerChange}
        onBlur={onPickerBlur}
        placeholder={label}>
        {children}
      </select>
      <CircleButton icon={checked ? checkedIcon || faUnlink : uncheckedIcon || faLink} title={checkTitle} onClick={onCheckToggle} />
    </div>
  );
};

export default LabelledPickerCheck;
