import { tryGet, tryPost, tryPut, tryDelete } from './index';

export const getValves = async (searchText, includeSettings = false) => {
  const result = await tryGet(`/valves?search=${searchText || ''}&includeSettings=${includeSettings ? 'true' : 'false'}`);
  return result.data;
};

export const getValve = async (valveId) => {
  const result = await tryGet(`/valves/${valveId}`);
  return result.data;
};

export const getValveEdit = async (valveId) => {
  const result = await tryGet(`/valves/${valveId}/edit`);
  return result.data;
};

export const updateValve = async (valve) => {
  const result = await tryPut(`/valves`, valve);
  return result.data;
};

export const addValve = async (valve) => {
  const result = await tryPost(`/valves`, valve);
  return result.data;
};

export const deleteValve = async (valveId) => {
  const result = await tryDelete(`/valves/${valveId}`);
  return result.data;
};

export const deleteValves = async (valveIds) => {
  console.log(valveIds);
  const result = await tryDelete('/valves', { data: valveIds });
  return result.data;
};

export const importValves = async (file, mappings) => {
  var data = new FormData();
  data.append('file', file);
  data.append('mapping', JSON.stringify(mappings));
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/valves/import?timeZoneName=${timeZoneName}`, data, true);
  return result.data;
};

export const exportValves = async (mappings) => {
  console.log(mappings);
  var timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const result = await tryPost(`/valves/export?timeZoneName=${timeZoneName}`, mappings);
  return result.data;
};