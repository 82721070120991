import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LabelledMultiPicker from '../components/LabelledMultiPicker';
import LabelledTextBox from '../components/LabelledTextBox';
import LabelledTextField from '../components/LabelledTextField';
import { getUser, getRoles, addUserRoles, inviteUser } from '../services/api';

const AddUserRoles = () => {
  const [email, setEmail] = useState(null);
  const [roles, setRoles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const { userId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setupRoles();
  }, []);

  const setupRoles = async () => {
    const result = await getRoles();
    setRoles(result);
  };

  const addRole = (roleId) => {
    setUserRoles([...userRoles.filter(ur => ur !== roleId), roleId]);
  };

  const removeRole = (roleId) => {
    setUserRoles(userRoles.filter(ur => ur !== roleId));
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const save = async (e) => {
    try {
      e.preventDefault();
      setErrorMessage(null);
      await inviteUser({
        email,
        roleIds: userRoles
      });
      history.goBack();
    } catch (ex) {
      console.log(ex.response);
      if (ex.response.status === 404) {
        setErrorMessage(`Unable to add ${email}.`);
      } else {
        setErrorMessage('Unexpected error has occured.');
      }
    }
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <form onSubmit={save}>
      <h2>Add User Roles</h2>
      <div className="details">
        {roles ? (
          <>
            {errorMessage ? <div className="error center-text">{errorMessage}</div> : null}
            <LabelledTextBox label="Email" type="email" value={email} onChange={changeEmail} onBlur={changeEmail} />
            <LabelledMultiPicker
              label="Roles"
              options={roles.map(r => ({ value: r.id, content: r.roleName }))}
              values={userRoles}
              onAdd={addRole}
              onRemove={removeRole} />
          </>
        ) : (
          <span className="spinner"></span>
        )}
      </div>
      <div className="form-actions">
        <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
        <button type="submit" className="btn btn-primary">Save</button>
      </div>
    </form>
  );
};

export default AddUserRoles;