import { tryGet, tryPost, tryPut } from './index';

export const getUserInvites = async () => {
  const result = await tryGet('/userInvites');
  return result.data;
};

export const inviteUser = async (invite) => {
  const result = await tryPost('/userInvites/inviteUser', invite);
  return result.data;
};

export const uninviteUser = async (uninvite) => {
  const result = await tryPost('/userInvites/uninviteUser', uninvite);
  return result.data;
};