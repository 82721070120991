import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { roundDecimals } from '../utils/math';

mapboxgl.accessToken = 'pk.eyJ1Ijoid3dlZ2Vua2UiLCJhIjoiY2t3NWl5eWRlMTZocTJvbW5xenFlcDFlMCJ9.TQud3faSsauFW-7URATL4Q';

const LatLongSelector = ({ open, toggle, onSelect, onCancel, point, image }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [longitude, setLongitude] = useState(point?.longitude || -95.6341);
  const [latitude, setLatitude] = useState(point?.latitude || 42.2174);
  const [zoom, setZoom] = useState(point?.latitude && point?.longitude ? 17 : 2.5);
  const [opened, setOpened] = useState(false);
  const [lngLat, setLngLat] = useState(point ? { lng: point.longitude, lat: point.latitude } : null);
  const selectionItem = useRef(null);


  //Load map
  useEffect(() => {
    if (!opened) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      center: [longitude, latitude],
      zoom: zoom
    });

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();
    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();
    selectionItem.current = new mapboxgl.Marker();
    if ((point?.latitude && point?.longitude) || (lngLat?.lat && lngLat?.lng)) {
      selectionItem.current.setLngLat({ lng: point?.longitude ?? lngLat?.lng, lat: point?.latitude ?? lngLat?.lat});
      selectionItem.current.addTo(map.current);
    }
    map.current.on('move', () => {
      setLongitude(map.current.getCenter().lng.toFixed(4));
      setLatitude(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    map.current.on('click', handleMapClick);
  }, [opened]);

  useEffect(() => {
    if (map.current && map.current.style !== mapStyle) {
      map.current.setStyle(mapStyle);
    }
  }, [mapStyle, map.current]);

  const handleMapClick = (e) => {
    selectionItem.current.setLngLat(e.lngLat)
    selectionItem.current.addTo(map.current);
    setLngLat(selectionItem.current.getLngLat());
  }

  const select = () => {
    const coords = selectionItem.current.getLngLat();
    onSelect({ latitude: roundDecimals(coords.lat, 9), longitude: roundDecimals(coords.lng, 9) });
  };

  const cancel = () => {
    onCancel();
  };

  return (
    <Modal isOpen={open} onClosed={() => setOpened(false)} onOpened={() => setOpened(true)} toggle={toggle} className="latlong-modal">
      <ModalHeader>
        <div>Select Lat/Long</div>
      </ModalHeader>
      <ModalBody>
        <div id="latLongModalMap" className="map-container">
          <div className="sidebar">
          Latitude: {lngLat?.lat?.toFixed(9)} | Longitude: {lngLat?.lng?.toFixed(9)}
          </div>
          <div ref={mapContainer} className="map" />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="actions">
          <button type="button" className="btn btn-secondary" onClick={cancel}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={select}>Select</button>
        </div>
      </ModalFooter>
    </Modal>
  )
};

export default LatLongSelector;